import { LoadingButton } from "@mui/lab";
import { Alert, Button, Card, CardActions, CardContent, CardMedia, LinearProgress, Slide, Snackbar } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import $auth from "../../services/$auth";
import $cookies from "../../services/$cookies";
import TextInput from "../ui/TextInput";
import WidgetTitle from "../widgets/WidgetTitle";
import AuthWrapper from "./AuthWrapper";

function NewPassword(props) {
  const [redirect, setRedirect] = useState(false);

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, setState] = useState({
    new_password1: '',
    new_password2: ''
  });

  const [error, setError] = useState({});


  const onChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSubmit = async () => {
    setLoading(true);
    
    try {
      await $auth.resetConfirm({...state, uid: props.uid, token: props.token})
      $cookies.set('forgot', 'true');
      setRedirect(true);
    } catch(e) {
      $cookies.set('forgot', 'true');
      setError(e);
      if(e.non_field_errors) {
        setErrorMessage(e.non_field_errors);
      }
    } finally {
      setLoading(false);
    }
  }

  if(redirect) {
    return <Redirect to="/login" />
  }

  return (
    <AuthWrapper>
      <form autoComplete="off" onSubmit={(evt) => {evt.preventDefault(); onSubmit();}}>
      <input autoComplete="false" hidden />

        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent'}}>
          <CardMedia style={{ padding: '10px 30px', }} className="co-background-primary">
            <Stack justifyContent="center" alignItems="center" direction="column" style={{ minHeight: '180px', borderRadius: '0' }}>
              <WidgetTitle text={"New password"} style={{color: '#fff'}} />
            </Stack>

          </CardMedia>
          <CardContent className="co-background">
            <TextInput autoComplete="new-password" error={Boolean(error.non_field_errors || error.new_password1)} helperText={error.new_password1} label="New Password" type="password" value={state.new_password1} onChange={(evt) => onChange('new_password1', evt.target.value)}  />
            <TextInput autoComplete="new-password" error={Boolean(error.non_field_errors || error.new_password2)} helperText={error.new_password2} label="Confirm password" type="password" value={state.new_password2} onChange={(evt) => onChange('new_password2', evt.target.value)}  />
          
          </CardContent>
          <CardActions  className="co-background" style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }}>
            <LoadingButton disabled={loading} type="submit" variant="contained" className="co-text">{'Confirm'}</LoadingButton>
           
          </CardActions>
          {loading && <LinearProgress variant="indeterminate" color="primary"/>}
        </Card>
      </form>

      {/* ERROR */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(errorMessage)} onClose={(evt, reason) => setErrorMessage(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* SUCCESS */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(success)} autoHideDuration={6000} onClose={(evt, reason) => setSuccess(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </AuthWrapper>
  )
}

export default NewPassword;