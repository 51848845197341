import { Container } from "@mui/material";
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";
import backgroundImage from '../../images/background.webp';

function AuthWrapper(props) {
  return (
    <div style={{backgroundImage: `url(${backgroundImage})`}}  className="auth-wrapper-container fade-in">
      <AuthHeader />
      <div style={{ width: 'calc(100% - 30px)', maxWidth: '460px', padding: '15px', margin: '0 auto 80px auto' }}>
        {props.children}
      </div>
      <AuthFooter />
    </div>
  )
}

export default AuthWrapper;