import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import MultiSelect from "../ui/MultiSelect";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";

function EditFarmModal(props) {
	const {onShowSuccess, projectData, onUpdateProjectData} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
	const [state, setState] = useState({
		...props.model,
		parcels: []
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	const extractModelChanges = () => {
		let changed = {
			id: props.model.id
		};

		if(state.name != props.model.name) {
			changed.name = state.name;
		}

		if(state.description != props.model.description) {
			changed.description = state.description;
		}

		if(state.parcels.length > 0) {
			changed.parcels = state.parcels.join(',');
		}

		return changed;
	}

	const onSubmit = async () => {
		setLoading(true);

		try {
			let model = extractModelChanges();
			let fd = new FormData();
			Object.keys(model).map(name => {
				fd.append(name, model[name]);
			})

			await $data.updateFarm(fd, model.id);
			let farms = await $data.getFarms(props.projectId);

			if(model.hasOwnProperty('parcels')) {
				let parcels = await $data.getParcels(props.projectId);
				onUpdateProjectData(props.projectId, {farms, parcels});
			} else {
				onUpdateProjectData(props.projectId, {farms});
			}

 			onShowSuccess(`${model.name || props.model.name} - farm updated`);

			props.onClose();

		} catch(e) {
			console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

	const onChangeParcels = (evt, data, reason) => {
		console.log(evt.target.value)
		setState((state) => ({
			...state,
			parcels: typeof evt.target.value === 'string' ? evt.target.value.split(',') : evt.target.value
		}));
	}

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Edit farm</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
					<TextInput value={state.name} onChange={(evt) => setState({...state, name: evt.target.value})} label="Name" />
					<TextInput value={state.description} error={Boolean(status.error.description)} helperText={status.error.description || null} multiline onChange={(evt) => setState({...state, description: evt.target.value})} label="Description" />

					{/* <MultiSelect name="Attach uncategorized parcels" items={projectData[props.projectId].parcels.filter(p => !p.farm).map(p => ({value: p.getId(), label: p.getName()}))} value={state.parcels} onChange={onChangeParcels} /> */}
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{lineHeight: 'unset'}} color="primary">Confirm</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default EditFarmModal;