import { Agriculture, ArrowBack, ArrowDownward, ArrowDropDown, ArrowForward, ArrowRight, Close, Create, Edit, Filter, Filter1, Filter2, Forest, KeyboardArrowDown, KeyboardArrowUp, PostAdd, QueryBuilder, Search, TableBar, TableChart, ZoomInMap } from "@mui/icons-material";
import { Button, CircularProgress, Fab, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import Parcel from "../../models/Parcel";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import COTooltip from "../ui/COTooltip";
import WidgetDescription from "../widgets/WidgetDescription";
import TextInput from "../ui/TextInput";

const container = {
  width: '320px',
  height: 'calc(100% - 60px - 260px - 16px)',
  overflowY: 'hidden',
  overflowX: 'hidden',
  paddingBottom: '15px',
  background: variables.widget,
}


function ParcelsWidget({ selected, data, farms, loading, onNavigateToParcel, onToggleAddFarm, onToggleManageFarms }) {
  const {user} = useContext(AppContext); 
  const [active, setActive] = useState([]);
  const [filter, setFilter] = useState('');

  const setFarm = (id) => {
    if (active.indexOf(id) > -1) {
      setActive(active.filter(i => i != id));
    } else {
      setActive([...active, id]);
    }
  }

  const renderParcels = (id) => {
    if (loading) {
      return <CircularProgress color="secondary" />
    }

    if (!data) return;


    let filtered = user.user_type !== 1 ? data.filter(parcel => parcel.farm == id) : [...data];

    if(filtered.length == 0) {
      return (
        <ListItem sx={{ '&:hover': { background: 'rgba(255,255,255, 0.3)', transition: '.2s linear background', cursor: 'pointer' }, '&:active': { background: 'rgba(255,255,255, 0.1)' }, width: '100%' }} dense>
          <ListItemText sx={{ '&>span': { color: '#fff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }, '&>p': { color: variables.secondary } }} primary={'No parcels imported/created'} />
        </ListItem>
      )
    }

    if(filter !== '') {
      filtered = filtered.filter(p => {
        return p.getName().trim().toLowerCase().indexOf(filter.trim().toLowerCase()) > -1;
      })
    }

    if(filtered.length === 0) {
      return (
        <ListItem sx={{ '&:hover': { background: 'rgba(255,255,255, 0.3)', transition: '.2s linear background', cursor: 'pointer' }, '&:active': { background: 'rgba(255,255,255, 0.1)' }, width: '100%' }} dense>
          <ListItemText sx={{ '&>span': { color: '#fff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }, '&>p': { color: variables.secondary } }} primary={'There are no parcels that match your search query'} />
        </ListItem>
      )
    }

    return (
      <List disablePadding sx={{ width: '100%' }}>
        {filtered.map((parcel, key) => {
          return (
            <ListItem sx={{background: selected === parcel.getId() ? 'rgba(255,255,255, 0.3)' : 'auto', p: '4px 48px 4px 32px', '&:hover': { background: 'rgba(255,255,255, 0.3)', transition: '.2s linear background', cursor: 'pointer' }, '&:active': { background: 'rgba(255,255,255, 0.1)' }, width: '100%' }} key={key} dense secondaryAction={<COTooltip arrow title="Go to parcel"><ArrowForward onClick={() => onNavigateToParcel(parcel)} sx={{ '&:hover': { transform: 'scale(1.1)' } }} color="secondary" /></COTooltip>}>
              <ListItemText sx={{ '&>span': { color: '#fff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }, '&>p': { color: variables.secondary } }} primary={<Stack direction="row" spacing={1}>{parcel.agroforestry && <Forest fontSize="small" color="secondary" />} <span>{parcel.getName()}</span></Stack>} secondary={parcel.getArea() + 'ha'} />
            </ListItem>
          )
        })}
      </List>
    )

  }

  const renderNoFarmParcels = () => {
    if (!data) return null;

    return data.filter(p => !p.farm).map((parcel, key) => (
      <ListItem sx={{ '&:hover': { background: 'rgba(255,255,255, 0.3)', transition: '.2s linear background', cursor: 'pointer' }, '&:active': { background: 'rgba(255,255,255, 0.1)' }, width: '100%' }} key={key} dense secondaryAction={<COTooltip arrow title="Go to parcel"><ZoomInMap onClick={() => onNavigateToParcel(parcel)} sx={{ '&:hover': { transform: 'scale(1.1)' } }} color="secondary" /></COTooltip>}>
        <ListItemText sx={{ '&>span': { color: '#fff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }, '&>p': { color: variables.secondary } }} primary={parcel.getName()} secondary={parcel.getArea() + 'ha'} />
      </ListItem>
    ))
  }

  const renderFarms = () => {
    if (loading) {
      return <CircularProgress color="secondary" />
    }

    if (!data) return;

    // let farms = [...new Map(data.map(p => [p.getFarm().id, p.getFarm()])).values()];

    // if(farms.length === 0) {
    //   return <WidgetDescription style={{color: variables.secondary}} text="There are no parcels" />
    // }

    let filtered = farms;

    if(filter !== '') {
      filtered = farms.filter(f => {
        let parcels = data.filter(parcel => parcel.farm == f.id);

        return parcels.filter(p => p.getName().trim().toLowerCase().indexOf(filter.trim().toLowerCase()) > -1).length > 0;
      })
    }


    return filtered.map((farm, key) => {
      let isActive = active.indexOf(farm.id) > -1;

      return (
        <Stack key={key} sx={{ width: '100%' }}>
          <Stack sx={{ boxSizing: 'border-box', width: '100%', p: '10px 15px 10px 15px', background: active.indexOf(farm.id) > -1 ? variables.secondary : 'transparent' }} alignItems="center" direction="row" justifyContent="space-between">
            <Stack sx={{ '& *': { color: isActive ? variables.primary : variables.secondary } }} alignItems="center" direction="row" spacing={1} >
              <Agriculture />
              <span className="text-bold">{farm.name}</span>
            </Stack>

            {active.indexOf(farm.id) == -1 && <KeyboardArrowDown sx={{ cursor: 'pointer', '&:hover': { transform: 'scale(1.2)' } }} color="secondary" onClick={() => setFarm(farm.id)} />}
            {isActive && <KeyboardArrowUp sx={{ cursor: 'pointer', '&:hover': { transform: 'scale(1.2)' } }} color="primary" onClick={() => setFarm(farm.id)} />}
          </Stack>
          {isActive && <Stack sx={{ overflow: 'hidden', width: '100%' }} className={isActive ? 'height-in' : 'height-out'}>
            {renderParcels(farm.id)}
          </Stack>}
        </Stack>
      )
    });
  }

  return (
    <Paper sx={{ ...container }} className="co-background-primary-blur fade-in-4" elevation={4} component={Stack} alignItems="stretch">
      <Stack sx={{ padding: '15px', background: 'transparent' }} direction="row" alignItems="center" justifyContent="space-between">
        <WidgetDescription text={user.user_type === 1 ? "Parcels" : "Farms"} style={{ color: variables.secondary, fontWeight: 'bold' }} />
        {/* <ArrowBack color="secondary" /> */}
        {user.user_type !== 1 && <div>
          <COTooltip title="Create new farm" arrow placement="left" >
            <Button onClick={onToggleAddFarm} size="small" sx={{ lineHeight: 'unset', minWidth: 'unset' }} color="secondary"><PostAdd color="secondary" /></Button>
          </COTooltip>
          <COTooltip title="Manage farms" arrow placement="right" >
            <Button onClick={onToggleManageFarms} size="small" sx={{ lineHeight: 'unset', minWidth: 'unset' }} color="secondary"><TableChart color="secondary" /></Button>
          </COTooltip>
        </div>}
      </Stack>

      <Stack sx={{ height: 'calc(100% - 54px)', overflowY: 'auto', paddingBottom: '15px' }} alignItems="center">
          <div style={{padding: '5px 10px', width: 'calc(100% - 20px)'}}>
          <TextField InputProps={{endAdornment: <InputAdornment><Search color="secondary" /></InputAdornment>}} onChange={(evt) => {setFilter(evt.target.value)}} label="Filter parcels by name" className="input-secondary" sx={{'& input': {color: '#fff'}, borderColor: variables.secondary}} color="secondary" size="small" fullWidth  />
          </div>
        {user.user_type === 1 && renderParcels()}
        {user.user_type !== 1 && renderFarms()}
        {/* {data?.length > 0 && <hr style={{ width: 'calc(100% - 30px)', borderColor: variables.secondary }} />} */}
        {/* {renderNoFarmParcels()} */}
      </Stack>
    </Paper>
  )
}

export default ParcelsWidget;