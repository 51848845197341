import { Delete, Edit, RotateRight } from "@mui/icons-material";
import { Button, Chip, Stack } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";

function PhotosPreview ({photos, onClose}) {


  const [photoIndex, setPhotoIndex] = useState(0);
  const [deletePhoto, setDeletePhoto] = useState(0);
  const [editPhoto, setEditPhoto] = useState(null);

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    console.log(rotation)
    let el = document.querySelector('.ril-image-current.ril__image');
    if(el) {
      el.style.transform = el.style.transform.split('rotate')[0].trim();
      el.style.transform =  `${el.style.transform} rotate(${rotation}deg)`;
      console.log(el.style.transform);
    }
  }, [rotation])

  if(!photos) return null;
  console.log(photos)
  const onClosePreview = () => {
    setPhotoIndex(0);
    onClose();
  }



  const rotateImage = (element) => {
    if(rotation == 315) {
      setRotation(0);
    } else {
      setRotation(rotation + 45);
    }
  }

  return (
    <>
      <Lightbox
          mainSrc={photos[photoIndex].image}
          nextSrc={photos.length > 1 ? photos[(photoIndex + 1) % photos.length].image : null}
          prevSrc={photos.length > 1 ? photos[(photoIndex + photos.length - 1) % photos.length].image : null}
          imageTitle={photos[photoIndex].name}

          imageCaption={<Stack alignItems="center" sx={{width: '100%'}} direction="row" justifyContent="space-between"><div>{photos[photoIndex].date_time && moment(photos[photoIndex].date_time).format('DD-MMM-YYYY')}</div>
            {photos[photoIndex].tag && <Chip size="small" label={`#${photos[photoIndex].tag}`} color="warning" variant="filled" sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer'}} />}
            <RotateRight onClick={() => {
              rotateImage( document.getElementsByTagName('ril-image-current ril__image')[0]);
            }} />
          </Stack>}
          onCloseRequest={onClosePreview}
          onMovePrevRequest={() =>{
            setRotation(0);
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
          }
          onMoveNextRequest={() =>{       
            setRotation(0);

            setPhotoIndex((photoIndex + 1) % photos.length) }
          }
          />
    </>
  )
}

export default PhotosPreview;