import { Add, Delete, Edit, EditAttributes, ModeEdit, MoveUp, PhotoAlbum, PhotoCamera, SendToMobile, Upload } from "@mui/icons-material";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import COTooltip from "../ui/COTooltip";

function Toolbar({
  onToggleDraw,
  action,
  uploadModal,
  onSetUploadModal,
  onTogglePhotos,
  sendToCroplab,
  onSetSendToCroplab,
  selected,
  onToggleDelete,
  onToggleEdit,
  onToggleImportCroplab
}) {

  const {user} = useContext(AppContext);

  return <ButtonGroup orientation="vertical" sx={{position: 'absolute', top: '20px', right: '10px'}}>
    <COTooltip arrow placement="left" title="Draw parcel">
      <Button onClick={() => onToggleDraw(action ? false : true)} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color={action ? "secondary" : "primary"}><Add /></Button>
    </COTooltip>
    <COTooltip arrow placement="left" title="Import parcels from file">
      <Button onClick={() => onSetUploadModal(true)} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="primary"><Upload /></Button>
    </COTooltip>
    <COTooltip arrow placement="left" title="Geo-tagged photos">
      <Button onClick={() => onTogglePhotos(true)} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="primary"><PhotoCamera /></Button>
    </COTooltip>
    {user.user_type !== 1 && <COTooltip arrow placement="left" title="Import from CropLab farmers">
      <Button onClick={() => onToggleImportCroplab(true)} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="primary"><MoveUp /></Button>
    </COTooltip>}
    {/* <COTooltip arrow placement="left" title="Send to CropLab">
      <Button onClick={() => onSetSendToCroplab(true)} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="primary"><MoveUp /></Button>
    </COTooltip> */}
    {selected && <COTooltip arrow placement="left" title="Edit parcel">
      <Button onClick={onToggleEdit} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="warning"><ModeEdit /></Button>
    </COTooltip>}
    {selected && <COTooltip arrow placement="left" title="Delete parcel">
      <Button onClick={onToggleDelete} variant="contained" sx={{minWidth: 'unset', p: '12px 10px'}} color="error"><Delete /></Button>
    </COTooltip>}
  </ButtonGroup>

}

export default Toolbar;