import { useContext, useEffect, useRef, useState } from "react";
import olMap from 'ol/Map';
import View from 'ol/View';
import { fromLonLat, toLonLat } from "ol/proj";
import { Button, ButtonGroup, CircularProgress, Fab } from "@mui/material";
import { Stack } from "@mui/system";
import { basemaps, defaults, overlays } from "../../config/map";
import { DataContext } from "../DataContext";
import $map from "../../services/$map";
import { unByKey } from "ol/Observable";
import { defaults as defaultInteractions } from 'ol/interaction';
import ParcelsWidget from "./ParcelsWidget";
import DataWidget from "./DataWidget";
import LayersWidget from "./LayersWidget";
import VerificationWidget from "./VerificationWidget";
import $data from "../../services/$data";
import Toolbar from "./Toolbar";
import UploadParcelsModal from "./UploadParcelsModal";
import Draw from 'ol/interaction/Draw.js';
import AddParcelModal from "./AddParcelModal";
import AddFarmModal from "./AddFarmModal";
import DeleteParcelModal from "./DeleteParcelModal";
import ManageFarmsModal from "./ManageFarmsModal";
import EditParcelModal from "./EditParcelModal";
import ImportFromCroplab from "./ImportFromCroplab";
import PhotosPreview from "../ui/PhotosPreview";
import SamplesPreview from "../ui/SamplesPreview";
import Legend from "./Legend";
import { AppContext } from "../../AppContext";
import ReportsWidget from "./ReportsWidget";
import PhotosModal from "./PhotosModal";
import { Add, Book, Layers, ListAlt, MenuBook, Remove, Report } from "@mui/icons-material";

function Map(props) {
	const { user } = useContext(AppContext);
	const { projects, projectData, base, layerM, layerA, timeA, timeAg, opacity, onUpdateProjectData, } = useContext(DataContext);
	let isAgroforestry = false;

	if (user.user_type === 3) {
		isAgroforestry = Boolean(projects.results.filter(p => p.id.toString() === props.projectId)[0]?.isAgroforestry);
	} else {
		console.log(projectData[props.projectId])
		isAgroforestry = Boolean(!projectData[props.projectId]?.parcels?.every(p => !p.agroforestry));
	}

	console.log('>>>>>>>>>>>>>>>>>>>>>>>>');
	console.log('AGROFORESTRY:', isAgroforestry)
	console.log('<<<<<<<<<<<<<<<<<<<<');

	const mapRef = useRef(null);
	const mapInstance = useRef(null);
	const featureHover = useRef(null);
	const queryClick = useRef(null);
	const basemapList = useRef(basemaps(base));
	const parcelsLayer = useRef(null);
	const photosLayer = useRef(null);
	const samplesLayer = useRef(null);
	const drawLayer = useRef(null);
	const applicabilityLayer = useRef(null);
	const monitoringLayer = useRef(null);
	const activeFeature = useRef(null);
	const interactions = useRef({});

	const [loading, setLoading] = useState(false);
	const [aLoading, setALoading] = useState(false);
	const [mLoading, setMLoading] = useState(false);

	const [queryData, setQueryData] = useState(null);

	const [pointQueryData, setPointQueryData] = useState(null);
	const [monitoringData, setMonitoringData] = useState(null);

	const [action, setAction] = useState(null);

	const [selected, setSelected] = useState(null);

	const [uploadParcels, setUploadParcels] = useState(false);
	const [deleteParcel, setDeleteParcel] = useState(null);
	const [editParcel, setEditParcel] = useState(null);
	const [addParcel, setAddParcel] = useState(false);
	const [addFarm, setAddFarm] = useState(false);
	const [manageFarms, setManageFarms] = useState(false);
	const [importFromCroplab, setImportFromCroplab] = useState(false);
	const [photoPreview, setPhotoPreview] = useState(null);
	const [samplesPreview, setSamplesPreview] = useState(null);
	const [photos, setPhotos] = useState(false);

	const [queryView, setQueryView] = useState('verification');

	useEffect(() => {
		init();

		return () => {
			unByKey(queryClick.current);
			unByKey(featureHover.current);
			Object.values(interactions.current).map(i => {
				unByKey(i);
			})
		}
	}, [])

	const setActiveFeature = (id) => {
		activeFeature.current = id;
		if (id) {
			setSelected(id);
			parcelsLayer.current.getSource().getFeatures().map(f => { f.get('pk') === activeFeature.current ? f.setStyle($map.selectedFeatureStyle) : f.setStyle(undefined) });
		} else {
			setSelected(null)
		}

	}

	const cursorFeatureHover = (evt) => {
		let features = evt.map.getFeaturesAtPixel(evt.pixel, { layerFilter: (l) => l.get('name') === 'parcels' });

		parcelsLayer.current.getSource().getFeatures().map(f => { f.get('pk') === activeFeature.current ? f.setStyle($map.selectedFeatureStyle) : f.setStyle(undefined) });
		if (features.length > 0) {
			if (features[0].get('pk') !== activeFeature.current) {
				features[0].setStyle($map.hoverFeatureStyle);
			}
			mapRef.current.style.cursor = 'pointer';
		} else {
			mapRef.current.style.cursor = 'initial';
		}
	}

	const init = () => {
		drawLayer.current = $map.createDrawLayer();
		interactions.current.draw = new Draw({
			source: drawLayer.current.getSource(),
			style: $map.drawFeatureStyle,
			type: 'Polygon'
		});
		interactions.current.draw.setActive(false);

		mapInstance.current = new olMap({
			target: mapRef.current,
			layers: [...basemapList.current, drawLayer.current],
			interactions: defaultInteractions().extend([interactions.current.draw]),
			overlays: [overlays()[0]],
			view: new View({
				center: fromLonLat(defaults.center),
				zoom: defaults.zoom
			})
		});

		queryClick.current = mapInstance.current.on('singleclick', query);


		featureHover.current = mapInstance.current.on('pointermove', cursorFeatureHover);
	}

	const toggleDraw = (bool) => {
		if (bool) {
			setAction('draw');
		} else {
			setAction(null);
		}

	}

	const loadData = () => {
		if (monitoringLayer.current) {
			mapInstance.current.removeLayer(monitoringLayer.current);
		}

		if (applicabilityLayer.current) {
			mapInstance.current.removeLayer(applicabilityLayer.current);
		}

		monitoringLayer.current = $map.createMonitoringLayers(props.view, layerM, props.timeM, props.projectId, opacity, user, isAgroforestry, timeAg);
		applicabilityLayer.current = $map.createApplicabilityLayers(props.view, layerA, timeA, opacity, user, projects, props.projectId);

		mapInstance.current.addLayer(monitoringLayer.current);
		mapInstance.current.addLayer(applicabilityLayer.current);

		parcelsLayer.current = $map.createParcelLayer(props.data.parcels);
		mapInstance.current.addLayer(parcelsLayer.current);

		photosLayer.current = $map.createPhotosLayer(props.data.photos, props.data.parcels);
		mapInstance.current.addLayer(photosLayer.current)

		samplesLayer.current = $map.createSamplesLayer(props.data.samples, props.data.parcels);
		mapInstance.current.addLayer(samplesLayer.current)

		if (props.view === 'monitoring') {
			applicabilityLayer.current.setVisible(false);
			monitoringLayer.current.setVisible(true);

		} else {
			applicabilityLayer.current.setVisible(true);
			monitoringLayer.current.setVisible(false);
		}


		if (props.data.parcels.length > 0) {
			mapInstance.current.getView().fit(parcelsLayer.current.getSource().getExtent(), { duration: 500 });
			// mapInstance.current.getView().fit(parcelsLayer.current.getSource().getFeatures().filter(f => f.get('pk') === 3102)[0].getGeometry(), {duration: 500});
		}
	}

	useEffect(() => {
		if (mapInstance.current && !props.loading && applicabilityLayer.current && monitoringLayer.current) {
			if (props.view === 'monitoring') {
				applicabilityLayer.current.setVisible(false);
				monitoringLayer.current.setVisible(true);
			} else {
				console.log(applicabilityLayer.current.getVisible())
				applicabilityLayer.current.setVisible(true);
				monitoringLayer.current.setVisible(false);

			}
		}
	}, [props.view]);

	useEffect(() => {
		if (mapInstance.current && !props.loading && applicabilityLayer.current) {
			$map.setApplicabilityLayer(layerA, applicabilityLayer.current);
		}
	}, [layerA]);

	useEffect(() => {
		if (mapInstance.current && !props.loading && monitoringLayer.current) {
			$map.setMonitoringLayer(layerM, monitoringLayer.current)
		}

	}, [layerM]);

	useEffect(() => {
		if (mapInstance.current && !props.loading && applicabilityLayer.current && timeA) {
			$map.updateApplicabilityParams(layerA, timeA, applicabilityLayer.current);
		}

	}, [timeA]);

	useEffect(() => {
		console.log(props.timeM)
		if (mapInstance.current && !props.loading && monitoringLayer.current && props.timeM) {
			console.log(props.timeM)
			$map.updateMonitoringParams(props.timeM, monitoringLayer.current);
		}

	}, [props.timeM]);

	useEffect(() => {
		if (mapInstance.current && !props.loading && monitoringLayer.current && timeAg) {
			$map.updateAgroforestryParams(timeAg, monitoringLayer.current);
		}

	}, [timeAg]);

	useEffect(() => {
		if (mapInstance.current && !props.loading && monitoringLayer.current && applicabilityLayer.current) {
			$map.setOpacity(opacity, applicabilityLayer.current, monitoringLayer.current);
		}

	}, [opacity]);

	useEffect(() => {
		if (!queryData) {
			setActiveFeature(null);
		}
	}, [JSON.stringify(queryData)])



	useEffect(() => {
		if (mapInstance.current) {
			console.log('BASE')
			$map.setBase(base, basemapList.current);
		}
	}, [base]);

	useEffect(() => {
		if (mapInstance.current) {
			if (!props.loading) {
				loadData();
			}
		}
	}, [props.loading]);

	useEffect(() => {
		if (mapInstance.current) {
			if (action) {
				unByKey(queryClick.current);
				interactions.current.draw.setActive(true);

				interactions.current.drawend = interactions.current.draw.on('drawend', (evt) => {
					setAddParcel(evt.feature);
				});
				mapInstance.current.getOverlays().getArray()[0].setPosition(null);

			} else {
				unByKey(interactions.current.drawend);
				queryClick.current = mapInstance.current.on('singleclick', query);
				interactions.current.draw.setActive(false);
				drawLayer.current.getSource().clear();
			}
		}
	}, [action])


	//QUERY
	const query = async (evt, id = null) => {
		if (action) return;

		if (id === null) {
			let samples = evt.map.getFeaturesAtPixel(evt.pixel, { layerFilter: (l) => l.get('name') === 'samples' });
			if (samples.length > 0) {
				let formated = [];

				samples.map(cluster => {
					let feats = cluster.get('features');
					feats.map(f => {
						formated.push(f.getProperties());
					})
				})

				setSamplesPreview(formated);
				return;
			};

			let photos = evt.map.getFeaturesAtPixel(evt.pixel, { layerFilter: (l) => l.get('name') === 'photos' });
			if (photos.length > 0) {
				let formated = [];

				photos.map(cluster => {
					let feats = cluster.get('features');
					feats.map(f => {
						formated.push(f.getProperties());
					})
				})

				setPhotoPreview(formated);
				return;
			};
		}




		if (id) {

			let parcel = props.data.parcels.filter(p => p.getId() === id)[0];

			console.log(parcel.getFeature().getGeometry().getInteriorPoint().getCoordinates());

			queryCall(parcel.getFeature().getGeometry().getInteriorPoint().getCoordinates(), id);

			// setActiveFeature(id);
			// setQueryData({});
			// setLoading(true);

			// try {
			// 	let data = await $data.getVerification(id);
			// 	setQueryData(data);
			// } catch (e) {

			// } finally {
			// 	setLoading(false);
			// }
			return;
		}
		let features = evt.map.getFeaturesAtPixel(evt.pixel, { layerFilter: (l) => l.get('name') === 'parcels' });

		if (features.length > 0) {
			queryCall(evt.coordinate, features[0].get('pk'));
		}
	}

	const queryCall = (coords, featureID) => {
		mapInstance.current.getOverlays().getArray()[0].setPosition(coords);

		setActiveFeature(featureID);
		setQueryData({});

		setLoading(true);
		setALoading(true);
		setMLoading(true);

		let coordinates = toLonLat(coords);

		$data.getVerification(featureID)
			.then(data => { setQueryData(data); setLoading(false) })
			.catch(err => {
				setLoading(false)
			})

		$data.pointQuery(coordinates[0], coordinates[1])
			.then(pointData => { setPointQueryData(pointData); setALoading(false) })
			.catch(err => { setALoading(false) })

		$data.monitoringQuery(coordinates[0], coordinates[1], featureID, props.projectId, user)
			.then(mData => { setMonitoringData(mData); setMLoading(false) })
			.catch(err => { setMLoading(false) })
	}

	//UPDATE PARCEL
	const onAddParcel = (parcels) => {
		onUpdateProjectData(props.projectId, { parcels: parcels });
		parcelsLayer.current.getSource().clear();
		parcelsLayer.current.getSource().addFeatures(parcels.map(p => p.getFeature()));
	}

	const onAddFarm = (farm) => {
		onUpdateProjectData(props.projectId, { farms: [...props.data.farms, farm] });
	}

	const onImportParcels = (parcels) => {
		onUpdateProjectData(props.projectId, { parcels: parcels })
		parcelsLayer.current.getSource().clear();
		parcelsLayer.current.getSource().addFeatures(parcels.map(p => p.getFeature()));
	}

	const refreshParcelsView = (parcels) => {

		parcelsLayer.current.getSource().clear();
		parcelsLayer.current.getSource().addFeatures(parcels.map(p => p.getFeature()));

	}

	const onDeleteParcel = (parcels) => {
		onUpdateProjectData(props.projectId, { parcels: parcels });

		parcelsLayer.current.getSource().clear();
		parcelsLayer.current.getSource().addFeatures(parcels.map(p => p.getFeature()));
	}

	const navigateAndQuery = (parcel) => {

		$map.navigateToParcel(parcel, mapInstance.current)
		query(null, parcel.getId());
	}


	return (
		<>
			<div style={{ width: '100%', height: '100%' }} ref={mapRef}></div>
			<DataWidget>
				{/* <BasemapWidget />	 */}
				<LayersWidget isAgroforestry={isAgroforestry} projectId={props.projectId} view={props.view} dates={props.dates} timeM={props.timeM} onSetDatesState={props.onSetDatesState} />
				<ReportsWidget projectId={props.projectId} view={props.view} />
				<ParcelsWidget selected={selected} data={props.data?.parcels} farms={props.data?.farms} loading={props.loading} onToggleAddFarm={() => setAddFarm(true)} onToggleManageFarms={() => setManageFarms(true)} onNavigateToParcel={navigateAndQuery} />
			</DataWidget>

			<ButtonGroup className="data-widget-mobile" orientation="vertical" sx={{ position: 'absolute', top: '20px', left: '10px' }}>
				<Button onClick={() => { }} variant="contained" sx={{ minWidth: 'unset', p: '12px 10px' }} color="primary"><Layers /></Button>
				<Button onClick={() => { }} variant="contained" sx={{ minWidth: 'unset', p: '12px 10px' }} color="primary"><MenuBook /></Button>
				<Button onClick={() => { }} variant="contained" sx={{ minWidth: 'unset', p: '12px 10px' }} color="primary"><ListAlt /></Button>

			</ButtonGroup>

			<Stack spacing={1} className="zoom-in-out">
				<Fab onClick={() => {mapInstance.current.getView().animate({zoom: mapInstance.current.getView().getZoom() + 0.5, duration: 250})}} size="small" variant="filled" color="primary"><Add /></Fab>
				<Fab onClick={() => {mapInstance.current.getView().animate({zoom: mapInstance.current.getView().getZoom() - 0.5, duration: 250})}} size="small" variant="filled" color="primary"><Remove /></Fab>
			</Stack>

			<Legend view={props.view} />
			<Toolbar selected={selected} action={action} onTogglePhotos={() => setPhotos(true)} onToggleDraw={toggleDraw} onToggleEdit={() => setEditParcel(selected)} uploadModal={uploadParcels} onToggleImportCroplab={setImportFromCroplab} onSetUploadModal={setUploadParcels} onToggleDelete={() => setDeleteParcel(selected)} />

			{queryData && <VerificationWidget onSetDatesState={props.onSetDatesState} onSetView={setQueryView} view={queryView} data={queryData} pointData={monitoringData} applicabilityData={pointQueryData} onClose={() => { setQueryData(null); setPointQueryData(null); setMonitoringData(null); mapInstance.current.getOverlays().getArray()[0].setPosition(null) }} loading={loading} aLoading={aLoading} mLoading={mLoading} />}

			{props.loading && <div className="co-background-opacity" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}>
				<Stack alignItems="center" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} spacing={2}>
					<CircularProgress sx={{ color: '#fff' }} />
					<p style={{ fontSize: '15px', color: '#fff' }} className="text-bold">LOADING PROJECT DATA</p>
				</Stack>
			</div>}



			{addParcel && <AddParcelModal projectId={props.projectId} open={Boolean(addParcel)} feature={addParcel} onClose={() => { setAddParcel(false); drawLayer.current.getSource().clear() }} onAddParcel={onAddParcel} />}
			{editParcel && <EditParcelModal projectId={props.projectId} model={editParcel} open={Boolean(editParcel)} onClose={() => { setEditParcel(null); setActiveFeature(null); setQueryData(null) }} onAddParcel={onAddParcel} />}

			{uploadParcels && <UploadParcelsModal open={uploadParcels} projectId={props.projectId} onClose={() => setUploadParcels(false)} onImportParcels={onImportParcels} />}
			{importFromCroplab && <ImportFromCroplab onRefreshParcelsView={refreshParcelsView} open={importFromCroplab} projectId={props.projectId} onClose={() => setImportFromCroplab(false)} />}

			{addFarm && <AddFarmModal projectId={props.projectId} open={addFarm} onClose={() => setAddFarm(false)} onAddFarm={onAddFarm} />}
			{deleteParcel && <DeleteParcelModal projectId={props.projectId} open={Boolean(deleteParcel)} parcelId={deleteParcel} onClose={() => { setDeleteParcel(null); setActiveFeature(null); setQueryData(null) }} onDeleteParcel={onDeleteParcel} />}
			{manageFarms && <ManageFarmsModal projectId={props.projectId} open={manageFarms} onClose={() => setManageFarms(false)} onToggleAddFarm={() => setAddFarm(true)} />}
			{photoPreview && <PhotosPreview photos={photoPreview} onClose={() => setPhotoPreview(null)} />}
			{samplesPreview && <SamplesPreview data={samplesPreview} open={Boolean(samplesPreview)} onClose={() => setSamplesPreview(null)} />}
			{photos && <PhotosModal projectId={props.projectId} open={photos} onClose={() => setPhotos(false)} />}


		</>
	)
}

export default Map;