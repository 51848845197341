import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";

function NewRequest(props) {
	const {user} = useContext(AppContext);
	const {projectData, projects, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
	const [state, setState] = useState({
		email: '',
		first_name: '',
		last_name: '',
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})


	const onSubmit = async () => {
		setLoading(true);

		try {
      await $data.requestFarmer({
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        farm: true
      })

      props.onRefresh();     
			onShowSuccess('Connection request sent! When farmer accepts the request, you will be able to import his/hers parcels to your projects/farms');
			props.onClose();
		} catch(e) {
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

  const getFarmsPerProject = (id) => {
    if(!id) return [];

    console.log(projects.results);
    console.log(id);
    let project = projects.results.filter(p => p.id === id)[0];
    console.log(project)
    if(!project) return [];
    


    return project.getFarms().map(f => {
      return {
        value: f.id,
        label: f.name
      }
    })
  
  }

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">New connection request</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
				<TextInput required value={state.email} error={Boolean(status.error.email)} helperText={status.error.email || null} onChange={(evt) => setState({...state, email: evt.target.value})} label="Email" />
				<TextInput required value={state.first_name} error={Boolean(status.error.first_name)} helperText={status.error.first_name || null} multiline onChange={(evt) => setState({...state, first_name: evt.target.value})} label="First name" />
				<TextInput required value={state.last_name} error={Boolean(status.error.last_name)} helperText={status.error.last_name || null} multiline onChange={(evt) => setState({...state, last_name: evt.target.value})} label="Last name" />
				{/* <SelectInput items={projects.results.map(p => ({value: p.id, label: p.name}))} value={state.project || undefined} onChange={(evt) => setState((state) => ({...state, project: evt.target.value, farm: undefined}))} name="Project" /> */}
        {/* <SelectInput items={getFarmsPerProject(state.project)} name="Farm to connect to" value={state.farm || undefined} onChange={(evt) => setState((state) => ({...state, farm: evt.target.value}))} /> */}

        {status.error.constructor == Array &&
					<>
						<Divider sx={{background: variables.dangerColor}} />
						<p className="co-danger" style={{fontSize: '13px', fontWeight: 'bold'}}>{status.error}</p>
					</>
				}
				
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading || state.email === '' || state.first_name === '' || state.last_name === ''} type="submit" style={{lineHeight: 'unset'}} color="primary">Confirm</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default NewRequest;