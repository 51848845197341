const base = process.env.REACT_APP_BRANCH === 'dev' ? 'https://dev.croplab.gilab.rs/' : 'https://app.croplab.info/';
// const base = 'https://app.croplab.info/'
const api = 'backend/api/';

const urls = {
  LOGIN: base + api + 'authentication/login/',
  LOGOUT: base + api + 'authentication/logout/',
  REGISTER: base + api + 'authentication/registration/', 
  RESET_PASSWORD_SEND: base + api + 'authentication/password/reset/',
	RESET_PASSWORD_CONFIRM: base + api + 'authentication/password/reset/confirm/',
	CHANGE_PASSWORD: base + api + 'authentication/password/change/',
	VERIFY_EMAIL: base + api + 'authentication/registration/verify-email/',
  TOKEN_REFRESH: base + api + 'authentication/token/refresh/',
	TOKEN_VERIFY: base + api + 'authentication/token-verify/',
  USER: base + api + 'authentication/user/',
  PROJECTS: base + api + 'ac/project/',
  PARCELS: base + api + 'ac/project/__ID__/parcels/',
  PARCELS_FARMER: base + api + 'parcel/',
  DATES_S2: base + api + 'ac/webservices/__ID__/dates/s2/',
  DATES_S2_FARMER: base + api + 'query/__ID__/dates/s2/',
  WMS: base + api + 'ac/webservices/wms',
  VERIFICATION: base + api + 'ac/verification/',
  PARCEL: base + api + 'parcel/',
  DECLARED_REPORT: base + api + 'ac/verification/activities-report/',
  DETECTION_REPORT: base + api + 'ac/verification/detected-practices-report/',
  APPLICABILITY_REPORT: base + api + 'ac/verification/applicability-report/',
  GENERATE_PDF: base + api + 'ac/verification/html-pdf/',
  FARMS: base + api + 'parcel/farm/',
  UPLOAD_PARCELS: base + api + 'parcel/shp_import/',
  REQUEST_FARMERS: base + api + 'authentication/projectant/farmer/',
  FARMER_PARCELS: base + api + 'parcel/farmer/__ID__/parcel_na/',
  ADD_FARMRE_PARCELS: base + api + 'ac/project/__ID__/parcel/add/',
  DISCONNECT_FARMER: base + api + 'ac/project/__ID__/farmer/delete/',
  PHOTOS: base + api + 'photos/',
  SAMPLES: base + api + 'sensor/collected-samples/',
  APPLICABILITY: base + api + 'query/ac/eligibility/',
  rothc: base + api + 'ac/exploration/rothc/',
  POINT: base + api + 'ac/webservices/__PROJECT__/query/',
  POINT_FARMER: base + api + 'parcel/__ID__/query/',
}

export default urls;