import { Tooltip } from "@mui/material";
import { variables } from "../../styles/abstract/variables";

function COTooltip(props) {

  return <Tooltip {...props} PopperProps={{
    sx: {
      "& .MuiTooltip-tooltip": {
        background: variables.secondary,
        color: variables.primary,
        fontSize: '14px',
        "& .MuiTooltip-arrow": {
          color: variables.secondary
        }
      }
    }
  }}  >{props.children}</Tooltip>
}

export default COTooltip;