import { Stack } from "@mui/system";

const style = {
  position: 'absolute',
  top: '20px',
  left: '10px',
  height: 'calc(100% - 30px)',
}

function DataWidget(props) {
  return (
    <Stack className="data-widget-desktop" style={style} spacing={2}>
      {props.children}
    </Stack>
  )
}

export default DataWidget;