import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import COTooltip from "../ui/COTooltip";

const legends = { 
  tcd : [
    {color: "#999999", label: "unclassifiable (no satel…"},
    {color: "#1c5c24", label: "100% tree cover density"},
    {color: "#1d5e26", label: "99% tree cover density",},
    {color: "#1f6127", label: "98% tree cover density",},
    {color: "#206328", label: "97% tree cover density",},
    {color: "#22662a", label: "96% tree cover density",},
    {color: "#24692c", label: "95% tree cover density",},
    {color: "#256b2c", label: "94% tree cover density",},
    {color: "#266e2e", label: "93% tree cover density",},
    {color: "#287030", label: "92% tree cover density",},
    {color: "#2a7332", label: "91% tree cover density",},
    {color: "#2b7533", label: "90% tree cover density",},
    {color: "#2e7834", label: "89% tree cover density",},
    {color: "#2f7a35", label: "88% tree cover density",},
    {color: "#317d37", label: "87% tree cover density",},
    {color: "#328038", label: "86% tree cover density",},
    {color: "#34823b", label: "85% tree cover density",},
    {color: "#35853a", label: "84% tree cover density",},
    {color: "#37873d", label: "83% tree cover density",},
    {color: "#388a3e", label: "82% tree cover density",},
    {color: "#3b8c40", label: "81% tree cover density",},
    {color: "#3d9143", label: "80% tree cover density",},
    {color: "#409445", label: "79% tree cover density",},
    {color: "#419645", label: "78% tree cover density",},
    {color: "#429946", label: "77% tree cover density",},
    {color: "#449c49", label: "76% tree cover density",},
    {color: "#469e4a", label: "75% tree cover density",},
    {color: "#45a148", label: "74% tree cover density",},
    {color: "#43a345", label: "73% tree cover density",},
    {color: "#41a641", label: "72% tree cover density",},
    {color: "#40a83e", label: "71% tree cover density",},
    {color: "#41ab3e", label: "70% tree cover density",},
    {color: "#41ad3b", label: "69% tree cover density",},
    {color: "#40b038", label: "68% tree cover density",},
    {color: "#41b536", label: "67% tree cover density",},
    {color: "#41b833", label: "66% tree cover density",},
    {color: "#42ba32", label: "65% tree cover density",},
    {color: "#42bd2f", label: "64% tree cover density",},
    {color: "#42bf2c", label: "63% tree cover density",},
    {color: "#42c229", label: "62% tree cover density",},
    {color: "#44c427", label: "61% tree cover density",},
    {color: "#42c724", label: "60% tree cover density",},
    {color: "#43cc21", label: "59% tree cover density",},
    {color: "#43cf1d", label: "58% tree cover density",},
    {color: "#44d119", label: "57% tree cover density",},
    {color: "#46d417", label: "56% tree cover density",},
    {color: "#47d613", label: "55% tree cover density",},
    {color: "#48d90f", label: "54% tree cover density",},
    {color: "#49db0b", label: "53% tree cover density",},
    {color: "#4de009", label: "52% tree cover density",},
    {color: "#4fe305", label: "51% tree cover density",},
    {color: "#4ce600", label: "50% tree cover density",},
    {color: "#53e605", label: "49% tree cover density",},
    {color: "#56e609", label: "48% tree cover density",},
    {color: "#5ae80e", label: "47% tree cover density",},
    {color: "#5de813", label: "46% tree cover density",},
    {color: "#61eb17", label: "45% tree cover density",},
    {color: "#63eb1a", label: "44% tree cover density",},
    {color: "#66eb1e", label: "43% tree cover density",},
    {color: "#6aed24", label: "42% tree cover density",},
    {color: "#6ded28", label: "41% tree cover density",},
    {color: "#71f02e", label: "40% tree cover density",},
    {color: "#75f032", label: "39% tree cover density",},
    {color: "#76f035", label: "38% tree cover density",},
    {color: "#7bf23a", label: "37% tree cover density",},
    {color: "#7ef23f", label: "36% tree cover density",},
    {color: "#82f545", label: "35% tree cover density",},
    {color: "#85f549", label: "34% tree cover density",},
    {color: "#87f54c", label: "33% tree cover density",},
    {color: "#8cf752", label: "32% tree cover density",},
    {color: "#8ff757", label: "31% tree cover density",},
    {color: "#94fa5c", label: "30% tree cover density",},
    {color: "#97fa61", label: "29% tree cover density",},
    {color: "#9afa66", label: "28% tree cover density",},
    {color: "#9dfc6a", label: "27% tree cover density",},
    {color: "#a1fc6f", label: "26% tree cover density",},
    {color: "#a4ff73", label: "25% tree cover density",},
    {color: "#a9ff73", label: "24% tree cover density",},
    {color: "#abff73", label: "23% tree cover density",},
    {color: "#b0ff73", label: "22% tree cover density",},
    {color: "#b4ff73", label: "21% tree cover density",},
    {color: "#b7ff73", label: "20% tree cover density",},
    {color: "#bbff73", label: "19% tree cover density",},
    {color: "#beff73", label: "18% tree cover density",},
    {color: "#c2ff73", label: "17% tree cover density",},
    {color: "#c5ff73", label: "16% tree cover density",},
    {color: "#c9ff73", label: "15% tree cover density",},
    {color: "#ceff73", label: "14% tree cover density",},
    {color: "#d0ff73", label: "13% tree cover density",},
    {color: "#d5ff73", label: "12% tree cover density",},
    {color: "#d7ff73", label: "11% tree cover density",},
    {color: "#dcff73", label: "10% tree cover density",},
    {color: "#deff73", label: "9% tree cover density", },
    {color: "#e3ff73", label: "8% tree cover density", },
    {color: "#e8ff73", label: "7% tree cover density", },
    {color: "#eaff73", label: "6% tree cover density", },
    {color: "#efff73", label: "5% tree cover density", },
    {color: "#f1ff73", label: "4% tree cover density", },
    {color: "#f6ff73", label: "3% tree cover density", },
    {color: "#f8ff73", label: "2% tree cover density", },
    {color: "#fdff73", label: "1% tree cover density", },
    {color: "#f0f0f0", label: "all non-tree covered are…"}
  ],
  wetlands: [
        {color: "#999999", label: "Unclassifiable (No image…"},
        {color: "#ffedc3", label: "Sea water",},
        {color: "#73ffdf", label: "Temporary wet"},
        {color: "#00c5ff", label: "Permanent wet",},
        {color: "#0070ff", label: "Temporary water"},
        {color: "#004da8", label: "Permanent water"},
        {color: "#ffffff", label: "Dry"}
  ],
  corine: [
    {
      "color": "#e6004d",
      "label": "111 - Continuous urban fabric",
      "_quantity": "1",
      "__prefix": "ns0"
    },
    {
      "color": "#ff0000",
      "label": "112 - Discontinuous urban fabric",
      "_quantity": "2",
      "__prefix": "ns0"
    },
    {
      "color": "#cc4df2",
      "label": "121 - Industrial or commercial units",
      "_quantity": "3",
      "__prefix": "ns0"
    },
    {
      "color": "#cc0000",
      "label": "122 - Road and rail networks and associated land",
      "_quantity": "4",
      "__prefix": "ns0"
    },
    {
      "color": "#e6cccc",
      "label": "123 - Port areas",
      "_quantity": "5",
      "__prefix": "ns0"
    },
    {
      "color": "#e6cce6",
      "label": "124 - Airports",
      "_quantity": "6",
      "__prefix": "ns0"
    },
    {
      "color": "#a600cc",
      "label": "131 - Mineral extraction sites",
      "_quantity": "7",
      "__prefix": "ns0"
    },
    {
      "color": "#a64d00",
      "label": "132 - Dump sites",
      "_quantity": "8",
      "__prefix": "ns0"
    },
    {
      "color": "#ff4dff",
      "label": "133 - Construction sites",
      "_quantity": "9",
      "__prefix": "ns0"
    },
    {
      "color": "#ffa6ff",
      "label": "141 - Green urban areas",
      "_quantity": "10",
      "__prefix": "ns0"
    },
    {
      "color": "#ffe6ff",
      "label": "142 - Sport and leisure facilities",
      "_quantity": "11",
      "__prefix": "ns0"
    },
    {
      "color": "#ffffa8",
      "label": "211 - Non-irrigated arable land",
      "_quantity": "12",
      "__prefix": "ns0"
    },
    {
      "color": "#ffff00",
      "label": "212 - Permanently irrigated land",
      "_quantity": "13",
      "__prefix": "ns0"
    },
    {
      "color": "#e6e600",
      "label": "213 - Rice fields",
      "_quantity": "14",
      "__prefix": "ns0"
    },
    {
      "color": "#e68000",
      "label": "221 - Vineyards",
      "_quantity": "15",
      "__prefix": "ns0"
    },
    {
      "color": "#f2a64d",
      "label": "222 - Fruit trees and berry plantations",
      "_quantity": "16",
      "__prefix": "ns0"
    },
    {
      "color": "#e6a600",
      "label": "223 - Olive groves",
      "_quantity": "17",
      "__prefix": "ns0"
    },
    {
      "color": "#e6e64d",
      "label": "231 - Pastures",
      "_quantity": "18",
      "__prefix": "ns0"
    },
    {
      "color": "#ffe6a6",
      "label": "241 - Annual crops associated with permanent crops",
      "_quantity": "19",
      "__prefix": "ns0"
    },
    {
      "color": "#ffe64d",
      "label": "242 - Complex cultivation patterns",
      "_quantity": "20",
      "__prefix": "ns0"
    },
    {
      "color": "#e6cc4d",
      "label": "243 - Land principally occupied by agriculture with significant areas of natural vegetation",
      "_quantity": "21",
      "__prefix": "ns0"
    },
    {
      "color": "#f2cca6",
      "label": "244 - Agro-forestry areas",
      "_quantity": "22",
      "__prefix": "ns0"
    },
    {
      "color": "#80ff00",
      "label": "311 - Broad-leaved forest",
      "_quantity": "23",
      "__prefix": "ns0"
    },
    {
      "color": "#00a600",
      "label": "312 - Coniferous forest",
      "_quantity": "24",
      "__prefix": "ns0"
    },
    {
      "color": "#4dff00",
      "label": "313 - Mixed forest",
      "_quantity": "25",
      "__prefix": "ns0"
    },
    {
      "color": "#ccf24d",
      "label": "321 - Natural grasslands",
      "_quantity": "26",
      "__prefix": "ns0"
    },
    {
      "color": "#a6ff80",
      "label": "322 - Moors and heathland",
      "_quantity": "27",
      "__prefix": "ns0"
    },
    {
      "color": "#a6e64d",
      "label": "323 - Sclerophyllous vegetation",
      "_quantity": "28",
      "__prefix": "ns0"
    },
    {
      "color": "#a6f200",
      "label": "324 - Transitional woodland-shrub",
      "_quantity": "29",
      "__prefix": "ns0"
    },
    {
      "color": "#e6e6e6",
      "label": "331 - Beaches - dunes - sands",
      "_quantity": "30",
      "__prefix": "ns0"
    },
    {
      "color": "#cccccc",
      "label": "332 - Bare rocks",
      "_quantity": "31",
      "__prefix": "ns0"
    },
    {
      "color": "#ccffcc",
      "label": "333 - Sparsely vegetated areas",
      "_quantity": "32",
      "__prefix": "ns0"
    },
    {
      "color": "#000000",
      "label": "334 - Burnt areas",
      "_quantity": "33",
      "__prefix": "ns0"
    },
    {
      "color": "#a6e6cc",
      "label": "335 - Glaciers and perpetual snow",
      "_quantity": "34",
      "__prefix": "ns0"
    },
    {
      "color": "#a6a6ff",
      "label": "411 - Inland marshes",
      "_quantity": "35",
      "__prefix": "ns0"
    },
    {
      "color": "#4d4dff",
      "label": "412 - Peat bogs",
      "_quantity": "36",
      "__prefix": "ns0"
    },
    {
      "color": "#ccccff",
      "label": "421 - Salt marshes",
      "_quantity": "37",
      "__prefix": "ns0"
    },
    {
      "color": "#e6e6ff",
      "label": "422 - Salines",
      "_quantity": "38",
      "__prefix": "ns0"
    },
    {
      "color": "#a6a6e6",
      "label": "423 - Intertidal flats",
      "_quantity": "39",
      "__prefix": "ns0"
    },
    {
      "color": "#00ccf2",
      "label": "511 - Water courses",
      "_quantity": "40",
      "__prefix": "ns0"
    },
    {
      "color": "#80f2e6",
      "label": "512 - Water bodies",
      "_quantity": "41",
      "__prefix": "ns0"
    },
    {
      "color": "#00ffa6",
      "label": "521 - Coastal lagoons",
      "_quantity": "42",
      "__prefix": "ns0"
    },
    {
      "color": "#a6ffe6",
      "label": "522 - Estuaries",
      "_quantity": "43",
      "__prefix": "ns0"
    },
    {
      "color": "#e6f2ff",
      "label": "523 - Sea and ocean",
      "_quantity": "44",
      "__prefix": "ns0"
    },
    {
      "color": "#ffffff",
      "label": "999 - NODATA",
      "_quantity": "48",
      "__prefix": "ns0"
    }
  ],
  worldcover: [
    {
      "color": "#006400",
      "_quantity": "10",
      "label": "Tree cover",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ffbb22",
      "_quantity": "20",
      "label": "Shrubland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ffff4c",
      "_quantity": "30",
      "label": "Grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#f096ff",
      "_quantity": "40",
      "label": "Cropland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#fa0000",
      "_quantity": "50",
      "label": "Built-up",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#b4b4b4",
      "_quantity": "60",
      "label": "Bare / sparse vegetation",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#f0f0f0",
      "_quantity": "70",
      "label": "Snow and ice",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#0064c8",
      "_quantity": "80",
      "label": "Permanent water bodies",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#0096a0",
      "_quantity": "90",
      "label": "Herbaceous wetland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#00cf75",
      "_quantity": "95",
      "label": "Mangroves",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#fae6a0",
      "_quantity": "100",
      "label": "Moss and lichen",
      "_opacity": "1.0",
      "__prefix": "sld"
    }
  ],
  plough: [
    {
      "color": "#8bb433",
      "_quantity": "0",
      "label": "No ploughing information",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#d75d3c",
      "_quantity": "1",
      "label": "1 year since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#a67df2",
      "_quantity": "2",
      "label": "2 years since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#1f8e7f",
      "_quantity": "3",
      "label": "3 years since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#3a5386",
      "_quantity": "4",
      "label": "4 years since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#a46370",
      "_quantity": "5",
      "label": "5 years since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#b0876b",
      "_quantity": "6",
      "label": "6 years since last indication of ploughing",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#318199",
      "_quantity": "255",
      "label": "Outside area - no data",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
  ],
  lc_uk: [
    {
      "color": "#e10000",
      "_quantity": "1",
      "label": "Broadleaved woodland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#006600",
      "_quantity": "2",
      "label": "Coniferous woodland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#732600",
      "_quantity": "3",
      "label": "Arable and horticulture",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#00ff00",
      "_quantity": "4",
      "label": "Improved grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#7fe57f",
      "_quantity": "5",
      "label": "Neutral grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#70a800",
      "_quantity": "6",
      "label": "Calcareous grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#998100",
      "_quantity": "7",
      "label": "Acid grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ffff00",
      "_quantity": "8",
      "label": "Fen, marsh and swamp",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#801a80",
      "_quantity": "9",
      "label": "Heather",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#e68ca6",
      "_quantity": "10",
      "label": "Heather grassland",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#008073",
      "_quantity": "11",
      "label": "Bog",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#d2d2ff",
      "_quantity": "12",
      "label": "Inland rock",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#000080",
      "_quantity": "13",
      "label": "Saltwater",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#0000ff",
      "_quantity": "14",
      "label": "Freshwater",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ccaa00",
      "_quantity": "15",
      "label": "Supralittoral rock",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ccb300",
      "_quantity": "16",
      "label": "Supralittoral sediment",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ffff80",
      "_quantity": "17",
      "label": "Littoral rock",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#ffff80",
      "_quantity": "18",
      "label": "Littoral sediment",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#8080ff",
      "_quantity": "19",
      "label": "Saltmarsh",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#000000",
      "_quantity": "20",
      "label": "Urban",
      "_opacity": "1.0",
      "__prefix": "sld"
    },
    {
      "color": "#808080",
      "_quantity": "21",
      "label": "Suburban",
      "_opacity": "1.0",
      "__prefix": "sld"
    },

  ],
  ndvi: [
      {
        "color": "#cccccc",
        "label": "Cloudy",
        "-opacity": "1.0",
        "-quantity": "0.01",
        "-self-closing": "true"
      },
      {
        "color": "#d7191c",
        "label": "<= 0.1",
        "-opacity": "1.0",
        "-quantity": "0.1",
        "-self-closing": "true"
      },
      {
        "color": "#e13e2d",
        "label": "0.1 - 0.15",
        "-opacity": "1.0",
        "-quantity": "0.15",
        "-self-closing": "true"
      },
      {
        "color": "#ea633e",
        "label": "0.15 - 0.2",
        "-opacity": "1.0",
        "-quantity": "0.2",
        "-self-closing": "true"
      },
      {
        "color": "#f48950",
        "label": "0.2 - 0.25",
        "-opacity": "1.0",
        "-quantity": "0.25",
        "-self-closing": "true"
      },
      {
        "color": "#fdae61",
        "label": "0.25 - 0.3",
        "-opacity": "1.0",
        "-quantity": "0.3",
        "-self-closing": "true"
      },
      {
        "color": "#fec379",
        "label": "0.3 - 0.35",
        "-opacity": "1.0",
        "-quantity": "0.35",
        "-self-closing": "true"
      },
      {
        "color": "#fed791",
        "label": "0.35 - 0.4",
        "-opacity": "1.0",
        "-quantity": "0.4",
        "-self-closing": "true"
      },
      {
        "color": "#ffeba8",
        "label": "0.4 - 0.45",
        "-opacity": "1.0",
        "-quantity": "0.45",
        "-self-closing": "true"
      },
      {
        "color": "#ffffc0",
        "label": "0.45 - 0.5",
        "-opacity": "1.0",
        "-quantity": "0.5",
        "-self-closing": "true"
      },
      {
        "color": "#e9f6ab",
        "label": "0.5 - 0.55",
        "-opacity": "1.0",
        "-quantity": "0.55",
        "-self-closing": "true"
      },
      {
        "color": "#d3ec95",
        "label": "0.55 - 0.6",
        "-opacity": "1.0",
        "-quantity": "0.6",
        "-self-closing": "true"
      },
      {
        "color": "#bce380",
        "label": "0.6 - 0.65",
        "-opacity": "1.0",
        "-quantity": "0.65",
        "-self-closing": "true"
      },
      {
        "color": "#a6d96a",
        "label": "0.65 - 0.7",
        "-opacity": "1.0",
        "-quantity": "0.7",
        "-self-closing": "true"
      },
      {
        "color": "#83c960",
        "label": "0.7 - 0.75",
        "-opacity": "1.0",
        "-quantity": "0.75",
        "-self-closing": "true"
      },
      {
        "color": "#60b855",
        "label": "0.75 - 0.8",
        "-opacity": "1.0",
        "-quantity": "0.8",
        "-self-closing": "true"
      },
      {
        "color": "#3da74b",
        "label": "0.8 - 0.85",
        "-opacity": "1.0",
        "-quantity": "0.85",
        "-self-closing": "true"
      },
      {
        "color": "#1a9641",
        "label": "0.85 - 0.9",
        "-opacity": "1.0",
        "-quantity": "0.9",
        "-self-closing": "true"
      },
      {
        "color": "#006600",
        "label": "0.9 - 1.0",
        "-opacity": "1.0",
        "-quantity": "1.1",
        "-self-closing": "true"
      }
  ].reverse(),
  ndvi_bg: [
    {
      "color": "#cccccc",
      "label": "Cloudy",
      "-opacity": "1.0",
      "-quantity": "0.01",
      "-self-closing": "true"
    },
    {
      "color": "#d7191c",
      "label": "<= 0.1",
      "-opacity": "1.0",
      "-quantity": "0.1",
      "-self-closing": "true"
    },
    {
      "color": "#e13e2d",
      "label": "0.1 - 0.15",
      "-opacity": "1.0",
      "-quantity": "0.15",
      "-self-closing": "true"
    },
    {
      "color": "#ea633e",
      "label": "0.15 - 0.2",
      "-opacity": "1.0",
      "-quantity": "0.2",
      "-self-closing": "true"
    },
    {
      "color": "#f48950",
      "label": "0.2 - 0.25",
      "-opacity": "1.0",
      "-quantity": "0.25",
      "-self-closing": "true"
    },
    {
      "color": "#fdae61",
      "label": "0.25 - 0.3",
      "-opacity": "1.0",
      "-quantity": "0.3",
      "-self-closing": "true"
    },
    {
      "color": "#fec379",
      "label": "0.3 - 0.35",
      "-opacity": "1.0",
      "-quantity": "0.35",
      "-self-closing": "true"
    },
    {
      "color": "#fed791",
      "label": "0.35 - 0.4",
      "-opacity": "1.0",
      "-quantity": "0.4",
      "-self-closing": "true"
    },
    {
      "color": "#ffeba8",
      "label": "0.4 - 0.45",
      "-opacity": "1.0",
      "-quantity": "0.45",
      "-self-closing": "true"
    },
    {
      "color": "#ffffc0",
      "label": "0.45 - 0.5",
      "-opacity": "1.0",
      "-quantity": "0.5",
      "-self-closing": "true"
    },
    {
      "color": "#e9f6ab",
      "label": "0.5 - 0.55",
      "-opacity": "1.0",
      "-quantity": "0.55",
      "-self-closing": "true"
    },
    {
      "color": "#d3ec95",
      "label": "0.55 - 0.6",
      "-opacity": "1.0",
      "-quantity": "0.6",
      "-self-closing": "true"
    },
    {
      "color": "#bce380",
      "label": "0.6 - 0.65",
      "-opacity": "1.0",
      "-quantity": "0.65",
      "-self-closing": "true"
    },
    {
      "color": "#a6d96a",
      "label": "0.65 - 0.7",
      "-opacity": "1.0",
      "-quantity": "0.7",
      "-self-closing": "true"
    },
    {
      "color": "#83c960",
      "label": "0.7 - 0.75",
      "-opacity": "1.0",
      "-quantity": "0.75",
      "-self-closing": "true"
    },
    {
      "color": "#60b855",
      "label": "0.75 - 0.8",
      "-opacity": "1.0",
      "-quantity": "0.8",
      "-self-closing": "true"
    },
    {
      "color": "#3da74b",
      "label": "0.8 - 0.85",
      "-opacity": "1.0",
      "-quantity": "0.85",
      "-self-closing": "true"
    },
    {
      "color": "#1a9641",
      "label": "0.85 - 0.9",
      "-opacity": "1.0",
      "-quantity": "0.9",
      "-self-closing": "true"
    },
    {
      "color": "#006600",
      "label": "0.9 - 1.0",
      "-opacity": "1.0",
      "-quantity": "1.1",
      "-self-closing": "true"
    }
].reverse(),
  ndvi_high: [
    {
      "color": "#cccccc",
      "label": "Cloudy",
      "-opacity": "1.0",
      "-quantity": "0.01",
      "-self-closing": "true"
    },
    {
      "color": "#d7191c",
      "label": "<= 0.1",
      "-opacity": "1.0",
      "-quantity": "0.1",
      "-self-closing": "true"
    },
    {
      "color": "#e13e2d",
      "label": "0.1 - 0.15",
      "-opacity": "1.0",
      "-quantity": "0.15",
      "-self-closing": "true"
    },
    {
      "color": "#ea633e",
      "label": "0.15 - 0.2",
      "-opacity": "1.0",
      "-quantity": "0.2",
      "-self-closing": "true"
    },
    {
      "color": "#f48950",
      "label": "0.2 - 0.25",
      "-opacity": "1.0",
      "-quantity": "0.25",
      "-self-closing": "true"
    },
    {
      "color": "#fdae61",
      "label": "0.25 - 0.3",
      "-opacity": "1.0",
      "-quantity": "0.3",
      "-self-closing": "true"
    },
    {
      "color": "#fec379",
      "label": "0.3 - 0.35",
      "-opacity": "1.0",
      "-quantity": "0.35",
      "-self-closing": "true"
    },
    {
      "color": "#fed791",
      "label": "0.35 - 0.4",
      "-opacity": "1.0",
      "-quantity": "0.4",
      "-self-closing": "true"
    },
    {
      "color": "#ffeba8",
      "label": "0.4 - 0.45",
      "-opacity": "1.0",
      "-quantity": "0.45",
      "-self-closing": "true"
    },
    {
      "color": "#ffffc0",
      "label": "0.45 - 0.5",
      "-opacity": "1.0",
      "-quantity": "0.5",
      "-self-closing": "true"
    },
    {
      "color": "#e9f6ab",
      "label": "0.5 - 0.55",
      "-opacity": "1.0",
      "-quantity": "0.55",
      "-self-closing": "true"
    },
    {
      "color": "#d3ec95",
      "label": "0.55 - 0.6",
      "-opacity": "1.0",
      "-quantity": "0.6",
      "-self-closing": "true"
    },
    {
      "color": "#bce380",
      "label": "0.6 - 0.65",
      "-opacity": "1.0",
      "-quantity": "0.65",
      "-self-closing": "true"
    },
    {
      "color": "#a6d96a",
      "label": "0.65 - 0.7",
      "-opacity": "1.0",
      "-quantity": "0.7",
      "-self-closing": "true"
    },
    {
      "color": "#83c960",
      "label": "0.7 - 0.75",
      "-opacity": "1.0",
      "-quantity": "0.75",
      "-self-closing": "true"
    },
    {
      "color": "#60b855",
      "label": "0.75 - 0.8",
      "-opacity": "1.0",
      "-quantity": "0.8",
      "-self-closing": "true"
    },
    {
      "color": "#3da74b",
      "label": "0.8 - 0.85",
      "-opacity": "1.0",
      "-quantity": "0.85",
      "-self-closing": "true"
    },
    {
      "color": "#1a9641",
      "label": "0.85 - 0.9",
      "-opacity": "1.0",
      "-quantity": "0.9",
      "-self-closing": "true"
    },
    {
      "color": "#006600",
      "label": "0.9 - 1.0",
      "-opacity": "1.0",
      "-quantity": "1.1",
      "-self-closing": "true"
    }
].reverse(),
  tree_class: [
    {
      "color": "#006600",
      "label": "Tree",
      "-opacity": "1.0",
      "-quantity": "0.9",
      "-self-closing": "true"
    },
    {
      "color": "#fff",
      "label": "No tree",
      "-opacity": "1.0",
      "-quantity": "1.1",
      "-self-closing": "true"
    },
  ],
  rgb: [],
  rgb_high: [] 
}


function Legend(props) {
  const { layerA, layerM } = useContext(DataContext);
  const container = useRef(null);

  const [visible, setVisible] = useState(true);


  useEffect(() => {
    if(props.view === 'monitoring') {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [props.view]);
  
  const data = props.view === 'monitoring' ? legends[layerM] : legends[layerA];
  console.log(data)
  const renderColors = () => {
    let height = container.current ? `${(container.current.offsetHeight) / data.length}px` : '0px';  
    // if(!visible) return null;

    if(props.view === 'monitoring' && layerM === 'rgb') return null;

    return data.map((obj, key) => {
      return <COTooltip key={key} placement="left" arrow title={obj.label}>
        <Box key={key} className="legend-color" sx={{height, backgroundColor: obj.color}}></Box>
      </COTooltip>
    })

  }


  if(props.view === 'monitoring' && layerM === 'rgb') return null;
  if(props.view === 'monitoring' && layerM === 'rgb_high') return null;

  return (
    <Paper sx={{background: variables.primary}} elevation={visible ? 3 : 3} className="legend-container">
      <div ref={container} className="color-wrapper">
        {renderColors()}
      </div>
    </Paper>
  )
}

export default Legend;