import { Analytics, ArrowBack, ChevronLeft, ChevronRight, Close, Dashboard, DateRange, Grain, Report, Search, SensorDoor, Sensors, SupervisedUserCircle, TaskAlt } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, CircularProgress, Divider, Fab, FormControl, Grid, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Slider, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import { useContext, useState } from "react";
import { applicabilityTemporalMap } from "../../config/map";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import WidgetDescription from "../widgets/WidgetDescription";
import SingleReport from "./SingleReport";

const container = {
  width: '320px',
  overflowY: 'hidden',
  overflowX: 'hidden',
  paddingBottom: '25px',
  background: variables.widget,
}


function ReportsWidget(props) {
  const { layerA, layerM, timeA, opacity, projectData, onUpdateMapData } = useContext(DataContext);
  const [singleReport, setSingleReport] = useState(null);

  const renderContent = () => {


    return;

  }

  return (
    <Paper sx={{ ...container }} className="co-background-primary-blur fade-in-2" elevation={4} component={Stack} alignItems="stretch">
      <Stack sx={{ padding: '15px', background: 'transparent' }} direction="row" alignItems="center" justifyContent="space-between">
        <WidgetDescription text="Reports" style={{ color: variables.secondary, fontWeight: 'bold' }} />
        {/* <ArrowBack color="secondary" /> */}
      </Stack>


      <Grid container spacing={2} sx={{ padding: '5px 10px 10px 10px' }}>
        <Grid item xs={6}>
          <Button fullWidth onClick={() => { setSingleReport('Applicability report') }} size="small" sx={{ lineHeight: 'unset' }} variant="contained" className="co-text-primary" color="secondary">Applicability</Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth onClick={() => { setSingleReport('Declared by farmer') }} size="small" sx={{ lineHeight: 'unset' }} variant="contained" className="co-text-primary" color="secondary">Declared</Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth onClick={() => { setSingleReport('Detected practices') }} size="small" sx={{ lineHeight: 'unset' }} variant="contained" className="co-text-primary" color="secondary">Detected</Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth onClick={() => { setSingleReport('Soil samples report') }} size="small" sx={{ lineHeight: 'unset' }} variant="contained" className="co-text-primary" color="secondary">Soil samples</Button>
        </Grid>
        {/* <Grid item xs={6}>
          <Button fullWidth onClick={() => {setSingleReport('Soil samples report')}} size="small" sx={{lineHeight: 'unset'}} variant="contained" className="co-text-primary" color="secondary">Photos</Button>
        </Grid> */}
      </Grid>



      {/* <Stack sx={{p: '0 15px 10px 15px'}} spacing={1}>
      </Stack> */}

      {singleReport && <SingleReport projectId={props.projectId} open={Boolean(singleReport)} parcels={projectData[props.projectId].parcels} farms={projectData[props.projectId].farms} title={singleReport} onClose={() => setSingleReport(null)} />}
    </Paper>
  )
}

export default ReportsWidget;