import { Add, AttachFile, Close, RequestPage, SendToMobile, TaskAlt, UploadFile, ViewModule } from "@mui/icons-material";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { variables } from "../../styles/abstract/variables";

function SamplesPreview(props) {

  const categorizeSamples = (data) => {
    let categories = {};

    data.map((obj) => {
      let key = obj.datestamp ? moment(obj.datestamp).format('YYYY') : 'N/A';
      if (categories.hasOwnProperty(key)) {
        categories[key].push(obj);
      } else {
        categories[key] = [obj];
      }
    })
    return categories;
  }

  const createColumns = () => {
    let columns = ['Parcel', 'Latitude', 'Longitude', 'Name', 'Date'];

    Object.keys(JSON.parse(props.data[0].dataset)).map(n => {
      columns.push(n);
    })

    console.log(columns)

    return columns;

  }

  const renderTable = (data) => {

  }

  const renderContent = () => {
    try {
      if (!props.data) return null;

      if (props.data.length === 0) {
        return (
          <Grid item xs={12}>
            <Typography variant="body2" color="primary"><strong>No samples available</strong></Typography>
          </Grid>
        )
      }

      let categories = categorizeSamples(props.data);
        
      console.log(categories)

      return Object.keys(categories).map((year, key) => {
        let columns = ['latitude', 'longitude', 'datestamp'];

        let additional = JSON.parse(categories[year][0].dataset);
        Object.keys(additional).map(col => {
          columns.push(col);
        })

        let rows = (c) => categories[year].map((obj, key) => {
          let additionalData = JSON.parse(obj.dataset);

          return (
            <TableCell key={key - 99999999}>
              {obj.hasOwnProperty(c) ? obj[c] : additionalData[c]}
            </TableCell>
          )
        })

        return (
          <Grid key={key} item xs={12}>
            <Table sx={{ width: '100%', overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: variables.primary, fontSize: '18px', fontWeight: 'bold' }} colSpan={categories[year].length + 1}>YEAR: {year}</TableCell>

                </TableRow>
                <TableRow>
                  <TableCell>Property</TableCell>
                  {categories[year].map((obj, j) => {
                    return (
                      <TableCell key={j}>{obj.name}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {columns.map((row, c) => {
                  return (
                    <TableRow key={c}>
                      <TableCell sx={{textTransform: 'uppercase'}}>{row}</TableCell>
                      {rows(row)}
                    </TableRow>
                  )
                })}
              </TableBody>

            </Table>
          </Grid>
        )
      })


      // return (
      //   <Grid item xs={12}>
      //     <Table sx={{width: '100%', overflow: 'auto'}}>
      //       <TableHead>
      //         <TableRow>
      //           <TableCell></TableCell>
      //         </TableRow>
      //         <TableRow>
      //           <TableCell>Property</TableCell>
      //           {props.data.map((c, k) => {
      //             return <TableCell key={k}>{c.name}</TableCell>
      //           })}
      //         </TableRow>
      //       </TableHead>
      //       <TableBody>
      //         {columns.map((col, key) => {

      //           return <TableRow key={key}>
      //             <TableCell key={-1}>{col}</TableCell>

      //            {props.data.map((obj, k) => {
      //               console.log(obj);
      //               if(!obj.hasOwnProperty(col)) {
      //                 return null
      //               }

      //               return (<>
      //               <TableCell>{obj.parcel__name}</TableCell>
      //               <TableCell>{obj.latitude || ' - '}</TableCell>
      //               <TableCell>{obj.longitude || ' - '}</TableCell>
      //               <TableCell>{obj.name || ' - '}</TableCell>
      //               <TableCell>{obj.datestamp || ' - '}</TableCell>
      //               {Object.values(JSON.parse(obj.dataset)).map((val, j) => {
      //                 return <TableCell key={j}>{val || ' - '}</TableCell>
      //               })}</>)
      //             })}
      //           </TableRow>
      //         })}
      //         {/* {props.data.map((obj, key) => {


      //           return (
      //             <TableRow key={key}>
      //               <TableCell>{obj.parcel__name}</TableCell>
      //               <TableCell>{obj.latitude || ' - '}</TableCell>
      //               <TableCell>{obj.longitude || ' - '}</TableCell>
      //               <TableCell>{obj.name || ' - '}</TableCell>
      //               <TableCell>{obj.datestamp || ' - '}</TableCell>
      //               {Object.values(JSON.parse(obj.dataset)).map((val, j) => {
      //                 return <TableCell key={j}>{val || ' - '}</TableCell>
      //               })}
      //             </TableRow>
      //           )
      //         })} */}
      //       </TableBody>
      //     </Table>

      //   </Grid>
      // )
    } catch (error) {
      console.log(error)
      return (
        <Grid item xs={12}>
          <Typography variant="body2" color="primary"><strong>No samples available</strong></Typography>
        </Grid>
      )
    }

  }

  return (
    <Dialog PaperProps={{ className: 'co-background-white' }} fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">Soil Samples</DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={2}>
            {renderContent()}
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>

        </DialogActions>
      </form>

    </Dialog>
  )
}

export default SamplesPreview;