export const variables = {
	primary: '#486c53',
	secondary: '#bbd560',
	widget: '#fff',
	button: '#486c53',
	greenColor: '#69f0ae',
	dangerColor: '#d32f2f',
	redColor: '#f75e5e',
	warningColor: '#FFAB40',
	materialWarning: '#E65100',
	secondaryOpacity:(opacity) =>  `rgba(187, 213, 96, ${opacity}`
}