import { Add, AttachFile, Close, RequestPage, SendToMobile, TaskAlt, UploadFile, ViewModule } from "@mui/icons-material";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import ManageParcels from "../farmers/ManageParcels";
import CroplabLogo from "../ui/CropLabLogo";
import SelectInput from "../ui/SelectInput";
import WidgetTitle from "../widgets/WidgetTitle";

const interactionStyle = {
  '&:hover': {
    transform: 'scale(1.05)'
  },
  '&:active': {
    transform: 'scale(1.05) translateY(2px)'
  }
}

function ImportFromCroplab(props) {
  const { projectData, onShowSuccess } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [farmers, setFarmers] = useState([]);
  const [manage, setManage] = useState(false);

  const getFarmers = async () => {
    setLoading(true)
    try {
      let farmers = await $data.getFarmers();
      setFarmers(farmers);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFarmers();
  }, [])


  const [error, setError] = useState(null);


  const renderContent = () => {
    if (loading) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <CircularProgress />
      </Grid>
    )

    if (error) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="error" variant="body1">Something went wrong while trying to fetch available requests</Typography>
      </Grid>
    )

    if (farmers.length === 0) {
      return <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="primary" variant="body1">There are no connections or pending requests</Typography>
      </Grid>
    }

    return (
      <Grid item xs={12}>
        {farmers.map((connection, key) => {
          return (
            <ListItem sx={{ mb: '10px' }} key={key} className="co-background"
              secondaryAction={<><Button color="primary" onClick={() => {setManage(connection.farmer_details.id)}} startIcon={<Add />}>Add parcels</Button></>}>
              <ListItemText primary={connection.farmer_details.email} secondary={connection.status ? <Chip color="success" label="CONNECTED" sx={{ mt: '10px' }} /> : <Chip color="warning" label="PENDING" sx={{ mt: '10px' }} />}></ListItemText>
            </ListItem>
          )
        })}
      </Grid>
    )
  }

  return (
    <Dialog PaperProps={{ className: 'co-background-white' }} fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">Import parcels from CropLab farmers</DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={2}>
            {renderContent()}

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button disabled={loading} style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>

        </DialogActions>
      </form>
      {loading && <LinearProgress color="primary" />}
      {manage && <ManageParcels projectId={props.projectId} open={Boolean(manage)} farmerId={manage} onClose={() => setManage(null)} onRefresh={getFarmers} onRefreshParcelsView={props.onRefreshParcelsView} />}

    </Dialog>
  )
}

export default ImportFromCroplab;