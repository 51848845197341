import { Button, ButtonGroup } from "@mui/material";
import { variables } from "../../styles/abstract/variables";

function MapViewSwitch({ view, onSetView }) {
  return (
    <ButtonGroup sx={{position: 'absolute', top: '20px', left: '50%', transform: 'translateX(-50%)'}}>
      <Button
        onClick={() => onSetView("applicability")}
        variant="contained"
				color={view == 'applicability' ? 'secondary' : 'primary'}
        sx={{
					opacity: view == 'applicability' ? 1 : 0.8,
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
          color: '#fff'
        }}
        size="small"
      >
        Applicability
      </Button>
      <Button
        onClick={() => onSetView("monitoring")}
        variant="contained"
				color={view == 'monitoring' ? 'secondary' : 'primary'}
        sx={{
					opacity: view == 'monitoring' ? 1 : 0.8,
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
          color: '#fff'

        }}
        size="small"
      >
        Monitoring
      </Button>
    </ButtonGroup>
  );
}

export default MapViewSwitch;
