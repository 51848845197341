import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";
import { Stack } from "@mui/system";
import { Add, AddCircle, Delete, Edit } from "@mui/icons-material";
import COTooltip from "../ui/COTooltip";
import DeleteFarmModal from "./DeleteFarmModal";
import EditFarmModal from "./EditFarmModal";

function ManageFarmsModal(props) {
	const {user} = useContext(AppContext);
	const {projectData, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
  const [deleteFarm, setDeleteFarm] = useState(null);
  const [editFarm, setEditFarm] = useState(null);


  const renderFarms = () => {
    const farms = projectData[props.projectId]?.farms;
    console.log(farms)
    if(!farms) {
      return
    }

    if(farms.length === 0) {
      return (
        <Grid item xs={12}>
          <Stack spacing={2}>
            <p className="text-center co-text-primary" >There are no created farms</p>
            <Button onClick={props.onToggleAddFarm} variant="contained" size="small" startIcon={<Add />}>Add farm</Button>
          </Stack>
        </Grid>
      )
    }

    return farms.map((farm, key) => {
      return <Grid key={key} item xs={12} md={6}>
        <Paper sx={{minHeight: '100px', p: '20px 10px 20px 20px'}} elevation={3}>
          <div className="co-text-primary">
            <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
              <strong>{farm.name}</strong>
              <div>
                <Edit sx={{cursor: 'pointer'}} onClick={() => setEditFarm(farm)} color="warning" />
                <Delete sx={{cursor: 'pointer'}} onClick={() => setDeleteFarm(farm.id)} color="error" />
              </div>
            </Stack>
          </div>
          <p className="co-text-opacity">{farm.description}</p>
        </Paper>
      </Grid>
    })
  }

	return (
		<Dialog fullWidth maxWidth="md" PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle component={Stack} justifyContent="space-between" direction="row" alignItems="center" color="primary">Manage farms <COTooltip arrow placement="left" title="Add new farm"><AddCircle onClick={props.onToggleAddFarm} sx={{cursor: 'pointer'}} /></COTooltip></DialogTitle>
			<form>
				<DialogContent>
          <Grid container spacing={2}>
            {renderFarms()}
          </Grid>
				
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Close</Button>					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}

      {deleteFarm && <DeleteFarmModal open={Boolean(deleteFarm)} onClose={() => setDeleteFarm(null)} farmId={deleteFarm} projectId={props.projectId} />}
      {editFarm && <EditFarmModal open={Boolean(editFarm)} onClose={() => setEditFarm(null)} model={editFarm} projectId={props.projectId} />}
		</Dialog>
	)
}

export default ManageFarmsModal;