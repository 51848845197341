import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, LinearProgress, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import TextInput from "../ui/TextInput";

function CreateProject(props) {
	const { page, onGetProjects, onShowSuccess } = useContext(DataContext);

	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		name: '',
		af_status: false,
		description: '',
		co2agri: true
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	useEffect(() => {
		if (props.open) {
			setState({ name: '', description: '', co2agri: true });
		}
	}, [props.open])

	const onSubmit = async () => {
		setLoading(true);

		try {
			let project = await $data.createProject(state);
			onShowSuccess(`${project.name} - project created`);
			await onGetProjects(page);
			props.onClose();

		} catch (e) {
			setStatus({ ...status, error: e });
		} finally {
			setLoading(false);
		}
	}


	return (
		<Dialog PaperProps={{ className: 'co-background-white' }} open={props.open} onClose={props.onClose}>

			<DialogTitle color="primary">Create new project</DialogTitle>
			<form onSubmit={(evt) => { evt.preventDefault(); onSubmit() }}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={8}>
							<TextInput value={state.name} onChange={(evt) => setState({ ...state, name: evt.target.value })} label="Name" />
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel control={<Checkbox checked={state.af_status} onChange={(evt, checked) => setState(current => ({...current, af_status: checked}))} />} label="Agroforestry"></FormControlLabel>
						</Grid>
						<Grid item xs={12}>
							<TextInput multiline value={state.description} onChange={(evt) => setState({ ...state, description: evt.target.value })} label="Description" />
						</Grid>
					</Grid>



				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{ lineHeight: 'unset' }} color="primary">Create</Button>

				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default CreateProject;