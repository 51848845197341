import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import $interceptor from './services/$interceptor';
import 'moment';
import 'moment/locale/sr';
import axios from 'axios';
import $auth from './services/$auth';
import $cookies from './services/$cookies';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';

$interceptor.init();
axios.defaults.headers.common['Accept-Language'] = 'en';

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = (Component, loggedIn, locale, user) => {
  root.render(
    // <React.StrictMode>
      <BrowserRouter>
        <Component loggedIn={loggedIn} locale={locale} user={user} />
      </BrowserRouter>

    // </React.StrictMode>
  );
}


const auth = () => {
  try {


    if ($cookies.get('refresh')) {
      $auth.refreshToken($cookies.get('refresh'))
        .then(refreshed => {
          console.log('REFRESH');
          $auth.getUser()
            .then(user => {
              render(App, true, user.language, user);
            })
            .catch(err => {
              $auth.unauthorize();
              render(App, false, 'en');
            })
        })
        .catch(err => {
          console.log(err);

          render(App, false, 'en');
        })
    } else {
      $auth.unauthorize();
      render(App, false, 'en')
    }
  } catch (e) {
    console.log(e);
  }
}

auth();
reportWebVitals();
