import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useState } from "react";
import { DataContext } from "../DataContext";
import PhotosPreview from "../ui/PhotosPreview";
import moment from "moment";

const tags = [
  'No-till',
  'Reduced or minimum tillage',
  'Cover crops',
  'Crop rotation',
  'Mulching',
  'Agroforestry and silvopasture',
  'Hedgerows, flower strips, shrubs',
  'Composting',
  'Biochar',
  'Holistically managed grazing',
  'Animal integration into crop production',
  'Perennial cropping',
];

function PhotosModal(props) {
  const { projectData } = useContext(DataContext);

  const photos = projectData[props.projectId].photos;

  const parcels = projectData[props.projectId].parcels;

  const [selected, setSelected] = useState(null);

  const [tag, setTag] = useState([]);

  const [parcel, setParcel] = useState([]);

  const [year, setYear] = useState([]);

  const renderFilterTags = () => {
    if (!photos) return null;

    if (photos.length === 0) return null;

    return (
      <Grid item xs={12}>
        <Typography sx={{ mb: '5px' }} variant="body2" color="primary"><strong>Filter by tag: </strong></Typography>
        <Stack direction="row" flexWrap={"wrap"}>
          {tags.map((t, j) => <Chip onClick={() => setTag((current) => current.indexOf(t) > -1 ? current.filter(c => c !== t) : [...current, t])} key={j} sx={{ ml: '5px', mb: '8px', cursor: 'pointer' }} label={`#${t}`} variant={tag.indexOf(t) > -1 ? 'filled' : 'filled'} color={tag.indexOf(t) > -1 ? "primary" : "secondary"} />)}
        </Stack>
      </Grid>
    )
  }

  const renderFilterParcels = () => {
    if (!parcels) return null;

    if (parcels.length === 0) return null;

    return (
      <Grid item xs={12}>
        <Typography sx={{ mb: '5px' }} variant="body2" color="primary"><strong>Filter by parcel: </strong></Typography>
        <Stack direction="row" flexWrap={"wrap"}>
          {parcels.map((p, j) => <Chip onClick={() => setParcel((current) => current.indexOf(p.getId()) > -1 ? current.filter(c => c !== p.getId()) : [...current, p.getId()])} key={j} sx={{ ml: '5px', mb: '8px', cursor: 'pointer' }} label={p.name} variant={parcel.indexOf(p.getId()) > -1 ? 'filled' : 'filled'} color={parcel.indexOf(p.getId()) > -1 ? "primary" : "secondary"} />)}
        </Stack>
      </Grid>
    )
  }

  const renderPhotosYear = () => {
    if (!photos) return null;

    if (photos.length === 0) return null;

    return (
      <Grid item xs={12}>
        <Typography sx={{ mb: '5px' }} variant="body2" color="primary"><strong>Filter by year: </strong></Typography>
        <Stack direction="row" flexWrap={"wrap"}>
          {[...new Set(photos.filter(p => p.date_time !== null).map(p => moment(p.date_time).format('YYYY')))].map((yr, key) => {
            return <Chip onClick={() => setYear(year.indexOf(yr) > -1 ? year.filter(y => y !== yr) : [...year, yr])} key={key} label={yr} color={year.indexOf(yr) > -1 ? "primary" : "secondary"} variant="filled" sx={{ cursor: 'pointer', ml: '5px', mb: '8px' }} />
          })}
        </Stack>
      </Grid>
    )
  }

  const renderPhotos = () => {
    if (!photos) return (
      <Typography variant="body2" color="error"><strong>Something went wrong while trying to display photos</strong></Typography>
    )

    if (photos.length === 0) {
      return (
        <Typography variant="body1" color="primary"><strong>There are no uploaded photos</strong></Typography>
      )
    }

    let filtered = photos.filter(p => {
      if (tag.length > 0) {
        if (tag.indexOf(p.tag) > -1) {
          return true;
        } else {
          return false
        }
      }

      return true;
    });

    filtered = [...filtered].filter(p => {
      if (year.length > 0) {
        if (!p.date_time) return false;

        if (year.indexOf(moment(p.date_time).format('YYYY')) > -1) {
          return true;
        } else {
          return false
        }

      }

      return true
    });

    filtered = [...filtered].filter(p => {
      if(parcel.length > 0) {
        if(parcel.indexOf(p.parcel) > - 1) {
          return true
        } else {
          return false
        }
      }

      return true
    })


    if (filtered.length === 0) {
      return (
        <Typography variant="body1" color="primary"><strong>There are no photos that match your filter query</strong></Typography>
      )
    }

    return (
      <Grid container spacing={2}>
        {filtered.map((p, key) => {
          return (
            <Grid key={key} item xs={12} sm={4} md={3} lg={2}>
              <Paper onClick={() => setSelected(p)} elevation={3} style={{ position: 'relative', backgroundImage: `url("${p.image}")`, backgroundPosition: 'center', backgroundSize: 'cover' }} sx={{ width: '100%', height: '150px', '&:hover': { cursor: 'pointer', transform: 'scale(1.03)' } }}>
                {p.tag && <Chip size="small" label={`#${p.tag}`} color="warning" variant="filled" sx={{ position: 'absolute', bottom: '2px', left: '2px', cursor: 'pointer' }} />}
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    )
  }



  return (
    <Dialog PaperProps={{ className: 'co-background-white' }} fullWidth maxWidth="lg" open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">Geo-tagged photos</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: '20px' }}>
          {renderFilterTags()}
          {renderPhotosYear()}
          {renderFilterParcels()}
          <Grid item xs={12}>
            {renderPhotos()}
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>

      </DialogActions>
      {selected && <PhotosPreview open={Boolean(selected)} photos={[selected]} onClose={() => { setSelected(null) }} />}

    </Dialog>
  )
}

export default PhotosModal;