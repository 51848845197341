import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";
import WidgetDescription from "../widgets/WidgetDescription";

function AddParcelModal(props) {
	const {user} = useContext(AppContext);
	const {projectData, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
	let initialFarm = 'null';
	if(user.user_type !== 1) {
		if(projectData[props.projectId].farms.length > 0) {
			initialFarm = (projectData[props.projectId].farms[0].id);
		}
		console.log(initialFarm)
	}

	
	const [state, setState] = useState({
		name: '',
		description: '',
    farm: initialFarm
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	const onSubmit = async () => {
		setLoading(true);

		try {

			let payload = {
				name: state.name,
				geometry: new WKT().writeFeature(props.feature)
			}
			if(user.user_type !== 1) {
				payload.project_id = props.projectId;
				payload.farm_id = state.farm === 'null' ? null : state.farm;
			}
			await $data.createParcel(payload)
			let parcels = await $data.getParcels(props.projectId, user.user_type === 1);
			console.log(parcels)
			props.onAddParcel(parcels);
			onShowSuccess('Parcel successfully created')
			props.onClose();
		} catch(e) {
			console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

  const farms = projectData[props.projectId]?.farms?.map(f => ({value: f.id, label: f.name})) || [];
	console.log(state.farm)
	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Create parcel</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
					{user.user_type !== 1 && <SelectInput helper="Farm selection is mandatory. Please create at least one farm if you haven't already" name="Select farm" items={[...farms]} value={state.farm} onChange={(evt, data) => {setState((st) => ({...st, farm: evt.target.value}))}} />}
					{user.user_type === 1 && <><WidgetDescription text="Parcel will be created in CropLab also" style={{marginBottom: '15px', fontWeight: 'bold', color: variables.dangerColor}} /> <br/><br/></>}
					<TextInput value={state.name} error={Boolean(status.error.name)} helperText={status.error.name || null} onChange={(evt) => setState({...state, name: evt.target.value})} label="Name" />
				{status.error.constructor == Array &&
					<>
						<Divider sx={{background: variables.dangerColor}} />
						<p className="co-danger" style={{fontSize: '13px', fontWeight: 'bold'}}>{status.error}</p>
					</>
				}
				
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading || ((farms.length === 0 || state.farm === 'null') && user.user_type !== 1)} type="submit" style={{lineHeight: 'unset'}} color="primary">Create</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default AddParcelModal;