import { Alert, Slide, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { defaults } from "../config/map";
import $data from "../services/$data";
import DataContextProvider from "./DataContext";
import LoadingData from "./widgets/LoadingData";
import backgroundImage from '../images/background.webp';
import { AppContext } from "../AppContext";

function Content(props) {
  const {user} = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(user.user_type !== 1);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [mapData, setMapData] = useState({
    ...defaults
  });

  const [projects, setProjects] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const [projectData, setProjectData] = useState({});

  const updateProjectData = (id, data) => {
    setProjectData((state) => {
      let pState = state[id] ? state[id] : {};
      return {
        ...state,
        [id]: {
          ...pState,
          ...data,
        },
      };
    });
  };

  const updateMapData = (data) => {
    setMapData((state) => ({
      ...state,
      ...data
    }));
  }

  useEffect(() => {
    if(user.user_type === 1) {
      // getParcels();
    } else {
      getProjects(page);

    }

  }, [page]);

  const getProjects = async (page) => {
    setLoading(true);
    try {
      let projectsData = await $data.getProjects(page);
      setProjects(projectsData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const updateProjects = async (page) => {
    try {
      let projectsData = await $data.getProjects(page);
      setProjects(projectsData);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  return (
    <DataContextProvider
      value={{
        ...mapData,
        projects: projects,
        onGetProjects: getProjects,
        onUpdateProjects: updateProjects,
        page: page,
        projectData: projectData,
        onUpdateMapData: updateMapData, 
        onUpdateProjectData: updateProjectData,
        onSetPage: setPage,
        onShowSuccess: setSuccess,
      }}
    >
      <div className="co-app" style={{backgroundImage: `url(${backgroundImage})`}}>{loading ? <LoadingData /> : props.children}</div>

      {/* SUCCESS */}
      <Snackbar
        TransitionComponent={Slide}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={Boolean(success)}
        autoHideDuration={6000}
        onClose={(evt, reason) => setSuccess(null)}
      >
        <Alert
          variant="filled"
          onClose={(evt, reason) => setSuccess(null)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      {/* ERROR */}
      <Snackbar
        TransitionComponent={Slide}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={(evt, reason) => setError(null)}
      >
        <Alert
          variant="filled"
          onClose={(evt, reason) => setError(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </DataContextProvider>
  );
}

export default Content;
