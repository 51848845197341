import { Typography } from "@mui/material";
import { Line, getDatasetAtEvent } from "react-chartjs-2";
import { variables } from "../../styles/abstract/variables";
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from "chart.js";
import { useRef } from "react";
Chart.register(zoomPlugin);

function IndexChart(props) {
  const chartRef = useRef();
  const formatData = (data) => {
    console.log(data)
    try {
      return {
        datasets: Object.keys(data).map((item) => {
          if (!data[item]) return null;

          if(item === 'BURN') {
            return {
              data: Object.keys(data[item]).map(date => ({ x: date, y: data[item][date] })),
              label: item,
              borderWidth: 0,
              pointRadius: 8,
              pointHitRadius: 8,
              pointHoverRadius: 8,
              order: 2,
  
            }
          }
          return {
            data: Object.keys(data[item]).map(date => ({ x: date, y: data[item][date] })),
            label: item,


          }
        }).filter(d => d !== null)
      }
    } catch (e) {
      return null
    }


  }

  const formatOptions = () => {
    return {
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x'
      },
      animation: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'YYYY-MM-DD'
            }
          },
          ticks: {
            maxRotation: 0,
            autoSkipPadding: 20,
            color: variables.secondary
          }
        },
        y: {
          ticks: {
            color: variables.secondary
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: variables.secondary
          }
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].raw.x
            },
            label: (context) => {
              return ` ${context.dataset.label}: ${(context.parsed.y).toFixed(2)}`
            }
          }
        },
        zoom: {
          onPan: () => {},
          onZoom: () => {},
          zoom: {
          
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: true
            },
            mode: 'x'
          },
          pan: {
            
            enabled: true,
            threshold: 1,
            mode: 'x',
            modifierKey: 'shift'
          }
        },
      }
    }
  }


  if (!props.data) {
    return <Typography color="secondary" variant="body1"><strong>Click inside parcel to get monitoring info</strong></Typography>
  }

  let formated = formatData(props.data);

  if (!formated) {
    return <Typography color="secondary" variant="body1"><strong>Information currently unavailable</strong></Typography>

  }

  return (
    <div style={{ width: '100%', height: '160px' }}>
      <Line ref={chartRef} style={{ width: '100%', height: '100%' }} data={formated} options={formatOptions()} onClick={(event) => {
        // console.log(getDatasetAtEvent(chartRef.current, event));
        let elements = chartRef.current.getElementsAtEventForMode(event, 'index', chartRef.current.options);
        let clicked = Object.keys(props.data['NDVI'])[elements[0].index];
        props.onSetDatesState((st) => ({...st, timeM: clicked}))
      }} />
    </div>
  )
}

export default IndexChart;