import { ArrowBack, ArrowLeft, AutoGraph, ChatRounded, ConnectingAirports, ConnectWithoutContact, ConnectWithoutContactOutlined, Logout, Menu, Person, Podcasts, PriorityHighRounded } from '@mui/icons-material';
import { Button, Fab, Paper } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AppContextProvider from './AppContext';
import EmailVerification from './components/auth/EmailVerification';
import ForgotPassword from './components/auth/ForgotPassword';
import Login from './components/auth/Login';
import NewPassword from './components/auth/NewPassword';
import Register from './components/auth/Register';
import Content from './components/Content';
import Farmers from './components/farmers/Farmers';
import MapWrapper from './components/map/MapWrapper';
import Profile from './components/profile/Profile';
import Projects from './components/projects/Projects';
import RothC from './components/rothc/RothC';
import LoadingData from './components/widgets/LoadingData';
import $auth from './services/$auth';
import $cookies from './services/$cookies';
import { variables } from './styles/abstract/variables';
import './styles/main.scss';
import UserLabel from './components/ui/UserLabel';

const theme = createTheme({
  palette: {
    primary: {
      main: variables.primary,
    },
    secondary: {
      main: variables.secondary
    },
    info: {
      main: variables.button,
      text: '#fff',
    }
  }
});


function App(props) {
  const timerRef = useRef(1800);
  const timerIntervalRef = useRef(null);

  const [state, setState] = useState({
    locale: props.locale,
    loggedIn: props.loggedIn,
    user: props.user,
  });

  const setTimer = () => {
    if (timerIntervalRef.current) { clearInterval(timerIntervalRef.current) }
    timerRef.current = 1800;
    timerIntervalRef.current = setInterval(() => {

      timerRef.current -= 1;
      if (timerRef.current === 0) {
        $auth.refreshToken($cookies.get('refresh'))
          .then(token => { this.setTimer() })
          .catch(err => { window.location.reload() })
      }
    }, 1000)
  }

  const onVisibilityChange = () => {
    if ($cookies.get('refresh')) {
      $auth.refreshToken($cookies.get('refresh'))
        .then(token => {
          setTimer()
        })
        .catch(err => { window.location.reload(); })
    }

  }

  useEffect(() => {
    if (state.loggedIn) {
      setTimer();
    }

    document.addEventListener('visibilitychange', onVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange, false);
    }
  }, []);

  useEffect(() => {
    if (state.loggedIn) {
      setTimer();
    } else {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
    }
  }, [state.loggedIn])

  const [loading, setLoading] = useState({
    projects: false
  });

  const updateState = (newState) => {
    setState({ ...state, ...newState });
  }

  const logout = async () => {
    try {
      await $auth.logout($cookies.get('refresh'));
      window.location.reload();

    } catch (e) {
      window.location.reload();

    }
  }


  const authCheck = (target, Fallback, props = {}) => {
    console.log(state.user)
    if (state.loggedIn) return <Redirect to={target} replace />

    if (Fallback.constructor === String) return <Redirect to={Fallback} replace />

    return <Fallback {...props} />
  }

  const userCheck = (user) => {
    if(!user) return '';

    if(user.user_type === 1) return 'map';

    return 'projects';
  }

  const isLoading = () => {
    if (loading.projects) return true;

    return false;
  }

  let { locale, user, loggedIn } = state;

  const location = useLocation();

  return (
    <AppContextProvider value={{ user, loggedIn, onUpdateState: updateState }}>
      <ThemeProvider theme={theme}>
        <Switch location={location}>
          <Route exact path="/" render={() => authCheck(`/dashboard/${userCheck(state.user)}`, '/login')} />

          <Route exact path="/login" render={(props) => { return authCheck(`/dashboard/${userCheck(state.user)}`, Login, { onUpdateState: updateState }) }} />

          <Route exact path="/register" render={() => authCheck(`/dashboard/${userCheck(state.user)}`, Register)} />

          <Route exact path="/forgot-password" render={() => authCheck(`/dashboard/${userCheck(state.user)}`, ForgotPassword)} />

          <Route exact path="/email-verification/:id" render={(props) => authCheck(`/dashboard/${userCheck(state.user)}`, EmailVerification, { id: props.match.params.id })} />

          <Route exact path="/reset-password/:uid/:token" render={(props) => authCheck(`/dashboard/${userCheck(state.user)}`, NewPassword, { uid: props.match.params.uid, token: props.match.params.token })} />

          <Route path="/dashboard" render={() => {
            if (!loggedIn) return <Redirect to="/login" />

            if (window.location.pathname === '/dashboard' || window.location.pathname === '/dashboard/') return <Redirect to={`/dashboard/projects`} />;
            if (isLoading()) return <LoadingData />;

            return (
              <Content>
                <Stack component={Paper} sx={{ zIndex: 10, padding: '15px', borderRadius: 0 }} className="co-background-primary" elevation={3} direction="row" spacing={2} justifyContent="space-between">
                  <div>
                    {/* <Fab size="small" color='secondary' children={<Menu style={{color: '#fff'}} />} /> &nbsp;&nbsp; */}
                    {user?.user_type !== 1 && window.location.pathname.indexOf('/dashboard/projects') == -1 && <Button component={Link} to="/dashboard/projects" sx={{ color: '#fff' }} color="secondary" startIcon={<ArrowBack />}>BACK TO PROJECTS</Button>}
                    {user?.user_type === 1 && window.location.pathname.indexOf('/dashboard/map') == -1 && <Button component={Link} to="/dashboard/map" sx={{ color: '#fff' }} color="secondary" startIcon={<ArrowBack />}>BACK TO MAP</Button>}
                    &nbsp;&nbsp;
                    <Button component={Link} to="/dashboard/profile" sx={{ color: '#fff' }} color="secondary" startIcon={<Person />}>PROFILE</Button>
                    {user?.user_type !== 1 && <Button component={Link} to="/dashboard/farmers" sx={{ color: '#fff' }} color="secondary" startIcon={<ConnectWithoutContactOutlined />}>FARMERS</Button>}
                    <Button component={Link} to="/dashboard/soc-sequestration" sx={{ color: '#fff' }} color="secondary" startIcon={<AutoGraph />}>SOC SEQUESTRATION</Button>
                    <UserLabel />
                  </div>
                  <div>
                    <Button onClick={logout} sx={{ color: '#fff' }} color="secondary" startIcon={<Logout />}>Logout</Button>
                  </div>
                </Stack>
                <div style={{ height: '100%' }}>
                  <Route exact path="/dashboard/projects" render={(props) => {
                    return <Projects />
                  }} />

                  <Route exact path="/dashboard/profile" render={(props) => {
                    return <Profile />
                  }} />

                  <Route exact path="/dashboard/farmers" render={(props) => {
                    return <Farmers />
                  }} />

                  <Route exact path="/dashboard/soc-sequestration" render={(props) => {
                    return <RothC />
                  }} />

                  {state.user?.user_type === 1 && <Route exact path="/dashboard/map" render={(props) => {
                    return <MapWrapper project={''} />
                  }} />}


                  <Route exact path="/dashboard/map/:project" render={(props) => {
                    return <MapWrapper project={props.match.params.project} />
                  }} />
                </div>

              </Content>
            )

          }} />

          <Route render={() => authCheck(`/dashboard/projects`, '/login')} />
        </Switch>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;
