import { Agriculture, Input, Satellite, Sensors, SensorWindow, SupervisedUserCircle, TaskAlt, VerifiedUser, ViewModule } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, LinearProgress, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import ApplicabilityTemplate from "../ui/ApplicabilityTemplate";
import DeclaredTemplate from "../ui/DeclaredTemplate";
import DetectionTemplate from "../ui/DetectionTemplate";
import SOCTemplate from "../ui/SOCTemplate";
import WidgetTitle from "../widgets/WidgetTitle";

const interactionStyle = {
  '&:hover': {
    transform: 'scale(1.05)'
  },
  '&:active': {
    transform: 'scale(1.05) translateY(2px)'
  }
}

function SingleReport(props) {
  const {user} = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);

  const [farms, setFarms] = useState([]);

  const [detectionReport, setDetectionReport] = useState(null);
  const [declaredReport, setDeclaredReport] = useState(null);
  const [applicabilityReport, setApplicabilityReport] = useState(null);
  const [socReport, setSOCReport] = useState(null);


  const onSubmit = async () => {
    let model = {
      parcels: selected
    }

    if (props.title === 'Detected practices') {
      setLoading(true);
      try {
        let data = await $data.getDetectionReport(model);
        setDetectionReport(data);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      return
    }

    if (props.title === 'Declared by farmer') {
      setLoading(true);
      try {
        let data = await $data.getDeclaredReport(model);
        setDeclaredReport(data);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      return
    }

    if (props.title === 'Applicability report') {
      setLoading(true);
      try {
        let data = await $data.getApplicabilityReport(model);
        setApplicabilityReport(data);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      return
    }

    if (props.title === 'Soil samples report') {
      setLoading(true);
      try {
        // let data = await $data.getApplicabilityReport(model);
        setSOCReport(selected);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      return
    }
  }

  const onSelect = (id) => {
    if (selected.indexOf(id) === -1) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter(i => i != id));
    }
  }

  const onSelectFarms = (f) => {
    if (farms.indexOf(f) === -1) {
      setFarms([...farms, f]);
    } else {
      setFarms(farms.filter(i => i != f));
    }
  }

  useEffect(() => {
    setSelected(props.parcels.filter(p => {
      return farms.indexOf(p.farm) > -1
    }).map(p => p.getId()));

  }, [JSON.stringify(farms)])

  const renderParcels = () => {
    if (!props.parcels) return null;

    if (props.parcels.length === 0) return (
      <Grid item xs={12}>
        <p>There are no created parcels</p>
      </Grid>
    )

    return (
      <>
        
        {user.user_type !== 1 && <Grid item xs={12}>
          <br/>
          <br/>
          <span className="co-text-primary"><strong>Select by parcel</strong></span>
        </Grid>}
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel control={<Checkbox checked={JSON.stringify(selected) === JSON.stringify(props.parcels.map(p => p.getId()))} onChange={(evt, checked) => { if (checked) { setSelected(props.parcels.map(p => p.getId())) } else { setSelected([]) } }} />} label="Select all" />
          </FormControl>
        </Grid>
        {
          props.parcels.map((p, key) => {
            return <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
              <Paper className="co-background-overlay" onClick={() => { }} sx={{ background: 'transparent', p: '10px', border: selected.indexOf(p.getId()) > -1 ? '2px solid ' + variables.primary : 'none' }} key={key}>
                <ListItem onClick={() => onSelect(p.getId())} sx={{ '&:hover': { background: 'rgba(255,255,255, 0.3)', transition: '.2s linear background', cursor: 'pointer' }, '&:active': { background: 'rgba(255,255,255, 0.1)' }, width: '100%' }} dense secondaryAction={user.user_type !== 1 && <span className="co-text-primary" ><Agriculture style={{ verticalAlign: 'middle', display: 'inline-block' }} fontSize="small" /> <span>{props.farms.filter(f => f.id == p.farm)[0]?.name || ' - '}</span></span>}>
                  <ListItemText sx={{ '&>span': {} }} primary={p.getName()} secondary={p.getArea() + 'ha'} />
                </ListItem>
              </Paper>
            </Grid>
          })
        }
      </>
    )



  }

  const renderFarms = () => {
    if (!props.farms) return null;

    if (props.farms.length === 0) return null;

    return (
      <Grid item xs={12}>
        <Stack>
          <p className="co-text-primary"><strong>Select by farm</strong></p>
          <Grid container spacing={1}>
            {props.farms.map((f, key) => {
              return (
                <Grid key={key} item xs={12} sm={6} md={4} lg={3}>

                  <FormControl>
                    <FormControlLabel control={<Checkbox checked={farms.indexOf(f.id) > -1} value={f.id} onChange={(evt, checked) => {  if (checked) { onSelectFarms(parseInt(evt.target.value)) } else { onSelectFarms(parseInt(evt.target.value)) } }} />} label={f.name} />
                  </FormControl>
                  {/* <Paper className={farms.indexOf(f.id) > -1 ? "co-background-primary" : "co-background-overlay"} onClick={() => {
                    onSelectFarms(f.id)
                  }} sx={{ cursor: 'pointer', p: '10px', color: farms.indexOf(f.id) > -1 ? variables.secondary : 'initial' }} key={key}>
                    {f.name}
                  </Paper> */}
                </Grid>
              )
            })}
          </Grid>

        </Stack>

      </Grid>
    )
  }

  return (
    <Dialog PaperProps={{ className: 'co-background-white' }} fullWidth maxWidth="xl" open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">{props.title}</DialogTitle>
      <form onSubmit={(evt) => { evt.preventDefault(); onSubmit() }}>
        <DialogContent>

          <Grid container spacing={2}>
            {user.user_type !== 1 && renderFarms()}

            {renderParcels()}

          </Grid>

        </DialogContent>
        <DialogActions sx={{ background: '#fff', position: 'sticky', bottom: 0 }}>
          <Button disabled={loading} style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>
          <Button disabled={selected.length === 0 || loading} type="submit" style={{ lineHeight: 'unset' }} color="primary">Generate</Button>

        </DialogActions>
      </form>
      {loading && <Stack sx={{position: 'sticky', bottom: 44}}><LinearProgress color="primary" /></Stack>}
      {detectionReport && <DetectionTemplate open={Boolean(detectionReport)} data={detectionReport} onClose={() => setDetectionReport(null)} />}
      {declaredReport && <DeclaredTemplate projectId={props.projectId} open={Boolean(declaredReport)} data={declaredReport} onClose={() => setDeclaredReport(null)} />}
      {applicabilityReport && <ApplicabilityTemplate projectId={props.projectId}  open={Boolean(applicabilityReport)} data={applicabilityReport} onClose={() => setApplicabilityReport(null)} />}
      {socReport && <SOCTemplate projectId={props.projectId} open={Boolean(socReport)} data={socReport} onClose={() => setSOCReport(null)} />}
    </Dialog>
  )
}

export default SingleReport;