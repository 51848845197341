import { Agriculture, Delete, Edit, Forest, Grass, Landscape, MenuBook, Report, Search } from "@mui/icons-material";
import { Button, Chip, Paper, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { variables } from "../../styles/abstract/variables";
import WidgetDescription from "../widgets/WidgetDescription";

function ProjectBox({project, onToggleReports, onToggleRemove, onToggleEdit}) {
  console.log(project)
  return (
    <Paper component={Stack} justifyContent={"space-between"} elevation={3} sx={{position: 'relative', padding: '15px', minHeight: '180px'}}  className="co-background-white fade-in">
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {/* <div style={{width: 64}}></div> */}
          <WidgetDescription text={project.getName()} style={{fontWeight: 'bold'}} />
          <Button color="warning" onClick={() => onToggleEdit(project)}  size="small" sx={{lineHeight: 'unset'}} startIcon={<Edit />}>EDIT</Button>
        </Stack>
        <p title={project.getDescription()} className="co-text-opacity text-bold multiline-ellipsis">{project.getDescription()}</p>
      </Stack>

      <Stack sx={{marginBottom: '40px'}} justifyContent="space-between" alignItems="center" direction="row" spacing={2}>
        <span className="co-text-opacity" style={{fontSize: '13px', fontWeight: 'bold'}}>{project.getDate()}</span>
        <Stack direction="row" alignItems="center" spacing={1}>
          {project.isAgroforestry ? <Forest color="primary" /> : <div style={{height: '24px'}}></div>}
          {project.isAgroforestry ? <strong className="co-text">Agroforestry</strong> : <div style={{height: '24px'}}></div>}
        </Stack>
      </Stack>


    
    <Stack direction="row" alignItems="center" justifyContent="space-between" style={{position: 'absolute', bottom: '15px', width: 'calc(100% - 30px'}}>
      <Button component={Link} to={`/dashboard/map/${project.getId()}`} style={{lineHeight: 'unset'}} startIcon={<Search fontSize="small" />} size="small" color="primary">OPEN</Button>
      <Button onClick={() => onToggleReports({parcels: project.parcels})}style={{lineHeight: 'unset'}} startIcon={<MenuBook fontSize="small" />} size="small" color="primary">Reports</Button>
      <Button onClick={() => onToggleRemove(project)} variant="contained" style={{lineHeight: 'unset'}} startIcon={<Delete fontSize="small" />} size="small" color="error">Delete</Button>

    </Stack>
      
    </Paper>
  )
}

export default ProjectBox;