import { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import MapViewSwitch from "./MapVIewSwitch";
import $data from "../../services/$data";
import Map from "./Map";
import { AppContext } from "../../AppContext";

function MapWrapper(props) {
	const { user } = useContext(AppContext);
	const { projectData, onUpdateProjectData, onUpdateMapData } = useContext(DataContext);
	// const [loading, setLoading] = useState({
	// 	parcels: !Boolean(projectData[props.project]?.parcels),
	// 	datesS2: !Boolean(projectData[props.project]?.parcels),
	// })

	const [loading, setLoading] = useState({
		parcels: true,
		datesS2: true,
		farms: user.user_type !== 1 ? true : false,
		photos: true,
		samples: true
	})

	const [view, setView] = useState('applicability');

	const [datesState, setDatesState] = useState({
		dates: [],
		timeM: null
	})

	useEffect(() => {
		if (user.user_type === 1) {
			getPhotos();

			getSamples();

			getParcels();

			getDatesS2();
		} else {
			//SET PARCELS AS ARRAY IF NOT EXIST - OTHERWISE SET LOADING TO FALSE
			if (!Boolean(projectData[props.project]?.parcels)) {
				onUpdateProjectData(props.project, { parcels: [] });
			} else {
				setLoading((state) => ({ ...state, parcels: false }));
			}

			getParcels();

			getDatesS2();

			getFarms();

			getPhotos();

			getSamples();
		}

	}, []);

	const getFarms = async () => {
		try {
			let farms = await $data.getFarms(props.project);
			onUpdateProjectData(props.project, { ...projectData[props.project], farms: farms })
		} catch (error) {

		} finally {
			setLoading((loadingState) => ({ ...loadingState, farms: false }));
		}

	}

	const getParcels = async () => {
		try {
			let parcels = await $data.getParcels(props.project, user.user_type === 1);
			onUpdateProjectData(props.project, { ...projectData[props.project], parcels: [...parcels] })
		} catch (e) {

		} finally {
			setLoading((loadingState) => ({ ...loadingState, parcels: false }));
		}
	}

	const getDatesS2 = async () => {
		try {
			let dates = await $data.getDatesS2(props.project, user.user_type === 1, user.pk);
			setDatesState((state) => ({ ...state, dates: dates, timeM: dates[dates.length - 1] }));

		} catch (e) {

		} finally {
			setLoading((loadingState) => ({ ...loadingState, datesS2: false }));
		}
	}

	const getPhotos = async () => {
		try {
			let photos = await $data.getPhotos(props.project);
			onUpdateProjectData(props.project, { ...projectData[props.project], photos });
		} catch (e) {

		} finally {
			setLoading((loadingState) => ({ ...loadingState, photos: false }));
		}
	}

	const getSamples = async () => {
		try {
			let samples = await $data.getSamples(props.project);
			
			onUpdateProjectData(props.project, { ...projectData[props.project], samples });
		} catch (e) {

		} finally {
			setLoading((loadingState) => ({ ...loadingState, samples: false }));
		}
	}


	return (
		<div style={{ height: '100%', position: 'relative' }} className="co-background-secondary-opacity fade-in">
			<Map projectId={props.project} view={view} data={projectData[props.project]} dates={datesState.dates} timeM={datesState.timeM} onSetDatesState={setDatesState} loading={loading.parcels || loading.datesS2 || loading.farms || loading.photos || loading.samples} />
			<MapViewSwitch view={view} onSetView={setView} />

		</div>
	)
}

export default MapWrapper;