import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";

function AddFarmModal(props) {
	const {user} = useContext(AppContext);
	const {projectData, onShowSuccess, onUpdateProjects} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
	const [state, setState] = useState({
		name: '',
		description: '',
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	useEffect(() => {
		if(props.open) {
			setState({name: '', description: '', farm: 'null'});
			setStatus({error: {}, success: null})
		}
	}, [props.open])

	const onSubmit = async () => {
		setLoading(true);

		try {
			console.log(user)
			let farm = await $data.createFarm({project: props.projectId, user: user.pk, name: state.name, description: state.description})
			props.onAddFarm(farm);
			onUpdateProjects();
			onShowSuccess('Farm successfully created');
			props.onClose();
		} catch(e) {
			console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

  const farms = projectData[props.projectId]?.farms?.map(f => ({value: f.id, label: f.name})) || [];

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Create farm</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
				<TextInput value={state.name} error={Boolean(status.error.name)} helperText={status.error.name || null} onChange={(evt) => setState({...state, name: evt.target.value})} label="Name" />
				<TextInput value={state.description} error={Boolean(status.error.description)} helperText={status.error.description || null} multiline onChange={(evt) => setState({...state, description: evt.target.value})} label="Description" />
				{status.error.constructor == Array &&
					<>
						<Divider sx={{background: variables.dangerColor}} />
						<p className="co-danger" style={{fontSize: '13px', fontWeight: 'bold'}}>{status.error}</p>
					</>
				}
				
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{lineHeight: 'unset'}} color="primary">Create</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default AddFarmModal;