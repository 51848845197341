import { Alert, Button, CircularProgress, Container, Divider, Grid, Paper, Slide, Snackbar, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import { variables } from "../../styles/abstract/variables";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";

import olMap from 'ol/Map';
import View from 'ol/View';
import { fromLonLat, toLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import MonthsForm from "./MonthsForm";
import MonthsCheckboxes from "./MonthsCheckboxes";
import ManualPools from "./ManualPools";
import ZimmermanForm from "./ZimmermanForm";
import SocForm from "./SocForm";
import Overlay from "ol/Overlay";
import { Room } from "@mui/icons-material";
import { renderToString } from "react-dom/server";
import urls from "../../config/urls";
import axios from "axios";
import DataViewer from "./DataViewer";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import $map from "../../services/$map";

function RothC(props) {
  const { onShowSuccess, projectData, projects } = useContext(DataContext);

  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  const [mandatory, setMandatory] = useState(0);
  const [optional, setOptional] = useState(0);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [parcelList, setParcelList] = useState([]);
  const [selectedParcel, setSelectedParcel] = useState(null);

  const [model, setModel] = useState({
    dr: 'agri',
    c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
    soc_unit: 'Mg/ha',
    period: 50,
    soil_thick: 30,
    clay: undefined,
    bare: [false, false, false, false, false, false, false, false, false, false, false, false,],

  })

  const checkZimmermanForm = (model) => {
    console.log(JSON.stringify(model))
    if(model.rpm && model.dpm && model.bio && model.hum && model.iom) return model;

    delete model.rpm;
    delete model.dpm;
    delete model.bio;
    delete model.hum;
    delete model.iom;

    return model;
  } 

  const checkManualForm = (model) => {
    if(model.doc && model.sa && model.pom && model.sc && model.r_soc) return model;

    delete model.doc;
    delete model.sa;
    delete model.pom;
    delete model.sc;
    delete model.r_soc;

    return model;
  }

  const checkSocForm = (model) => {
    if(model.soc && model.soc_year) return model;

    delete model.soc;
    delete model.soc_year;

    return model;
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      let formated = {
        ...model,
        c_inputs: model.c_inputs.map(c => parseFloat(c)),
        period: model.period ? parseFloat(model.period) : undefined,
        soil_thick: model.soil_thick ? parseFloat(model.soil_thick) : undefined,
        clay: model.clay ? parseFloat(model.clay) : undefined,
        soc: model.soc ? parseFloat(model.soc) : undefined,
        soc_year: model.soc_year ? parseFloat(model.soc_year) : undefined,
        rpm: model.rpm ? parseFloat(model.rpm) : undefined,
        dpm: model.dpm ? parseFloat(model.dpm) : undefined, 
        bio: model.bio ? parseFloat(model.bio) : undefined, 
        hum: model.hum ? parseFloat(model.hum) : undefined, 
        iom: model.iom ? parseFloat(model.iom) : undefined,
        doc: model.doc ? parseFloat(model.doc) : undefined,
        sa: model.sa ? parseFloat(model.sa) : undefined, 
        pom: model.pom ? parseFloat(model.pom) : undefined, 
        sc: model.sc ? parseFloat(model.sc) : undefined, 
        r_soc: model.r_soc ? parseFloat(model.r_soc) : undefined
      };

      if (formated.hasOwnProperty('fym')) {
        formated.fym = formated.fym.map(f => parseFloat(f));
      }

      let f1 = checkSocForm({...formated});
      let f2 = checkManualForm({...f1});
      let f3 = checkZimmermanForm({...f2});

      let result = await axios.post(urls.rothc, f3);
      setData(result.data);
    } catch (error) {
      setError('Something went wrong! Please check if you filled out mandatory information or if you are out of bounds (e.g. location is in water content - oceans, seas, lakes etc.)');
    } finally {
      setLoading(false);
    }
  }

  const updateModel = (newModel = {}, toRemove = []) => {
    let updated = {
      ...model
    };

    toRemove.map(param => {
      delete updated[param];
    })

    updated = {
      ...updated,
      ...newModel
    }

    setModel((current) => ({...updated}));
  }

  const onSelectLocation = (evt) => {
    let coordinates = toLonLat(evt.coordinate);
    mapInstance.current.getOverlays().getArray()[0].setPosition(evt.coordinate);
    updateModel({ lon: coordinates[0], lat: coordinates[1] });
  }

  useEffect(() => {
    let divA = document.createElement('div');
    divA.innerHTML = renderToString(<Room fontSize='large' color="error" style={{ fill: 'red' }} />);
    divA.style.width = '40px';
    let marker = new Overlay({
      id: 'A',
      element: divA,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, 5],
      className: 'co-marker-a',

    })

  
    let parcelLayer = $map.createProjectParcels(projectData);

    setParcelList(parcelLayer.getSource().getFeatures());

    mapInstance.current = new olMap({
      target: mapRef.current,
      overlays: [marker],
      layers: [
        new TileLayer({
          name: 'sat',
          zIndex: 0,
          visible: true,
          preload: Infinity,
          source: new BingMaps({
            crossOrigin: 'anonymous',
            key: 'AkyJi5QqTckvtgPG_BKw6XPDbn-afEtkcrOA_yT4fyG8FeS6ciHXU3HzAbLhiQGk',
            imagerySet: "AerialWithLabels",
            // hidpi: true,
            wrapX: false,
            maxZoom: 19
          })
        }),
        parcelLayer
      ],
      view: new View({
        zoom: 4,
        center: fromLonLat([20.5, 44])
      })
    })
    console.log(parcelLayer.getSource().getFeatures())
    if(parcelLayer.getSource().getFeatures().length !== 0) {
      mapInstance.current.getView().fit(parcelLayer.getSource().getExtent());
    }

    mapInstance.current.on('singleclick', onSelectLocation);
    return () => {
      if (mapInstance.current) {
        mapInstance.current.setTarget(null);
        mapInstance.current = null;
      }
    }
  }, [])

  useEffect(() => {
    updateModel(mandatory === 0 ? { c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] } : { c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,], fym: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] }, ['c_inputs', 'fym'])
  }, [mandatory])

  useEffect(() => {
    updateModel(null, ['rpm', 'dpm', 'bio', 'hum', 'iom', 'doc', 'sa', 'pom', 'sc', 'soc', 'r_soc', 'soc_year'])

  }, [optional])



  const renderMap = () => {
    return (
      <Grid item xs={12} >
        <Paper ref={mapRef} style={{ marginTop: '20px', height: '400px' }}></Paper>
      </Grid>
    )
  }

  const renderMandatoryForm = () => {
    return (
      <>
        <Grid item xs={12}>
          <Paper className="co-background" sx={{ p: '10px' }}>
            <Typography variant="body1" color="primary"><strong>Choose one of the options below (mandatory):</strong></Typography>
            <Tabs value={mandatory} selectionFollowsFocus onChange={(evt, val) => setMandatory(val)}>
              <Tab label="Option 1"></Tab>
              <Tab label="Option 2"></Tab>
            </Tabs>
            <Box sx={{ mb: '10px', mt: '10px' }}>

              {mandatory === 0 && (
                <MonthsForm title="Monthly C inputs to soil in t/ha/month" name="c_inputs" list={model.c_inputs || []} onUpdateModel={updateModel} />
              )}

              {mandatory === 1 && (<>
                <MonthsForm title="The amount of litter inputs by month in t/ha" name="c_inputs" list={model.c_inputs || []} onUpdateModel={updateModel} />
                <br />
                <MonthsForm title="The amount of Farm Yard Manure inputs by month in t/ha" name="fym" list={model.fym || []} onUpdateModel={updateModel} />
              </>
              )}
            </Box>


          </Paper>
        </Grid>
      </>
    )
  }

  const renderOptionalForm = () => {

    return (
      <Grid item xs={12}>
        <Paper className="co-background" sx={{ p: '10px' }}>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" color="primary"><strong>Optional parameters:</strong></Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" size="small" label="Prediction period in future" value={model.period} onChange={(evt) => updateModel({ period: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" size="small" label="Soil thickness (organic layer topsoil) in cm" value={model.soil_thick} onChange={(evt) => updateModel({ soil_thick: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" inputProps={{ min: 0, max: 100 }} size="small" label="Clay percentage" value={model.clay} onChange={(evt) => updateModel({ clay: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12}>
              <MonthsCheckboxes list={model.bare} onUpdateModel={updateModel} name="bare" />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  const renderOptionalTabs = () => {
    return (
      <>
        <Grid item xs={12}>
          <Paper className="co-background" sx={{ p: '10px' }}>
            <Typography variant="body1" color="primary"><strong>Choose one of the options below (optional):</strong></Typography>
            <Tabs value={optional} selectionFollowsFocus onChange={(evt, val) => {
                updateModel(null,  ['rpm', 'dpm', 'bio', 'hum', 'iom', 'doc', 'sa', 'pom', 'sc', 'soc', 'r_soc', 'soc_year'])
                setOptional(val)
              }}>
              <Tab label="Pedotransfer functions"></Tab>
              <Tab label="Manually input 5 pools in t/ha"></Tab>
              <Tab label="Zimmermann’s fractionation scheme"></Tab>

            </Tabs>
            <Box sx={{ mb: '10px', mt: '10px' }}>

              {optional === 2 && (
                <ZimmermanForm onUpdateModel={updateModel} model={model} />
              )}

              {optional === 1 && (<>
                <ManualPools onUpdateModel={updateModel} model={model} />
                
              </>
              )}

              {optional === 0 && (<>
                <SocForm onUpdateModel={updateModel} model={model} />
              </>
              )}
            </Box>


          </Paper>
        </Grid>
      </>
    )
  }

  const renderLoader = () => {
    if (loading) return (
      <Stack sx={{ background: 'rgba(0,0,0,.7)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999999 }} justifyContent="center" alignItems="center">
        <CircularProgress sx={{ color: 'white' }}></CircularProgress>
      </Stack>
    );

    return null;
  }


  const renderContent = () => {
    return (
      <Grid container spacing={2}>

        {renderMap()}
        {parcelList.length !== 0 && <Grid item xs={12} >
          <Paper sx={{ p: '10px' }} className="co-background">
            <Typography variant="body1" color="primary"><strong>Select location by parcel (optional):</strong> </Typography>
            <Stack sx={{mt: '20px'}}>
              <SelectInput name="Parcel" items={parcelList.map(f => ({value: f.get('pk'), label: `${f.get('name')} (${f.get('area').toFixed(2)}ha)`}))} value={selectedParcel || undefined} onChange={(evt, data) => {
                let sel = parcelList.filter(f => f.get('pk') === evt.target.value)[0];
                try {
                  let fullCoords = sel.getGeometry().getInteriorPoint().getCoordinates();
                  onSelectLocation({coordinate: [fullCoords[0], fullCoords[1]]})
                } catch(e) {

                }
              }} />
            </Stack>
          </Paper>
        </Grid>}

        <Grid item xs={12} >
          <Paper sx={{ p: '10px' }} className="co-background">
            <Typography variant="body1" color="primary"><strong>Location (mandatory): {model.lat && model.lon ? `${model.lat}, ${model.lon}` : ' - '}</strong> </Typography>
            <Stack sx={{mt: '20px'}}>
              <SelectInput name="Plant on the parcel" items={[{value: 'agri', label: 'Agricultural crops'}, {value: 'wood', label: 'Deciduous or tropical woodland'}, { value: 'grass', label: 'Grassland and scrub'}]} value={model.dr} onChange={(evt, data) => updateModel({'dr': evt.target.value})} />
            </Stack>
          </Paper>
        </Grid>

        {renderMandatoryForm()}

        <br />
        {renderOptionalForm()}
        <br />
        {renderOptionalTabs()}

        <Grid item xs={12} sx={{ position: 'sticky', bottom: 0, zIndex: 99 }}>
          <Paper className="co-background" sx={{ textAlign: 'center', padding: '10px', position: 'sticky', top: 0, zIndex: 99 }}>
            <Button onClick={onSubmit} disabled={loading || !model.lat || !model.lon} color="primary" variant="contained">EXECUTE QUERY</Button>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Container sx={{ mt: '30px', pb: '30px' }}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <WidgetTitle text="SOC Sequestration" />
        </Grid>
        <Grid item xs={12}>
          <Stack className="only-laptop" direction="row" alignItems="center" justifyContent="space-between">
            <WidgetDescription text="Select location on the map and fill out the form to get SOC sequestration estimate over years" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider color="primary" light style={{ background: variables.primary, height: '3px' }} />
        </Grid>
      </Grid>
      {renderContent()}

      {renderLoader()}

      {data && <DataViewer open={Boolean(data)} coordinates={[model.lat, model.lon]} onClose={() => setData(null)} data={data} />}

      {/* ERROR */}
      <Snackbar
        TransitionComponent={Slide}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={Boolean(error)}
        autoHideDuration={10000}
        onClose={(evt, reason) => setError(null)}
      >
        <Alert
          variant="filled"
          onClose={(evt, reason) => setError(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default RothC;