import ReactDOMServer from 'react-dom/server';
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import BingSource from 'ol/source/BingMaps';
import URL from "./urls";
import { createXYZ } from 'ol/tilegrid';
import axios from "axios";
import Overlay from "ol/Overlay";
import { Room } from "@mui/icons-material";


export const applicabilityTemporalMap = {
	'tcd': ['2012', '2015', '2018'],
	'wetlands': ['2015', '2018'],
	'corine': ['2000', '2006', '2012', '2018'],
	'worldcover': ['2020'],
	'plough': ['2018'],
	'lc_uk': ['2007', '2017', '2018', '2019', '2020']
}

export const applicabilityDimensions = {
	'tcd': 'LAYERS',
	'wetlands': 'LAYERS',
	'corine': 'DIM_YEAR',
	'worldcover': 'LAYERS',
	'plough': 'LAYERS',
	'lc_uk': 'LAYERS'
}

export const monitoringTemporalMap = {
	"ndvi_high": ['2021', '2022'], 'tree_class': ['2021', '2022'], 'rgb_high': ['2021', '2022']
}

const tileLoadFunction = (tile, src) => {
	axios.get(src, { responseType: 'blob' })
		.then(result => {
			if (result.data !== undefined) tile.getImage().src = window.URL.createObjectURL(result.data);
			else tile.getImage().src = '';
		})
		.catch(err => { tile.getImage().src = ''; });
}

export const defaults = {
	base: 'sat',
	layerM: 'ndvi',
	layerA: 'tcd',
	timeA: applicabilityTemporalMap['tcd'][applicabilityTemporalMap['tcd'].length - 1],
	timeM: null,
	timeAg: '2022',
	view: 'applicability',
	opacity: 100,
	zoom: 4,
	center: [9.2551, 54.5260],
}


export const basemaps = (active) => {
	return [
		new TileLayer({
			name: 'sat',
			zIndex: 0,
			visible: active === 'sat',
			preload: Infinity,
			source: new BingSource({
				crossOrigin: 'anonymous',
				key: 'AkyJi5QqTckvtgPG_BKw6XPDbn-afEtkcrOA_yT4fyG8FeS6ciHXU3HzAbLhiQGk',
				imagerySet: "AerialWithLabels",
				// hidpi: true,
				wrapX: false,
				maxZoom: 19
			})
		}),
		new TileLayer({
			name: 'map',
			zIndex: 0,
			visible: active === 'map',
			preload: Infinity,
			source: new BingSource({
				crossOrigin: 'anonymous',
				key: 'AkyJi5QqTckvtgPG_BKw6XPDbn-afEtkcrOA_yT4fyG8FeS6ciHXU3HzAbLhiQGk',
				imagerySet: "Road",
				// hidpi: true,
				wrapX: false,
				maxZoom: 19
			})
		})
	]
}

export const applicability = (id, active, time, opacity, isUK) => {
	console.log('UK:', isUK);
	let l = [
		new TileLayer({
			name: 'tcd',
			displayName: 'Tree Cover Density',
			baseName: 'ac:TCD_[time]',
			zIndex: 2,
			preload: Infinity,
			opacity: opacity / 100,
			visible: active == 'tcd',
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `ac:TCD_${time}`,
				}
			})
		}),
		new TileLayer({
			name: 'wetlands',
			displayName: 'Wetlands',
			baseName: 'ac:WW_[time]',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'wetlands',
			opacity: opacity / 100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `ac:WW_${time}`,
				}
			})
		}),
		new TileLayer({
			name: 'corine',
			displayName: 'CORINE Land Cover',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'corine',
			opacity: opacity / 100,
			source: new TileWMS({
				url: 'https://geoserver.opendatascience.eu/geoserver/wms/?',
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `gh:lcv-landcoverXclc-corine-c-100m-0XX0cm--eumap-epsg3035-v2020`,
					'DIM_YEAR': time
				}
			})
		}),
		new TileLayer({
			name: 'worldcover',
			displayName: 'World Cover',
			baseName: 'WORLDCOVER_[time]_MAP',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'worldcover',
			opacity: opacity / 100,
			source: new TileWMS({
				url: 'https://services.terrascope.be/wms/v2',
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: { 'LAYERS': `WORLDCOVER_${time}_MAP`, 'srs': 'EPSG:3857' }
			})
		}),
		new TileLayer({
			name: 'plough',
			displayName: 'Ploughing < 2018',
			baseName: 'ac:PLOUGH_2018_010m_eu_03035',
			zIndex: 2,
			preload: Infinity,
			opacity: opacity / 100,
			visible: active === 'plough',
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `ac:PLOUGH_2018_010m_eu_03035`,
				}
			})
		}),
	]

	if (true) {
		l.push(
			new TileLayer({
				name: 'lc_uk',
				displayName: 'Land Cover UK',
				baseName: `ac:LC_UK_[time]`,
				zIndex: 2,
				preload: Infinity,
				opacity: opacity / 100,
				visible: active === 'lc_uk',
				source: new TileWMS({
					url: URL.WMS,
					tileGrid: createXYZ({ tileSize: 512 }),
					tileLoadFunction: tileLoadFunction,
					params: {
						'LAYERS': `ac:LC_UK_${time}`,
					}
				})
			}),
		)
	}

	return l;
}


export const monitoring = (id, active, time, opacity, isPlanet, isAgroforestry, year) => {
	let layers = [
		new TileLayer({
			name: 'rgb',
			zIndex: 2,
			preload: Infinity,
			visible: active == 'rgb',
			opacity: opacity / 100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `${id}:${id}_rgb${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'TIME': time
				}
			})
		}),
		new TileLayer({
			name: 'ndvi',
			displayName: 'Vegetation Index (NDVI)',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'ndvi',
			opacity: opacity / 100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `${id}:${id}_ndvi${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'TIME': time
				}
			})
		}),
		new TileLayer({
			name: 'chl',
			displayName: 'Leaf Chlorophyll Index (CHL)',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'chl',
			opacity: opacity / 100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: {
					'LAYERS': `${id}:${id}_chl${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'TIME': time
				}
			})
		}),
		new TileLayer({
			name: 'burn',
			displayName: 'Burn Index (BI)',
			zIndex: 2,
			preload: Infinity,
			visible: active === 'burn',
			opacity: opacity / 100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({ tileSize: 512 }),
				tileLoadFunction: tileLoadFunction,
				params: { 'LAYERS': `${id}:${id}_burn${isPlanet ? '_planet' : ''}`, 'TIME': time, 'TILED': true,}
			})
		}),
	]

	if (isAgroforestry) {
		layers.push(
			new TileLayer({
				name: 'ndvi_bg',
				displayName: 'NDVI Background',
				zIndex: 2,
				preload: Infinity,
				visible: active === 'ndvi_bg',
				opacity: opacity / 100,
				source: new TileWMS({
					url: URL.WMS,
					tileGrid: createXYZ({ tileSize: 512 }),
					tileLoadFunction: tileLoadFunction,
					params: { 'LAYERS': `${id}:${id}_ndvihres${isPlanet ? '_planet' : ''}`, 'TIME': time, 'TILED': true,}
				})
			})
		);

		layers.push(
			new TileLayer({
				name: 'tree_class',
				displayName: 'Tree Classification',
				zIndex: 2,
				preload: Infinity,
				visible: active === 'tree_class',
				opacity: opacity / 100,
				source: new TileWMS({
					url: URL.WMS,
					tileGrid: createXYZ({ tileSize: 512 }),
					tileLoadFunction: tileLoadFunction,
					params: { 'LAYERS': `${id}:${id}_vhr_tree${isPlanet ? '_planet' : ''}`, 'TIME': year, 'TILED': true,}
				})
			})
		);

		layers.push(
			new TileLayer({
				name: 'ndvi_high',
				displayName: 'NDVI High resolution',
				zIndex: 2,
				preload: Infinity,
				visible: active === 'ndvi_high',
				opacity: opacity / 100,
				source: new TileWMS({
					url: URL.WMS,
					tileGrid: createXYZ({ tileSize: 512 }),
					tileLoadFunction: tileLoadFunction,
					params: { 'LAYERS': `${id}:${id}_vhr_ndvi${isPlanet ? '_planet' : ''}`, 'TIME': year, 'TILED': true,}
				})
			})
		);

		layers.push(
			new TileLayer({
				name: 'rgb_high',
				displayName: 'Imagery (High resolution)',
				zIndex: 2,
				preload: Infinity,
				visible: active === 'rgb_high',
				opacity: opacity / 100,
				source: new TileWMS({
					url: URL.WMS,
					tileGrid: createXYZ({ tileSize: 512 }),
					tileLoadFunction: tileLoadFunction,
					params: { 'LAYERS': `${id}:${id}_vhr_rgb${isPlanet ? '_planet' : ''}`, 'TIME': year, 'TILED': true,}
				})
			})
		);
	}


	return layers;
}

export const overlays = () => {
	let divA = document.createElement('div');
	divA.innerHTML = ReactDOMServer.renderToString(<Room fontSize='large' color="error" style={{ fill: 'red' }} />);
	divA.style.width = '40px';
	let divB = document.createElement('div');
	divB.innerHTML = ReactDOMServer.renderToString(<Room fontSize='large' color="warning" />);

	return [
		new Overlay({
			id: 'A',
			element: divA,
			positioning: 'bottom-center',
			stopEvent: false,
			offset: [0, 5],
			className: 'co-marker-a',

		}),
		new Overlay({
			id: 'B',
			element: divB,
			positioning: 'bottom-center',
			stopEvent: false,
			offset: [0, 5]
		}),
	]
}
