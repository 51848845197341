import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { Chip } from "@mui/material";

function UserLabel(props) {
  const {user} = useContext(AppContext);

  if(!user) return null;

  if(user.first_name && user.last_name) {
    return <Chip label={`${user.first_name} ${user.last_name}`} size="small" color="secondary" variant="filled" sx={{cursor: 'pointer', position: 'absolute', color: '#fff', fontWeight: 'bold', right: '10px', top: '65px', transform: 'translateY(-50%)'}} />
  }

  return <Chip label={user.email} color="secondary" variant="filled" sx={{cursor: 'pointer', position: 'absolute', color: '#fff', fontWeight: 'bold', right: '10px', top: '65px', transform: 'translateY(-50%)'}} />; 
}

export default UserLabel;