import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import WidgetDescription from "../widgets/WidgetDescription";

function DeleteParcelModal({open, onClose, parcelId, projectId, onDeleteParcel}) {
	const {user} = useContext(AppContext);
	const {page, onGetProjects, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})
  console.log(parcelId)

	const onSubmit = async () => {
		setLoading(true);

		try {
			await $data.deleteParcel(parcelId);
			
			let parcels = await $data.getParcels(projectId);

			onDeleteParcel(parcels);
      onShowSuccess('Parcel successfully deleted');
			onClose();

		} catch(e) {
      console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={open} onClose={onClose}>
			<DialogTitle>Delete parcel</DialogTitle>
			<DialogContent>
				<p className="co-text-primary text-bold">Are you sure that you want to delete selected parcel?</p>

				{user.user_type === 1 && <><WidgetDescription text="Parcel will be deleted in CropLab also" style={{marginBottom: '15px', fontWeight: 'bold', color: variables.dangerColor}} /> <br/><br/></>}

			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} style={{lineHeight: 'unset'}}>Cancel</Button>
				<Button onClick={onSubmit} style={{lineHeight: 'unset'}} variant="contained" color="error" startIcon={<Delete />}>DELETE</Button>
			</DialogActions>
			{loading && <LinearProgress color="primary" />}

		</Dialog>
	)
}

export default DeleteParcelModal;