import { AccountCircle, PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Card, CardActions, CardContent, CardMedia, LinearProgress, Slide, Snackbar, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import $auth from "../../services/$auth";
import $cookies from "../../services/$cookies";
import { variables } from "../../styles/abstract/variables";
import TextInput from "../ui/TextInput";
import ApplicabilityTemplate from "../widgets/applicability_template";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";
import AuthWrapper from "./AuthWrapper";

function Login(props) {
  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, setState] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState({});



  useEffect(() => {
    if($cookies.get('verification') == 'true') {
      setSuccess('Account verification successful!');
    }

    if($cookies.get('verification') == 'false') {
      setErrorMessage('Account verification failed! Please contact our technical support!');
    }

    if($cookies.get('forgot') == 'true') {
      setSuccess('Password change successful!');
    }

    if($cookies.get('forgot') == 'false') {
      setErrorMessage('Password change fsailed! Please contact our technical support!');
    }

    if($cookies.get('session') == 'true') {
      setErrorMessage('Session has expired!');
    }


    $cookies.remove('verification');
    $cookies.remove('forgot');
    $cookies.remove('session');

  }, []);

  const onChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSubmit = async () => {
    setLoading(true);
    
    try {
      let data = await $auth.login(state.email, state.password);
      props.onUpdateState({loggedIn: true, user: data.user});
    } catch(e) {
      setError(e);
      if(e.non_field_errors) {
        setErrorMessage(e.non_field_errors);
      }
    } finally {
      setLoading(false);
    }
  }

  // return <div style={{maxWidth: '22cm', margin: '0 auto'}}><ApplicabilityTemplate /></div>;

  return (
    <AuthWrapper>
      <form   ref={wrapperRef} autoComplete="off" onSubmit={(evt) => {evt.preventDefault(); onSubmit();}}>
      <input autoComplete="false" hidden />
        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent'}}>
          <CardMedia style={{ padding: '10px 30px', }} className="co-background-primary">
            <Stack justifyContent="center" alignItems="center" direction="column" style={{ minHeight: '180px', borderRadius: '0' }}>
              <WidgetTitle text={"Sign In"} style={{color: '#fff'}} />
              <WidgetDescription  style={{color: '#fff'}}  text={"Manage carbon credits projects, inspect applicability of farms and monitor agricultural activities in near real-time"} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
              <label style={{ fontSize: '14px', opacity: 0.7, color: '#fff' }}>Don't have account? </label>
              <Button style={{lineHeight: 'unset'}} size="small" component={Link} to="/register" startIcon={<PersonAdd color="secondary" />} className="co-text-secondary">Sign Up</Button>
            </Stack>
          </CardMedia>
          <CardContent className="co-background">
          
            <TextInput autoComplete="off" error={Boolean(error.non_field_errors || error.email)} helperText={error.email} label="Email" type="text" value={state.email} onChange={(evt) => onChange('email', evt.target.value)} />
            <TextInput autoComplete="new-password" error={Boolean(error.non_field_errors || error.password)} helperText={error.password} label="Password" type="password" value={state.password} onChange={(evt) => onChange('password', evt.target.value)}  />
            <Stack alignItems={'flex-end'}>
              <Link to="/forgot-password" style={{ fontSize: '14px' }} className="co-text">Did you forget password?</Link>
            </Stack>
          </CardContent>
          <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
            <LoadingButton disabled={loading} type="submit" variant="contained" className="co-text">{'Sign In'}</LoadingButton>
           
          </CardActions>
          {loading && <LinearProgress variant="indeterminate" color="primary"/>}
        </Card>
      </form>

      {/* ERROR */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(errorMessage)} onClose={(evt, reason) => setErrorMessage(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
      </Snackbar>

      {/* SUCCESS */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(success)} autoHideDuration={6000} onClose={(evt, reason) => setSuccess(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
      </Snackbar>
    </AuthWrapper>
  )
}

export default Login;