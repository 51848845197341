import moment from "moment";

class Project {
	constructor(model = {}) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.country = model.country;
    this.isAgroforestry = model.af_status;
    this.parcels = model.parcels;
    this.date = model.timestamp;
    this.farms = model.farms;
    this.farmer = model.farmers[0] || null 
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name || 'Uknown';
  }

  getDescription() {
    return this.description || 'No description'
  }

  getCountry() {
    return this.country[0];
  }

  isAgroforestry() {
    return this.isAgroforestry;
  }

  getNumberOfParcels() {
    let parcels = this.parcels || [];
    return parcels.length;
  }
  
  getDate(){
    return moment(this.date).format('DD/MMMM/YYYY')
  }

  getFarms() {
    return this.farms;
  }
}

export default Project;