import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import $auth from "../../services/$auth";
import $cookies from "../../services/$cookies";
import WidgetDescription from "../widgets/WidgetDescription";
import AuthWrapper from "./AuthWrapper";

function EmailVerification(props) {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      $auth.verification(props.id)
        .then(data => onHandleVerification(true))
        .catch(err => onHandleVerification(false))
    }, 3000)
  }, []);

  const onHandleVerification = (bool) => {
    $cookies.set('verification', bool.toString());
    setRedirect(true)
  }

  if(redirect) {
    return <Redirect to="/login" />
  }

  return (
    <AuthWrapper>
      <Stack alignItems={'center'} justifyContent={'center'} spacing={5} minHeight={200}>
        <CircularProgress color="secondary" />
        <WidgetDescription text="VERIFICATION IN PROGRESS" />
      </Stack>
    </AuthWrapper>
  )
}

export default EmailVerification;