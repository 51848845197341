import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { useContext, useState } from "react";
import $data from "../../services/$data";
import { DataContext } from "../DataContext";

function DeleteProject({open, onClose, project}) {
	const {page, onGetProjects, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	const onSubmit = async () => {
		setLoading(true);

		try {
			await $data.deleteProject(project.getId());
			onShowSuccess(`${project.name} - project deleted`);
			await onGetProjects(page);
			onClose();

		} catch(e) {
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

	if(!project) return;
	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={open} onClose={onClose}>
			<DialogTitle>Delete project - {project.getName()}</DialogTitle>
			<DialogContent>
				<p className="co-text-primary text-bold">Are you sure that you want to delete project - {project.getName()}?</p>
				<p className="co-danger">WARNING: All the data associated with the project will be lost</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} style={{lineHeight: 'unset'}}>Cancel</Button>
				<Button onClick={onSubmit} style={{lineHeight: 'unset'}} variant="contained" color="error" startIcon={<Delete />}>DELETE</Button>
			</DialogActions>
			{loading && <LinearProgress color="primary" />}

		</Dialog>
	)
}

export default DeleteProject;