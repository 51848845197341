import { Grid, TextField, Typography } from "@mui/material";
import SelectInput from "../ui/SelectInput";

function SocForm(props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <SelectInput name={"SOC Unit"} items={[{ value: 'Mg/ha', label: 'Mg/ha' }, { value: '%', label: '%' }]} value={props.model.soc_unit} onChange={(evt) => props.onUpdateModel({ soc_unit: evt.target.value })} ></SelectInput>
      </Grid>


      <Grid item xs={12}>
        <Typography variant="body2" color="error">This form is optional, but in order to be taken into account during calculation, all fields must be filled out.</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc: evt.target.value })} value={props.model.soc} type="number" variant="outlined" size="small" label={`Soil organic carbon ${props.model.soc_unit === 'Mg/ha' ? 'stock in t/ha' : 'content in %'} for specified year`}></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc_year: evt.target.value })} value={props.model.soc_year} type="number" variant="outlined" size="small" label={`Year of SOC ${props.model.soc_unit === 'Mg/ha' ? 'stock' : 'content'} measurement`}></TextField>
      </Grid>
      {props.model.soc_unit === '%' && <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ bulk_dens: isNaN(parseFloat(evt.target.value)) ? null : parseFloat(evt.target.value) })} value={props.model.bulk_dens} type="number" variant="outlined" size="small" label="Bulk density in g/cm3"></TextField>
      </Grid>}
    </Grid>
  )
}

export default SocForm;