import React from 'react';

export const DataContext = React.createContext();

const DataContextProvider = (props) => {
  return (
    <DataContext.Provider value={props.value}>
      {props.children}
    </DataContext.Provider>
  )
}

export default DataContextProvider;