import { AccountCircle, ArrowBack, PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Card, CardActions, CardContent, CardMedia, Checkbox, LinearProgress, Slide, Snackbar, Stack } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import $auth from "../../services/$auth";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";
import AuthWrapper from "./AuthWrapper";

const countries = [
  { value: 1, label: 'United Kingdom' },
  { value: 2, label: 'Greece' },
  { value: 3, label: 'Poland' },
  { value: 4, label: 'Serbia' },
  { value: 5, label: 'Portugal' },
  {value: 6, label: 'Ireland'}
];

function Register(props) {
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    user_type: '3',
    language: 'en',
    email: '',
    password1: '',
    password2: '',
    country: 1,
    privacy: false,
  });

  const [error, setError] = useState({});

  const onChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      await $auth.register(state);
      setSuccess('Verification link has been sent to provided email address');
    } catch(e) {
      setError(e);
      if(e.non_field_errors) {
        setErrorMessage(e.non_field_errors);
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <AuthWrapper>
      <form autoComplete="off" onSubmit={(evt) => { evt.preventDefault(); onSubmit() }}>

        <Card elevation={5} style={{ borderRadius: 0, marginBottom: '100px', background: 'transparent' }}>
          <CardMedia style={{ padding: '10px 30px', }} className="co-background-primary">
            <Stack justifyContent="center" alignItems="center" direction="column" style={{ minHeight: '200px', borderRadius: '0' }}>
              <WidgetTitle text={"Sign Up"} style={{color: '#fff'}} />
              <WidgetDescription style={{color: '#fff'}} text={"Manage carbon credits projects, inspect applicability of farms and monitor agricultural activities in near real-time"} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Button style={{lineHeight: 'unset'}} size="small" component={'a'} href="https://app.croplab.info/register" startIcon={<AccountCircle color="secondary" />} className="co-text-secondary">For farmers</Button>
              <Button style={{lineHeight: 'unset'}} size="small" component={Link} to="/login" startIcon={<ArrowBack color="secondary" />} className="co-text-secondary">Back to Sign in</Button>
            </Stack>
          </CardMedia>
          <CardContent className="co-background">
            <TextInput autoComplete="off" error={Boolean(error.non_field_errors || error.email)} helperText={error.email} label="Email" type="text" value={state.email} onChange={(evt) => onChange('email', evt.target.value)} />
            <TextInput autoComplete="new-password" error={Boolean(error.non_field_errors || error.password1)} helperText={error.password1} label="Password" type="password" value={state.password1} onChange={(evt) => onChange('password1', evt.target.value)} />
            <TextInput autoComplete="new-password" error={Boolean(error.non_field_errors || error.password2)} helperText={error.password2} label="Confirm" type="password" value={state.password2} onChange={(evt) => onChange('password2', evt.target.value)} />
            <SelectInput name="Country" items={countries} value={state.country} onChange={(evt, data) => onChange('country', evt.target.value)} />
            <Stack direction="row" alignItems="center" spacing={0}>
              <Checkbox checked={state.privacy} onChange={(evt, checked) => onChange('privacy', checked)} />
              <p style={{fontSize: '12px'}}>I accept and understand the <a className="text-bold co-text">Terms & Conditions</a> and <a  className="text-bold co-text">Privacy Statement</a></p>
            </Stack>
          </CardContent>
          <CardActions className="co-background" style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }}>
            <LoadingButton disabled={loading} type="submit" variant="contained" className="co-text">Sign Up</LoadingButton>
          </CardActions>
          {loading && <LinearProgress variant="indeterminate" color="primary" />}
        </Card>
      </form>
      {/* ERROR */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(errorMessage)} onClose={(evt, reason) => setErrorMessage(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* SUCCESS */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(success)} autoHideDuration={6000} onClose={(evt, reason) => setSuccess(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </AuthWrapper>
  )
}

export default Register;