import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import LayerGroup from 'ol/layer/Group';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import TextStyle from 'ol/style/Text';
import IconStyle from 'ol/style/Icon';
import { variables } from "../styles/abstract/variables";
import { applicability, applicabilityDimensions, applicabilityTemporalMap, monitoring } from "../config/map";
import photo from '../images/photo.svg';
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Cluster from 'ol/source/Cluster'
import { fromLonLat } from "ol/proj";
import _ from "lodash";

class $map {
	constructor() {
		this.featureStyle = (feature) => new Style({
			fill: new Fill({color: 'transparent'}),
			stroke: new Stroke({color: '#3399CC', width: 3}),
			text: new TextStyle({
				text: feature.get('name'),
				font: '14px sans-serif',
				fill: new Fill({
					color: 'rgb(0, 153, 255)'
				}),
				stroke: new Stroke({
					color: '#fff',
					width: 3
				})
			})
		});

		this.selectedFeatureStyle = (feature) => new Style({
			zIndex: 5,
			fill: new Fill({color: 'transparent'}),
			stroke: new Stroke({color: variables.secondary, width: 5}),
			text: new TextStyle({
				text: feature.get('name'),
				font: '14px sans-serif',
				fill: new Fill({
					color: variables.secondary
				}),
				stroke: new Stroke({
					color: variables.primary,
					width: 3
				})
			})
		})

		this.drawFeatureStyle = new Style({
			zIndex: 6,
			fill: new Fill({color: variables.secondaryOpacity(0.4)}),
			stroke: new Stroke({color: variables.secondary, width: 5}),
			image: new CircleStyle({
				radius: 9,
				stroke: new Stroke({
					width: 4,
					color: variables.primary,
				}),
				fill: new Fill({
					color: variables.secondary
				})
			})
		})

		this.hoverFeatureStyle = (feature) => new Style({
			zIndex: 4,
			fill: new Fill({color: 'transparent'}),
			stroke: new Stroke({color: '#3399CC', width: 5}),
			text: new TextStyle({
				text: feature.get('name'),
				font: '15px sans-serif',
				fill: new Fill({
					color: 'rgb(0, 153, 255)'
				}),
				stroke: new Stroke({
					color: '#fff',
					width: 3
				})
			})
		})

		this.photoStyle = (feature) =>  {
      if(feature.get('features').length > 1) {
        return new Style({
          fill: new Fill({color: 'red'}),
          stroke: new Stroke({color: '#fff', width: 2}),
          image: new CircleStyle({
            radius: 11,
            fill: new Fill({color: "#1c8adb"}),
            stroke: new Stroke({width: 1, color: '#fff'})
          }),
          text: new TextStyle({
            font: '12px sans-serif',
            text: feature.get('features').length.toString(),
            fill: new Fill({color: '#fff'}),
            stroke: new Stroke({width: 1, color: '#fff'})
           
          })
        })
      }
      
      return new Style({
        fill: new Fill({color: 'red'}),
        stroke: new Stroke({color: '#fff', width: 2}),
        // image: new CircleStyle({
        //   radius: 5,
        //   fill: new Fill({color: "red"}),
        //   stroke: new Stroke({width: 2, color: '#fff'})
        // })
        image: new IconStyle({
          src: photo,
          scale: 0.6
         
        })
      })
    };

		this.sampleStyle = (feature) =>  {
      if(feature.get('features').length > 1) {
        return new Style({
          fill: new Fill({color: 'red'}),
          stroke: new Stroke({color: '#fff', width: 2}),
          image: new CircleStyle({
            radius: 11,
            fill: new Fill({color: "#FFAB40"}),
            stroke: new Stroke({width: 1, color: '#fff'})
          }),
          text: new TextStyle({
            font: '12px sans-serif',
            text: feature.get('features').length.toString(),
            fill: new Fill({color: '#fff'}),
            stroke: new Stroke({width: 1, color: '#fff'})
           
          })
        })
      }
      
      return new Style({
        fill: new Fill({color: 'red'}),
        stroke: new Stroke({color: '#fff', width: 2}),
        // image: new CircleStyle({
        //   radius: 5,
        //   fill: new Fill({color: "red"}),
        //   stroke: new Stroke({width: 2, color: '#fff'})
        // })
        image: new CircleStyle({
					radius: 11,
					fill: new Fill({color: "#FFAB40"}),
					stroke: new Stroke({width: 1, color: '#fff'})
				}),
      })
    };
	}


	setBase(base, list) {
		list.map(b => {
			if(b.get('name') == base) {
				b.setVisible(true);
			} else {
				b.setVisible(false)
			}
		});
	}

	setApplicabilityLayer(layer, group) {
		group.getLayers().getArray().map(l => {
			if(l.get('name') == layer) {
				l.setVisible(true);
				let time = applicabilityTemporalMap[layer][applicabilityTemporalMap[layer].length - 1];
				l.getSource().updateParams({
					[applicabilityDimensions[layer]]: applicabilityDimensions[layer] == 'DIM_YEAR' ? time : l.get('baseName').replace('[time]', time)
				})
			} else {
				l.setVisible(false);
			}
		});
	}

	setMonitoringLayer(layer, group) {
		group.getLayers().getArray().map(l => {
			if(l.get('name') == layer) {
				l.setVisible(true);
			} else {
				l.setVisible(false);
			}
		});
	}

	updateApplicabilityParams(layer, time, group) {
		group.getLayers().getArray().map(l => {
			if(l.get('name') == layer) {
				l.getSource().updateParams({
					[applicabilityDimensions[layer]]: applicabilityDimensions[layer] == 'DIM_YEAR' ? time : l.get('baseName').replace('[time]', time)
				})
			}
			
		});
	}

	updateMonitoringParams(time, group) {
		console.log(time)
		group.getLayers().getArray().map(l => {
			if(["ndvi_high", 'tree_class', 'rgb_high'].indexOf(l.get('name')) === -1)
			l.getSource().updateParams({
				'TIME': time 
			});
		});
	}

	updateAgroforestryParams(time, group) {
		console.log(time)
		group.getLayers().getArray().map(l => {
			if(["ndvi_high", 'tree_class', 'rgb_high'].indexOf(l.get('name')) > -1)
			l.getSource().updateParams({
				'TIME': time 
			});
		});
	}



	setOpacity(opacity, group1, group2) {
		group1.getLayers().getArray().map(l => {
			l.setOpacity(opacity/100);
		});

		group2.getLayers().getArray().map(l => {
			l.setOpacity(opacity/100);
		});
	}

	createParcelLayer(parcels) {
		return new VectorImageLayer({
			name: 'parcels',
			zIndex: 3,
			style: this.featureStyle,
			source: new VectorSource({
				features: parcels.map(p => p.getFeature().clone())
			})
		})
	}

	createPhotosLayer(photos, parcels) {
		return new VectorImageLayer({
			zIndex: 5,
			name: 'photos',
			style: this.photoStyle,
			source: new Cluster({source:  new VectorSource({
				features: photos.map(p => {
					if(p.parcel && (!p.longitude || !p.latitude)) {

						// let f = new Feature();
						let parcel = parcels.filter(parc => parc.getId() === p.parcel)[0];

						if(parcel) {
							let geom = parcel.getFeature().getGeometry().getInteriorPoint();
							let f = new Feature(geom);
							f.setProperties({...p});
							return f
						} else {
							return null;
						}

					}

					if((!p.parcel && p.longitude && p.latitude) || (p.parcel && p.longitude && p.latitude)) {
						let f = new Feature(new Point(fromLonLat([p.longitude, p.latitude])));
						f.setProperties({...p});
						return f;
					}
					

					return null
				
				}).filter(p => p !== undefined && p !== null)
			}),
			})
		})
	}

	createSamplesLayer(samples, parcels) {
		return new VectorImageLayer({
			zIndex: 5,
			name: 'samples',
			style: this.sampleStyle,
			source: new Cluster({source:  new VectorSource({
				features: samples.map(p => {
					if(p.parcel__name && (!p.longitude || !p.latitude)) {

						// let f = new Feature();
						let parcel = parcels.filter(parc => parc.getName() === p.parcel__name)[0];

						if(parcel) {
							let geom = parcel.getFeature().getGeometry().getInteriorPoint();
							let f = new Feature(geom);
							f.setProperties({...p});
							return f
						} else {
							return null;
						}

					}

					if((!p.parcel && p.longitude && p.latitude) || (p.parcel && p.longitude && p.latitude)) {
						let f = new Feature(new Point(fromLonLat([p.longitude, p.latitude])));
						f.setProperties({...p});
						return f;
					}
					

					return null
				
				}).filter(p => p !== undefined && p !== null)
			}),
			})
		})
	}

	createDrawLayer() {
		return new VectorImageLayer({
			zIndex: 4,
			style: this.drawFeatureStyle,
			source: new VectorSource()
		})
	}

	createApplicabilityLayers(view, active, time, opacity) {
		return new LayerGroup({
			name: view,
			visible: view == 'applicability',
			zIndex: 2,
			layers: applicability(null, active, time, opacity),
		});
	}

	createMonitoringLayers(view, active, date, projectId, opacity, user, isAgroforestry, year) {

		return new LayerGroup({
			name: view,
			visible: view == 'monitoring',
			zIndex: 2,
			layers: monitoring(user?.user_type !== 1 ? `p${projectId}`: `${user.pk}`, active, date, opacity, false, isAgroforestry, year),
		});
	}

	navigateToParcel(parcel, mapInstance) {
		mapInstance.getView().fit(parcel.getFeature().getGeometry(), {duration: 500, padding: [20, 20, 300, 300]});
	}

	createProjectParcels(projectData = {}) {
		let parcels = [];

		Object.values(projectData).map(obj => {
			parcels = _.concat(parcels, obj.parcels);
		})

		console.log(projectData);

		return new VectorImageLayer({
			zIndex: 3,
			source: new VectorSource({
				features: parcels.map(p => p.getFeature())
			})
		})
	}
}

export default new $map();