import { Add } from "@mui/icons-material";
import { Button, Divider, Grid, Pagination, Stack, Slide, Snackbar, Alert } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import BackgroundWidget from "../widgets/BackgroundWidget";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";
import CreateProject from "./CreateProject";
import EditProject from "./EditProject";
import DeleteProject from "./DeleteProject";
import ProjectBox from "./ProjectBox";
import ReportsModal from "./ReportsModal";

function Projects(props) {
	const {projects, page, onSetPage} = useContext(DataContext);

	const [create, setCreate] = useState(false);
	const [edit, setEdit] = useState(false);
	const [remove, setRemove] = useState(false);

	const [reportsModal, setReportsModal] = useState(null);

	const renderProjects = () => {

		console.log(projects)
		return projects.results.map((project, key) => {
			return <Grid key={key} item xs={12} sm={6} md={4}>
				<ProjectBox project={project} edit={edit} onToggleReports={setReportsModal} onToggleEdit={setEdit} onToggleRemove={setRemove} remove={remove} />
			</Grid>
		})
	}

	const renderContent = () => {
		if(projects.count === 0) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<WidgetTitle text="My Projects" />
					</Grid>
					<Grid item xs={12}>
						<Divider color="secondary" light style={{background: variables.secondary, height: '3px'}}  />
					</Grid>
					<Grid item xs={12} textAlign={'center'}>
						<WidgetDescription text="There are no projects created"/>
					</Grid>
					<Grid item xs={12} textAlign={'center'}>
						<Button color="info" onClick={() => setCreate(true)} sx={{lineHeight: 'unset', color: '#fff'}} startIcon={<Add />} variant="contained">CREATE NEW PROJECT</Button>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Stack className="only-laptop" direction="row" alignItems="center" justifyContent="space-between">
						<WidgetTitle text="My Projects" />
						<Button color="info" onClick={() => setCreate(true)} sx={{lineHeight: 'unset', color: '#fff'}} startIcon={<Add />} variant="contained">CREATE NEW PROJECT</Button>
					</Stack>

					<Stack className="only-mobile" direction="column">
						<WidgetTitle text="My Projects" />
						<Button color="info" onClick={() => setCreate(true)} sx={{lineHeight: 'unset', color: '#fff'}} startIcon={<Add />} variant="contained">CREATE NEW PROJECT</Button>
					</Stack>
				</Grid>
				
				<Grid item xs={12}>
					<Divider color="primary" light style={{background: variables.primary, height: '3px'}}  />
				</Grid>
				{projects.count >= 10 && <Grid item xs={12}>
					<Pagination count={Math.floor(projects.count/10)} page={page + 1} siblingCount={0} color="secondary" sx={{'& li>button, & li>div': {color: variables.secondary}, '& li>button[aria-current=true]': {color: variables.primary, fontWeight: 'bold'}}} ></Pagination>
				</Grid>}
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{renderProjects()}
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return (
		<>
			<Container style={{marginTop: '30px', marginBottom: '30px'}}>
				{renderContent()}
			</Container>

			<CreateProject open={create} onClose={() => setCreate(false)} />
			<EditProject open={Boolean(edit)} project={edit} onClose={() => setEdit(false)} />
			<DeleteProject open={Boolean(remove)} project={remove} onClose={() => setRemove(false)} />

			 <ReportsModal open={Boolean(reportsModal)} model={reportsModal}  onClose={() => setReportsModal(null)} />
		</>
	)
}

export default Projects;