import { Paper } from "@mui/material";
import { Stack } from "@mui/system";
import logo from '../../images/gilab.svg';
import {variables} from '../../styles/abstract/variables.js';


function AuthFooter(props) {
  
  return (
    <Stack component={Paper} elevation={3} direction="row" alignItems="center" className="co-text text-bold" style={{borderRadius: 0,position: 'fixed', width: '100%', bottom: 0, zIndex: 2, padding: '10px', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)', color: variables.primary}}>
      Powered by <a href="https://gilab.rs" target="_blank"><img width={80} src={logo} /></a>
    </Stack>
  );
}

export default AuthFooter;