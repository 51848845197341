import { Stack } from "@mui/system";
import WidgetTitle from "../widgets/WidgetTitle";

function AuthHeader(props) {
  return (
    <Stack style={{padding: '15px', width: '100%'}} direction="row" justifyContent="space-between">
      {/* <WidgetTitle text={<span>CO<sub>2</sub>Agri</span>} /> */}
      <img src="/logo_co2.svg" height="80" />
    </Stack>
  );
}

export default AuthHeader;