import axios from 'axios';
import URL from '../config/urls';
import moment from 'moment';
import Project from '../models/Project';
import Parcel from '../models/Parcel';

const tags = [
  'No-till',
  'Reduced or minimum tillage',
  'Cover crops',
  'Crop rotation',
  'Mulching',
  'Agroforestry and silvopasture',
  'Hedgerows, flower strips, shrubs',
  'Composting',
  'Biochar',
  'Holistically managed grazing',
  'Animal integration into crop production',
  'Perennial cropping',
];

class $data {

  createFarm(model) {
    return axios.post(URL.FARMS, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  updateFarm(model, id) {
    return axios.patch(URL.FARMS + `${id}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  deleteFarm(id) {
    return axios.delete(URL.FARMS + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getProjects(page) {
    return axios.get(URL.PROJECTS + '?limit=10')
      .then(response => {
        return {
          ...response.data,
          results: response.data.results.map(project => new Project(project))
        }
      })
      .catch(err => {
        throw err.message;
      })
  }

  createProject(model) {
    return axios.post(URL.PROJECTS, model)
      .then(response => {
        return new Project(response.data);
      })
      .catch(err => {
        throw err.message;
      })
  }

  updateProject(model) {
    console.log(model)
    return axios.put(URL.PROJECTS + `${model.id}/`, model)
      .then(response => {
        return new Project(response.data);
      })
      .catch(err => {
        throw err.message;
      })
  }


  deleteProject(id) {
    return axios.delete(URL.PROJECTS + `${id}/`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err.message;
      })
  }

  getFarms(projectId) {
    return axios.get(URL.FARMS + '?limit=999999&project_id=' + projectId)
      .then(result => result.data.results)
      .catch(err => { throw err.message })
  }

  getParcels(projectId, isFarmer) {
    if(isFarmer) {
      return axios.get(URL.PARCELS_FARMER)
        .then(response => {
          if (!response.data?.features) {
            return []
          }
          return response.data.features.map(f => new Parcel({ ...f.properties }, f));
        })
        .catch(err => {
          throw err.message;
        })
    }
    return axios.get(URL.PARCELS.replace('__ID__', projectId))
      .then(response => {
        if (!response.data?.features) {
          return []
        }
        return response.data.features.map(f => new Parcel({ ...f.properties }, f));
      })
      .catch(err => {
        throw err.message;
      })
  }

  getPhotos(projectId) {
    let filter = projectId ? `?project_id=${projectId}` : '';

    return axios.get(URL.PHOTOS + filter)
      .then(result => result.data)
      .catch(err => {throw err.message})
  }

  getSamples(projectId) {
    let filter = projectId ? `?project_id=${projectId}` : '';
    console.log(projectId)
    return axios.get(URL.SAMPLES + filter)
      .then(result => this.formatSamples(result.data, Boolean(projectId)))
      .catch(err => {throw err.message})
  }

  formatSamples(data, isPD) {
    console.log(isPD)
    try {
      
      if(!data) return [];
      console.log('NIJE NO DATA');
      //USERS
      if(Object.keys(data).length === 0) return [];
      console.log('NIJE PRAZNO');

      let collection = Object.values(data);
   

      if(!collection) return [];

      let formated = [...collection];
      if(isPD) {
        formated = [];
        collection.map(arr => {
          arr.map(obj => {
            Object.values(obj).map(o => {
              o.map(item => {
                formated.push(item);
              })
            }) 
          })
        });

        console.log(formated);
        return formated

      } else {
        console.log('SAMPLES')
        console.log(collection)
        let samples = [];
        collection.map(coll => {
          Object.values(coll).map(c => {
            c.map(obj => {
              samples.push(obj)
            })
          })
        })
        console.log(samples);
        return samples
      }

      


    } catch (error) {
      console.log(error)
      return []
    }

  }

  importParcels(files) {
    return axios.post(URL.UPLOAD_PARCELS, files)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createParcel(model) {
    return axios.post(URL.PARCEL, model)
      .then(result => new Parcel(result.data.properties, result.data))
      .catch(err => {
        console.log(err);
        throw err.message;
      })
  }

  editParcel(model, id) {
    console.log(model)
    return axios.patch(URL.PARCEL + `${id}/`, model)
      .then(result => new Parcel(result.data.properties, result.data))
      .catch(err => {
        console.log(err);
        throw err.message;
      })
  }

  deleteParcel(id) {
    return axios.delete(URL.PARCEL + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getDatesS2(projectId, isFarmer, farmerId) {
    if(isFarmer) {
      return axios.get(URL.DATES_S2_FARMER.replace('__ID__', farmerId))
        .then(response => {
          return response.data;
        })
        .catch(err => {
          throw err.message;
        })
    }

    return axios.get(URL.DATES_S2.replace('__ID__', projectId))
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err.message;
      })
  }

  getVerification(parcelId) {
    return axios.get(URL.VERIFICATION + `${parcelId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err.message;
      })
  }

  getDeclaredReport(model) {
    return axios.post(URL.DECLARED_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getDetectionReport(model) {
    return axios.post(URL.DETECTION_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getApplicabilityReport(model) {
    return axios.post(URL.APPLICABILITY_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  downloadPDF(htmlString) {
    console.log(htmlString)
    return axios.post(URL.GENERATE_PDF, { html_string: htmlString }, { responseType: 'blob' })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  requestFarmer(model) {
    return axios.post(URL.REQUEST_FARMERS, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getFarmers() {
    return axios.get(URL.REQUEST_FARMERS + '?limit=9999999')
      .then(result => result.data.results)
      .catch(err => { throw err.message })
  }

  getParcelsForFarmer(id) {
    return axios.get(URL.FARMER_PARCELS.replace('__ID__', id))
      .then(response => {
        if (!response.data?.features) {
          return []
        }
        return response.data.features.map(f => new Parcel({ ...f.properties }, f));
      })
      .catch(err => { throw err.message })
  }

  addParcelsForFarmer(project, model) {
    return axios.post(URL.ADD_FARMRE_PARCELS.replace('__ID__', project), model)
      .then(result => result.data)  
      .catch(err => {throw err})
  }

  disconnectFarmer(id) {
    return axios.delete(URL.REQUEST_FARMERS + `${id}/`)
      .then(result => result.data)  
      .catch(err => {throw err})
  }


  async pointQuery(lon, lat) {
    try {
      let tcdAndWW = await axios.post(URL.APPLICABILITY, {Lon: lon, Lat: lat});
      let corine = await axios.get(`https://api.opendatascience.eu/point-query/?property=lcv_landcover.clc_corine_c_100m_0..0cm_*_eumap_epsg3035_v2020.tif&lon=${lon}&lat=${lat}`)
      let corineObj = {};

      corine.data.map(obj => {
        corineObj[obj.year] = obj.label;
      }) 

      return {
        coordinates: `${lat.toFixed(4)}, ${lon.toFixed(4)}`,
        tcd: tcdAndWW.data.tcd[0],
        wetlands: tcdAndWW.data.ww[0],
        corine: corineObj,
        plough: tcdAndWW.data.plough[0],
        worldcover: tcdAndWW.data.world_cover[0],
        lc_uk: tcdAndWW.data.lc_uk[0]
      }
    
    } catch (error) {
      throw error.message;
    }
  }

  async monitoringQuery(lon, lat, parcel, project, user) {
    try {
      if(user?.user_type !== 1) {
        let result = await axios.get(URL.POINT.replace('__PROJECT__', project) + `${parcel}?lon=${lon}&lat=${lat}&source=s2&var=ndvi,ndvihres`);
        return {
          'NDVI': typeof result.data.NDVI === 'object' ? result.data.NDVI : null,
          'CHL': null,
          'BURN': null,
          'NDVI BACKGROUND': typeof result.data.NDVIHRES === 'object' ? result.data.NDVIHRES : null
        }
      } else {
        let result = await axios.post(URL.POINT_FARMER.replace('__ID__', parcel), {lat, lon, layer: ['ndvi', 'ndvihres'], source: 's2'});

        let ndvibg = null;

        try {
          ndvibg = Object.keys(result.data.ndvihres[0]).length > 0 ? result.data.ndvihres[0] : null;
        } catch(e) {

        }

        return {
          'NDVI': result.data.ndvi[0] ? result.data.ndvi[0] : null,
          'CHL':  null,
          'BURN': null,
          'NDVI BACKGROUND': ndvibg
        }
      }

     
    } catch (error) {
      throw error.message;
    }
  }
}

export default new $data();