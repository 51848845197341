import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, ListItem, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";
import { Container } from "@mui/system";

function ManageParcels(props) {
  const { user } = useContext(AppContext);
  const { projectData, projects, onShowSuccess, onUpdateProjectData } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [parcels, setParcels] = useState([]);
  const [selected, setSelected] = useState([]);

  const [error, setError] = useState(null);

  const [state, setState] = useState({

    project: props.projectId,
    farm: undefined
  })

  const [status, setStatus] = useState({
    error: {},
    success: null,
  })

  const getParcels = async () => {
    setLoading(true);
    try {
      let parcels = await $data.getParcelsForFarmer(props.farmerId);
      setParcels(parcels);
      if (parcels.length > 0) {
        setState((state) => ({ ...state, farm: parcels[0].farm }))
      }
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }

  const getProjectByFarm = (id) => {
    console.log(id)
    let pid = null;
    projects.results.map(p => {
      let farm = p.farms.filter(obj => obj.id === id)[0];
      if (farm) {
        console.log("PROSLO")
        console.log(p.id)
        pid = p.id;
      }
    })
    console.log(pid)
    return pid;
  }

  useEffect(() => {
    getParcels();
  }, [])


  const onSubmit = async () => {
    setSubmitLoading(true);

    try {

      let fd = new FormData();
      fd.append('project_id', props.projectId);
      fd.append('parcels', selected.join(','));
      fd.append('id', state.farm)

      await $data.updateFarm(fd, state.farm);
      let parcels = await $data.getParcels(props.projectId);
      let farms = await $data.getFarms(props.projectId);

      onUpdateProjectData(props.projectId, { farms, parcels });
      props.onRefreshParcelsView(parcels);
      getParcels();
      onShowSuccess('Successfully imported parcels');
      props.onClose();
    } catch (e) {
      setStatus({ ...status, error: e });
    } finally {
      setSubmitLoading(false);
    }
  }

  const getFarmsPerProject = (id) => {
    if (!id) return [];


    let project = projects.results.filter(p => p.id.toString() === id.toString())[0];

    if (!project) return [];



    return project.getFarms().map(f => {
      return {
        value: f.id,
        label: f.name
      }
    })

  }

  const renderContent = () => {
    if (loading) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <CircularProgress />
      </Grid>
    )

    if (error) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="error" variant="body1">Something went wrong while trying to fetch available parcels</Typography>
      </Grid>
    )


    if (parcels.length === 0) {
      return <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="primary" variant="body1">There are no more available parcels from this farmer</Typography>
      </Grid>
    }

    return (
      <Grid item xs={12}>
        <ListItem sx={{ mb: '10px' }} key={-1} className="co-background" secondaryAction={<Checkbox value={'all'} checked={selected.length === parcels.length} onChange={(evt, checked) => setSelected((current) => (checked ? parcels.map(p => parseInt(p.getId())) : []))} />} dense>
          <ListItemText primary={'Select all'}>Select all</ListItemText>
        </ListItem>
        {parcels.map((parcel, key) => {
          return (
            <ListItem sx={{ mb: '10px' }} key={key} className="co-background" secondaryAction={<Checkbox value={parcel.getId()} checked={selected.indexOf(parcel.getId()) > -1} onChange={(evt, checked) => setSelected((current) => (checked ? [...current, parseInt(evt.target.value)] : current.filter(id => id != evt.target.value)))} />} dense>
              <ListItemText primary={parcel.getName()} secondary={parcel.getArea()}></ListItemText>
            </ListItem>
          )
        })}
      </Grid>
    )
  }


  return (
    <Dialog fullWidth maxWidth="md" PaperProps={{ className: 'co-background-white' }} open={props.open} onClose={props.onClose}>

      <DialogTitle sx={{ position: 'sticky', top: 0, zIndex: 10 }} color="primary">Manage parcels</DialogTitle>
      <form onSubmit={(evt) => { evt.preventDefault(); onSubmit() }}>
        <DialogContent>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1" color="primary">Select parcels that you wish to import to previously selected farm</Typography>
              </Grid>

              <Grid item xs={12}>
                <SelectInput items={getFarmsPerProject(props.projectId)} name="Farm to connect to" value={state.farm || undefined} onChange={(evt) => setState((state) => ({ ...state, farm: evt.target.value }))} />

                {/* <p>Farm: <strong className="co-text-primary">{renderFarm(state.project)}</strong></p> */}
              </Grid>
              {renderContent()}
            </Grid>
          </Container>

          {status.error.constructor == Array &&
            <>
              <Divider sx={{ background: variables.dangerColor }} />
              <p className="co-danger" style={{ fontSize: '13px', fontWeight: 'bold' }}>{status.error}</p>
            </>
          }

        </DialogContent>
        <DialogActions sx={{ position: 'sticky', bottom: 0 }} className="co-background">
          <Button disabled={submitLoading} style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>
          <Button disabled={submitLoading || !state.project || !state.farm || selected.length === 0} type="submit" style={{ lineHeight: 'unset' }} color="primary">Confirm</Button>

        </DialogActions>
      </form>
      {submitLoading && <LinearProgress color="primary" />}
    </Dialog>
  )
}

export default ManageParcels;