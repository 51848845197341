import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import BackgroundWidget from "./BackgroundWidget";
import WidgetDescription from "./WidgetDescription";

function LoadingData(props) {
  return <BackgroundWidget>
    <Stack spacing={3} alignItems="center" style={{position: 'absolute', top: '40%', left: '50%', transform: 'translateX(-50%)'}}>
      <CircularProgress  style={{color: '#fff', width: 60}} size="small" />
      <p style={{color: '#fff'}}>LOADING DATA</p>
    </Stack>
  </BackgroundWidget>;
}

export default LoadingData;