import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";
import WidgetDescription from "../widgets/WidgetDescription";

function EditParcelModal(props) {
	const {user} = useContext(AppContext);
	const {projectData, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	const model = projectData[props.projectId].parcels.filter(p => p.getId() === props.model)[0].getProperties()
	const [state, setState] = useState({
		...model,
    farm: model.farm ? model.farm : 'null'
    
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

  console.log(state)

	const onSubmit = async () => {
		setLoading(true);

		try {

			let payload = {
				pk: state.pk,
				name: state.name,
			}
			if(user.user_type !== 1) {
				payload.project_id = props.projectId;
				payload.farm_id = state.farm === 'null' ? null : state.farm;
			}
			await $data.editParcel(payload, state.pk)
			let parcels = await $data.getParcels(props.projectId, user.user_type === 1);
			
      props.onAddParcel(parcels);
			onShowSuccess('Parcel successfully updated');
			props.onClose();
		} catch(e) {
			console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

  const farms = projectData[props.projectId]?.farms?.map(f => ({value: f.id, label: f.name})) || [];

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Edit parcel</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
          {user.user_type !== 1 && <SelectInput name="Select farm" items={[...farms]} value={state.farm} onChange={(evt, data) => {setState((st) => ({...st, farm: evt.target.value}))}} />}
					{user.user_type === 1 && <><WidgetDescription text="Parcel will be changed in CropLab also" style={{marginBottom: '15px', fontWeight: 'bold', color: variables.dangerColor}} /> <br/><br/></>}

					<TextInput value={state.name} error={Boolean(status.error.name)} helperText={status.error.name || null} onChange={(evt) => setState({...state, name: evt.target.value})} label="Name" />
				{status.error.constructor == Array &&
					<>
						<Divider sx={{background: variables.dangerColor}} />
						<p className="co-danger" style={{fontSize: '13px', fontWeight: 'bold'}}>{status.error}</p>
					</>
				}
				
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{lineHeight: 'unset'}} color="primary">Confirm</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default EditParcelModal;