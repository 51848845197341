import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";

function DeleteRequest(props) {
	const {user} = useContext(AppContext);
	const {projectData, projects, onShowSuccess} = useContext(DataContext); 
  console.log(props.connection);
	const [loading, setLoading] = useState(false);
	

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})


	const onSubmit = async () => {
		setLoading(true);

		try {
      await $data.disconnectFarmer(props.connection.id)

      props.onRefresh();     
			onShowSuccess('Successfully disconnected from the farmer');
			props.onClose();
		} catch(e) {
      console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}
	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Remove connection</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="primary" variant="body1">Are you sure that you want to disconnect from the selected farmer?</Typography>
            </Grid>
          </Grid>
        
        </DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="primary" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{lineHeight: 'unset'}} color="error">Disconnect</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default DeleteRequest;