import { AttachFile, RequestPage, SendToMobile, TaskAlt, UploadFile, ViewModule } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import CroplabLogo from "../ui/CropLabLogo";
import SelectInput from "../ui/SelectInput";
import WidgetTitle from "../widgets/WidgetTitle";

const interactionStyle = {
  '&:hover': {
    transform: 'scale(1.05)'
  },
  '&:active': {
    transform: 'scale(1.05) translateY(2px)'
  }
}

function UploadParcelsModal(props) {
  const {user} = useContext(AppContext);
  const {projectData, onShowSuccess} = useContext(DataContext);

  const fileRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [farm, setFarm] = useState(projectData[props.projectId].farms ? projectData[props.projectId].farms[0]?.id : null);

  const [error, setError] = useState(null);

  const onChangeFile = (evt) => {
    let name = JSON.stringify(fileRef.current.files[0].name);
    try {
      if(!name.match('.zip') && !name.match('.geojson') && !name.match('.gpkg')) {
        setError('Unsupported file format. Please use .zip for ESRI ShapeFile, .geojson or .gpkg')
        setSelectedFile(null);
      } else {
        setSelectedFile(fileRef.current.files[0]);
   
        setError(null);
      }

    } catch(e) {
      console.log(e)
    }
  }

  const onSubmitFile = async () => {
    setLoading(true);

    try {
      let fd = new FormData();
      fd.append('file', selectedFile);

      if(user.user_type !== 1) {
        fd.append('project_id', props.projectId);
        if(farm !== 'null') {
          fd.append('farm_id', farm);
        }
      }
     
      
      await $data.importParcels(fd);
      let parcels = await $data.getParcels(props.projectId, user.user_type === 1);

      props.onImportParcels(parcels);
      onShowSuccess('Parcels imported successfully')
      props.onClose();

      

    } catch(e) {

      setError(e?.non_field_errors || JSON.stringify(e));
    } finally {
      setLoading(false);
    }

  }
  const farms = projectData[props.projectId]?.farms?.map(f => ({value: f.id, label: f.name})) || [];

  return (
    <Dialog PaperProps={{className: 'co-background-white'}} fullWidth maxWidth="sm" open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">Upload/Import parcels from file</DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <p style={{fontSize: '20px',color: variables.primary, fontWeight: 'bold' }}>Import from file (.shp (must be in ZIP archive), .geojson, .gpkg):</p>
              <Paper elevation={3} sx={{ p: '20px', background: 'transparent' }} className="co-background-overlay">
         
                <Button component={'label'} sx={{ lineHeight: 'unset' }} startIcon={<AttachFile />} color="warning" variant="contained">Select file
                <input ref={fileRef} type="file" onChange={onChangeFile} hidden />
                </Button>
                {error && <><br/><br/>
                <strong><Typography color="error">{error}</Typography></strong></>}

                {selectedFile && <Stack>
                  <br/>
                  <span>File: <strong className="co-text-primary">{selectedFile.name}</strong></span> 
                </Stack>}

                {selectedFile && user.user_type !== 1 && <Stack>
                  <br/>
                  <SelectInput name="Select farm" items={[...farms]} value={farm} onChange={(evt, data) => {setFarm(evt.target.value)}} />
                </Stack>}

                  {(!farm && selectedFile && user.user_type !== 1) && <Stack>
                    <Typography variant="body2" color="error">Farm is mandatory. Please create one, if you haven't already</Typography>
                  </Stack>}
                <br/>
                <br/>
                <Button onClick={onSubmitFile} disabled={!selectedFile || (!farm && user.user_type !== 1)} color="primary" variant="contained" sx={{lineHeight: 'unset', m: ''}} startIcon={<UploadFile/>}>Upload parcels</Button>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} textAlign="center" sx={{ mt: '20px', fontSize: '20px',color: variables.primary, fontWeight: 'bold' }}>- OR -</Grid>
            <Grid item xs={12}>
            <p style={{fontSize: '20px',color: variables.primary, fontWeight: 'bold' }}>Import from CropLab - request data access from farmer(s):</p>
              <Paper elevation={3} sx={{ p: '20px',  background: 'transparent' }} className="co-background-overlay">
                <div style={{textAlign: 'center'}}>
                  <CroplabLogo style={{width: '150px', height: '100px', margin: '0 auto'}} />

                </div>
                <div style={{textAlign: 'center'}}>
                  <p style={{textAlign: 'center'}}>There are no pending requests.</p>
                  <Button  color="primary" variant="contained" sx={{lineHeight: 'unset', m: ''}} startIcon={<SendToMobile/>}>Make a request</Button>
                </div>
              </Paper>

            </Grid> */}

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button disabled={loading} style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>

        </DialogActions>
      </form>
      {loading && <LinearProgress color="primary" />}
    </Dialog>
  )
}

export default UploadParcelsModal;