import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import TextInput from "../ui/TextInput";

function EditProject({project, onClose, open}) {

	console.log(project)
	const {page, onGetProjects, onShowSuccess} = useContext(DataContext); 

	const [loading, setLoading] = useState(false);
	
	const [state, setState] = useState({
		id: project ? project.getId() : 0,
		name: project ? project.getName() : '',
		af_status: project ? project.isAgroforestry : false,
		description: project ? project.getDescription() : '',
	})

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	useEffect(() => {
		if(project && open) {
			setState({id: project.getId(), name: project.getName(), af_status: project.isAgroforestry, description: project.getDescription()});
		}
	}, [open])

	const onSubmit = async () => {
		setLoading(true);

		try {
			let project = await $data.updateProject(state);
			onShowSuccess(`${project.name} - project updated`);
			await onGetProjects(page);
			onClose();

		} catch(e) {
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

	if(!project) return;
	console.log(state)
	return (
		<Dialog PaperProps={{ className: 'co-background-white' }} open={open} onClose={onClose}>
			
			<DialogTitle color="primary">Edit project - {project.getName()}</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>
				<Grid container spacing={2}>
						<Grid item xs={12} sm={8}>
							<TextInput value={state.name} onChange={(evt) => setState({ ...state, name: evt.target.value })} label="Name" />
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel control={<Checkbox checked={state.af_status} onChange={(evt, checked) => setState(current => ({...current, af_status: checked}))} />} label="Agroforestry"></FormControlLabel>
						</Grid>
						<Grid item xs={12}>
							<TextInput multiline value={state.description} onChange={(evt) => setState({ ...state, description: evt.target.value })} label="Description" />
						</Grid>
					</Grid>
					
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={onClose}>Cancel</Button>
					<Button disabled={loading} type="submit" style={{lineHeight: 'unset'}} color="primary">SAVE CHANGES</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
		</Dialog>
	)
}

export default EditProject;