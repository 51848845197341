import { Input, Satellite, Sensors, SensorWindow, SupervisedUserCircle, TaskAlt, VerifiedUser, ViewModule } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import ApplicabilityTemplate from "../ui/ApplicabilityTemplate";
import DeclaredTemplate from "../ui/DeclaredTemplate";
import DetectionTemplate from "../ui/DetectionTemplate";
import WidgetTitle from "../widgets/WidgetTitle";

const interactionStyle = {
  '&:hover': {
    transform: 'scale(1.05)'
  },
  '&:active': {
    transform: 'scale(1.05) translateY(2px)'
  }
}

function ReportsModal(props) {

  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(null);

  const [detectionReport, setDetectionReport] = useState(null);
  const [declaredReport, setDeclaredReport] = useState(null);
  const [applicabilityReport, setApplicabilityReport] = useState(null);


  const onSubmit = async () => {
    if(selected === 'detected') {
      setLoading(true);
      try {
        let data = await $data.getDetectionReport(props.model);
        setDetectionReport(data);
      } catch(e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
      return
    }

    if(selected === 'declared') {
      setLoading(true);
      try {
        let data = await $data.getDeclaredReport(props.model);
        setDeclaredReport(data); 
      } catch(e) {
        console.log(e)
      } finally {
        setLoading(false);  
      }
      return 
    }

    if(selected === 'applicability') {
      setLoading(true);
      try {
        let data = await $data.getApplicabilityReport(props.model);
        setApplicabilityReport(data);
      } catch(e) {
        console.log(e)
      } finally {
        setLoading(false);  
      }
      return 
    }
  }

  return (
    <Dialog PaperProps={{className: 'co-background-white'}} fullWidth maxWidth="xl"  open={props.open} onClose={props.onClose}>
			
			<DialogTitle color="primary">Project reports</DialogTitle>
			<form onSubmit={(evt) => {evt.preventDefault(); onSubmit()}}>
				<DialogContent>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <p className="co-text-primary text-bold" style={{fontSize: '18px'}}>Select type of report:</p>
            </Grid>
 
            <Grid item xs={12} sm={4}>
              <Paper onClick={() => setSelected('applicability')} elevation={3} sx={{background: 'transparent', border: selected == 'applicability' ? '4px solid ' + variables.primary : 'none', textAlign: 'center', p: '20px', cursor: 'pointer', ...interactionStyle}} className="co-text-primary co-background-overlay">
                <ViewModule fontSize="large" />
                <p>Applicability report</p>
              </Paper>

            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper onClick={() => setSelected('declared')} elevation={3} sx={{background: 'transparent', border: selected == 'declared' ? '4px solid ' + variables.primary : 'none', textAlign: 'center', p: '20px', cursor: 'pointer', ...interactionStyle}} className="co-text-primary co-background-overlay">
                <SupervisedUserCircle fontSize="large" />
                <p>Declared by farmer</p>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper onClick={() => setSelected('detected')} elevation={3} sx={{background: 'transparent', border: selected == 'detected' ? '4px solid ' + variables.primary : 'none', textAlign: 'center', p: '20px', cursor: 'pointer', ...interactionStyle}} className="co-text-primary co-background-overlay">
                <Sensors fontSize="large" />
                <p>Detected practices</p>
              </Paper>
            </Grid> 

          </Grid>
					
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} style={{lineHeight: 'unset'}} color="error" onClick={props.onClose}>Cancel</Button>
					<Button disabled={loading || !selected} type="submit" style={{lineHeight: 'unset'}} color="primary">Generate</Button>
					
				</DialogActions>
			</form>
			{loading && <LinearProgress color="primary" />}
      {detectionReport && <DetectionTemplate open={Boolean(detectionReport)} data={detectionReport} onClose={() => setDetectionReport(null)} />}
      {declaredReport && <DeclaredTemplate open={Boolean(declaredReport)} data={declaredReport} onClose={() => setDeclaredReport(null)} />}
      {applicabilityReport && <ApplicabilityTemplate open={Boolean(applicabilityReport)} data={applicabilityReport} onClose={() => setApplicabilityReport(null)} />}
		</Dialog>
  )
}

export default ReportsModal;