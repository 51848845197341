import { Analytics, ArrowBack, ChevronLeft, ChevronRight, Close, Dashboard, DateRange, Forest, Report, Search, SensorDoor, Sensors, SupervisedUserCircle, TaskAlt } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { Button, CircularProgress, Divider, Fab, FormControl, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Slider, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import { useContext, useState } from "react";
import { applicabilityTemporalMap, monitoringTemporalMap } from "../../config/map";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import WidgetDescription from "../widgets/WidgetDescription";
import SingleReport from "./SingleReport";
import { AppContext } from "../../AppContext";

const container = {
  width: '320px',
  overflowY: 'hidden',
  overflowX: 'hidden',
  paddingBottom: '25px',
  background: variables.widget,
}


function LayersWidget(props) {
  const {user} = useContext(AppContext);
  const {layerA, layerM, timeA, timeAg, opacity, projectData, onUpdateMapData } = useContext(DataContext);

  const [singleReport, setSingleReport] = useState(null);

  const nextDate = () => {
    let currentIndex = props.dates.indexOf(props.timeM);
    if(currentIndex < props.dates.length -1 && currentIndex !== -1 ) {
      props.onSetDatesState((st) => ({...st, timeM: props.dates[currentIndex + 1]}))
    }
  }

  const previousDate = () => {
    let currentIndex = props.dates.indexOf(props.timeM);
    if(currentIndex > 0 ) {
      props.onSetDatesState((st) => ({...st, timeM: props.dates[currentIndex - 1]}))
    }
  }

  const renderContent = () => {
    if (props.view === 'applicability') {
      return (
        <>
          <Stack spacing={2} sx={{ width: '100%' }}>

            <SelectInput onChange={(evt) => onUpdateMapData({ layerA: evt.target.value, timeA: applicabilityTemporalMap[evt.target.value][applicabilityTemporalMap[evt.target.value].length - 1] })} className="select-secondary" value={layerA} name="Select layer" items={[
              { value: 'tcd', label: 'Tree Cover Density' },
              { value: 'wetlands', label: 'Wetlands' },
              { value: 'corine', label: 'Corine Land Cover' },
              { value: 'worldcover', label: 'World Cover' },
              { value: 'lc_uk', label: 'Land Cover UK'},
              { value: 'plough', label: 'Ploughing < 2018' },
            ]} />



          </Stack>
          <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
            {applicabilityTemporalMap[layerA].map((val, i) => {
              return <Button onClick={() => onUpdateMapData({ timeA: val })} key={i} className="dimension-button" size="small" variant={timeA == val ? "contained" : "outlined"} color="secondary">{val}</Button>
            })}
          </Stack>

        </>

      )
    }

    if (props.view === 'monitoring') {
      return (

        <>
          <Stack spacing={2} sx={{ width: '100%'}}>

            <SelectInput onChange={(evt) => { onUpdateMapData({ layerM: evt.target.value }); }} className="select-secondary" value={layerM} name="Select layer" items={[
              { value: 'ndvi', label: 'Vegetation Index (NDVI)' },
              // { value: 'chl', label: 'Leaf Chlorophylle Index (CHL)' },
              { value: 'rgb', label: 'Imagery (Sentinel-2 - 10m)' },
              // { value: 'burn', label: 'Burn Index' },
              { value: 'ndvi_bg', label: 'NDVI Background'},
              { value: 'ndvi_high', label: 'Vegetation Index (NDVI - High res.)'},
              { value: 'tree_class', label: 'Tree Classification'},
              { value: 'rgb_high', label: 'Imagery (High resolution)'}
            ].filter(obj => {
              if(props.isAgroforestry) return true;

              if(["ndvi_bg", "ndvi_high", 'tree_class', 'rgb_high'].indexOf(obj.value) > -1) return false;

              return true

            })} itemValue={["ndvi_bg", "ndvi_high", 'tree_class', 'rgb_high']} icon={<Forest sx={{mb: '2px'}} fontSize="small" color="secondary" />} />

            

          </Stack>

          {["ndvi_high", 'tree_class', 'rgb_high'].indexOf(layerM) > -1 && <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
            {monitoringTemporalMap[layerM].map((val, i) => {
              return <Button onClick={() =>  onUpdateMapData({timeAg: val})} key={i} className="dimension-button" size="small" variant={timeAg == val ? "contained" : "outlined"} color="secondary">{val}</Button>
            })}
          </Stack>}
          {props.dates && ["ndvi_high", 'tree_class', 'rgb_high'].indexOf(layerM) === -1  && <Stack sx={{ width: 'calc(100% - 20px)', minHeight: '30px' }} alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
            <ChevronLeft color="secondary" onClick={previousDate} sx={{cursor: 'pointer'}}/>
            {/* <span style={{ color: '#fff', whiteSpace: 'nowrap', fontSize: '14px', fontWeight: 'bold' }}>{props.timeM ? moment(props.timeM).format('DD/MMM/YYYY') : ' - '}</span> */}
            {props.timeM !== null ? <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker 
                shouldDisableDate={(date) => {
                  // return false
                  return props.dates.indexOf(date.format('YYYY-MM-DD')) === -1 && date.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')
                }}
                slotProps={{textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  sx: {textAlign: 'center'},
                  size: 'small', 
                  className: 'datepicker-text-input',
                  InputProps: {endAdornment: <InputAdornment position="end" sx={{cursor: 'pointer', mb: '3px'}}><DateRange fontSize="small" color="secondary" /></InputAdornment>}
                }}}
                value={props.timeM ? moment(props.timeM) : moment()} 
                onChange={(newValue) => props.onSetDatesState((st) => ({...st, timeM: newValue.format('YYYY-MM-DD')}))} />
            </LocalizationProvider> : <span className="co-text-secondary"> - </span>}
 
            <ChevronRight color="secondary" onClick={nextDate} sx={{cursor: 'pointer'}}/>

          </Stack>}
        </>

      )
    }

    return;

  }

  return (
    <Paper sx={{ ...container }} className="co-background-primary-blur fade-in-2" elevation={4} component={Stack} alignItems="stretch">
      <Stack sx={{ padding: '15px', background: 'transparent' }} direction="row" alignItems="center" justifyContent="space-between">
        <WidgetDescription text="Layers" style={{ color: variables.secondary, fontWeight: 'bold' }} />
        {/* <ArrowBack color="secondary" /> */}
      </Stack>

      <Stack sx={{ padding: '5px 15px 0px 15px' }} alignItems="center">
        {renderContent()}
      </Stack>

      <Stack sx={{ padding: '15px 15px 15px 15px' }} alignItems="flex-start">
        <span style={{ color: variables.secondary, fontSize: '14px', fontWeight: 'bold' }}>Opacity ({opacity}%)</span>
        <Slider
          aria-label="Date"
          sx={{ width: 'calc(100% - 10px)' }}
          onChange={(evt, value) => onUpdateMapData({ opacity: value })}
          value={opacity}
          min={0}
          max={100}
          color="secondary"
        />
      </Stack>

      {/* <Stack sx={{p: '0 15px 10px 15px'}} spacing={1}>
      <Button onClick={() => {setSingleReport('Applicability report')}} size="small" startIcon={<Dashboard />} sx={{lineHeight: 'unset'}} variant="contained" className="co-text-primary" color="secondary">Applicability report</Button>
      <Button onClick={() => {setSingleReport('Declared by farmer')}} size="small" startIcon={<SupervisedUserCircle />} sx={{lineHeight: 'unset'}} variant="contained" className="co-text-primary" color="secondary">Declared by farmer</Button>
      <Button onClick={() => {setSingleReport('Detected practices')}} size="small" startIcon={<Sensors />} sx={{lineHeight: 'unset'}} variant="contained" className="co-text-primary" color="secondary">Detected practices</Button>
      </Stack> */}

      {singleReport && <SingleReport open={Boolean(singleReport)} parcels={projectData[props.projectId].parcels} farms={projectData[props.projectId].farms} title={singleReport} onClose={() => setSingleReport(null)} />}
    </Paper>
  )
}

export default LayersWidget;