import { Button, Divider, Grid, LinearProgress, Paper } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import $auth from "../../services/$auth";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";


const countries = [
  { value: 1, label: 'United Kingdom' },
  { value: 2, label: 'Greece' },
  { value: 3, label: 'Poland' },
  { value: 4, label: 'Serbia' },
  { value: 5, label: 'Portugal' },
  {value: 6, label: 'Ireland'}
];


function Profile(props) {
  const { user, onUpdateState } = useContext(AppContext);
  const { onShowSuccess } = useContext(DataContext);
  const [accLoading, setAccLoading] = useState(false);
  const [passLoading, setPassLoading] = useState(false);

  const [accError, setAccError] = useState({});
  const [passError, setPassError] = useState({});

  const [accState, setAccState] = useState({
    ...user
  });

  const [passState, setPassState] = useState({
    old_password: '',
    new_password1: '',
    new_password2: ''
  });

  const onAccChange = (name, value) => {
    setAccState((current) => ({
      ...current,
      [name]: value
    }));
  }

  const onPassChange = (name, value) => {
    setPassState((current) => ({
      ...current,
      [name]: value
    }))
  }

  const onAccSubmit = async () => {
    setAccLoading(true);
    try {
      let updated = await $auth.updateProfile({ ...accState })
      onUpdateState({ user: updated });
      onShowSuccess('Successfully updated account information')
    } catch (error) {
      setAccError(error);
    } finally {
      setAccLoading(false)
    }
  }

  const onPassSubmit = async () => {
    setPassLoading(true);
    try {
      await $auth.changePassword(passState);
      setPassState({old_password: '', new_password1: '', new_password2: ''});
      onShowSuccess('Successfully changed password');
      setPassError({});
    } catch (error) {
      setPassError(error);
    } finally {
      setPassLoading(false)
    }
  }

  return (
    <Container>

      <Grid sx={{ mt: '20px' }} container spacing={2}>
        <Grid item xs={12}>
          <WidgetTitle text="Account settings" />
        </Grid>

        <Grid item xs={12}>
          <Divider color="primary" light style={{ background: variables.primary, height: '3px' }} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <form onSubmit={(evt) => { evt.preventDefault(); onAccSubmit(); }}>

            <Paper elevation={3} component={Stack} sx={{ position: 'relative', padding: '15px', minHeight: '180px' }} className="co-background-white fade-in">
              <WidgetDescription text={"Personal information"} style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '10px' }} />
              <TextInput autoComplete="off" error={Boolean(accError.non_field_errors || accError.first_name)} helperText={accError.first_name} label="First Name" type="text" value={accState.first_name} onChange={(evt) => onAccChange('first_name', evt.target.value)} />
              <TextInput autoComplete="off" error={Boolean(accError.non_field_errors || accError.last_name)} helperText={accError.last_name} label="Last Name" type="text" value={accState.last_name} onChange={(evt) => onAccChange('last_name', evt.target.value)} />
              <SelectInput name="Country" items={countries} value={accState.country.toString()} onChange={(evt) => onAccChange('country', evt.target.value)} />
              <TextInput readOnly focused={false} autoComplete="off" error={Boolean(accError.non_field_errors || accError.email)} helperText={accError.email} label="Email" type="text" value={accState.email} onChange={(evt) => onAccChange('email', evt.target.value)} />

              <Stack>
                <Button disabled={accLoading} variant="contained" type="submit">Save</Button>

                {accLoading && <LinearProgress />}
              </Stack>
            </Paper>
          </form>

        </Grid>

        <Grid item xs={12} sm={6}>
          <form onSubmit={(evt) => { evt.preventDefault(); onPassSubmit() }}>
            <Paper elevation={3} component={Stack} sx={{ position: 'relative', padding: '15px', minHeight: '180px' }} className="co-background-white fade-in">
              <WidgetDescription text={"Change Password"} style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '10px' }} />
              <TextInput autoComplete="new-password" error={Boolean(passError.non_field_errors || passError.old_password)} helperText={passError.old_password} label="Current Password" type="password" value={passState.old_password} onChange={(evt) => onPassChange('old_password', evt.target.value)} />
              <TextInput autoComplete="new-password" error={Boolean(passError.non_field_errors || passError.new_password1)} helperText={passError.new_password1} label="New Password" type="password" value={passState.new_password1} onChange={(evt) => onPassChange('new_password1', evt.target.value)} />
              <TextInput autoComplete="new-password" error={Boolean(passError.non_field_errors || passError.new_password2)} helperText={passError.new_password2} label="Confirm Password" type="password" value={passState.new_password2} onChange={(evt) => onPassChange('new_password2', evt.target.value)} />
              <Stack>
                <Button variant="contained" type="submit">Save</Button>
                {passLoading && <LinearProgress />}
              </Stack>
            </Paper>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Profile;