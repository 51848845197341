import { Add, Close, Settings } from "@mui/icons-material";
import { Button, Chip, CircularProgress, Divider, Grid, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";
import DeleteRequest from "./DeleteRequest";
import ManageParcels from "./ManageParcels";
import NewRequest from "./NewRequest";

function Farmers(props) {

  const { projects } = useContext(DataContext);

  const [loading, setLoading] = useState(true);

  const [farmers, setFarmers] = useState([]);

  const [error, setError] = useState(null);

  const [connect, setConnect] = useState(false);
  const [manage, setManage] = useState(null);
  const [disconnect, setDisconnect] = useState(null);

  const getFarmers = async () => {
    setLoading(true)
    try {
      let farmers = await $data.getFarmers();
      setFarmers(farmers);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFarmers();
  }, [])


  const renderContent = () => {
    if (loading) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <CircularProgress />
      </Grid>
    )

    if (error) return (
      <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="error" variant="body1">Something went wrong while trying to fetch available requests</Typography>
      </Grid>
    )

    if (farmers.length === 0) {
      return <Grid sx={{ textAlign: 'center' }} item xs={12}>
        <Typography color="primary" variant="body1">There are no connections or pending requests</Typography>
      </Grid>
    }

    return (
      <Grid item xs={12}>
        {farmers.map((connection, key) => {
          return (
            <ListItem sx={{ mb: '10px' }} key={key} className="co-background"
              secondaryAction={<Button color="error" onClick={() => setDisconnect(connection)} startIcon={<Close />}>Remove connection</Button>}>
              <ListItemText primary={connection.farmer_details.email} secondary={connection.status ? <Chip color="success" label="CONNECTED" sx={{ mt: '10px' }} /> : <Chip color="warning" label="PENDING" sx={{ mt: '10px' }} />
              }></ListItemText>
            </ListItem>
          )
        })}
      </Grid>
    )
  }

  return (
    <Container style={{ marginTop: '30px', marginBottom: '30px' }}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <WidgetTitle text="Connect with CropLab farmers" />
        </Grid>
        <Grid item xs={12}>
          <Stack className="only-laptop" direction="row" alignItems="center" justifyContent="space-between">
            <WidgetDescription text="When connected, you can import their parcels to your projects" />
            <Button color="info" onClick={() => { setConnect(true) }} sx={{ lineHeight: 'unset', color: '#fff' }} startIcon={<Add />} variant="contained">NEW REQUEST</Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider color="primary" light style={{ background: variables.primary, height: '3px' }} />
        </Grid>


        {renderContent()}

      </Grid>

      {connect && <NewRequest open={connect} onClose={() => setConnect(false)} onRefresh={getFarmers} />}
      {manage && <ManageParcels open={Boolean(manage)} farmerId={manage} onClose={() => setManage(null)} onRefresh={getFarmers} />}
      {disconnect && <DeleteRequest open={Boolean(disconnect)} connection={disconnect} projects={projects} onClose={() => setDisconnect(null)} onRefresh={getFarmers} />}

    </Container>
  )
}

export default Farmers;