import { AccountCircle, ArrowBack, PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Card, CardActions, CardContent, CardMedia, LinearProgress, Slide, Snackbar, Stack } from "@mui/material";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import $auth from "../../services/$auth";
import { variables } from "../../styles/abstract/variables";
import TextInput from "../ui/TextInput";
import WidgetDescription from "../widgets/WidgetDescription";
import WidgetTitle from "../widgets/WidgetTitle";
import AuthWrapper from "./AuthWrapper";

function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(null);
  
  const [state, setState] = useState({
    email: '',
  });

  const [error, setError] = useState({});

  const onChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSubmit = async () => {
    setLoading(true);
    
    try {
      await $auth.resetEmail(state.email, state.password);
      setSuccess('Reset link has been sent to provided email address');
    } catch(e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthWrapper>
      <form autoComplete="off" onSubmit={(evt) => {evt.preventDefault(); onSubmit();}}>
        <input autoComplete="false" hidden />
        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent'}}>
          <CardMedia style={{ padding: '10px 30px', }} className="co-background-primary">
            <Stack justifyContent="center" alignItems="center" direction="column" style={{ minHeight: '180px', borderRadius: '0' }}>
              <WidgetTitle text={"Reset password"} style={{color: '#fff'}} />
              <WidgetDescription  style={{color: '#fff'}} text={"Please provide an email associated with your account"} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
              <Button style={{lineHeight: 'unset'}} size="small" component={Link} to="/login" startIcon={<ArrowBack color="secondary" />} className="co-text-secondary">Back to Sign in</Button>
            </Stack>
 
          </CardMedia>
          <CardContent className="co-background">
            <TextInput autoComplete="off" error={Boolean(error.non_field_errors || error.email)} helperText={error.email} label="Email" type="email" value={state.email} onChange={(evt) => onChange('email', evt.target.value)} />
          </CardContent>
          <CardActions className="co-background" style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }}>
            <LoadingButton disabled={loading} type="submit" variant="contained" className="co-text">{'Reset'}</LoadingButton>
           
          </CardActions>
          {loading && <LinearProgress variant="indeterminate" color="primary"/>}
        </Card>
      </form>

      {/* ERROR */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(error.non_field_errors)} onClose={(evt, reason) => setError({...error, non_field_errors: null})}>
        <Alert variant="filled" onClose={(evt, reason) => setError({...error, non_field_errors: null})} severity="error" sx={{ width: '100%' }}>
            {error.non_field_errors}
          </Alert>
      </Snackbar>

      {/* SUCCESS */}
      <Snackbar TransitionComponent={Slide} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={Boolean(success)} autoHideDuration={6000} onClose={(evt, reason) => setSuccess(null)}>
        <Alert variant="filled" onClose={(evt, reason) => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
      </Snackbar>
    </AuthWrapper>
  )
}

export default ForgotPassword;