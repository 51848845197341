import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";

function SelectInput(props ) {
  return (
    <FormControl className={props.className} style={{marginBottom: props.noMargin ? 0 : '15px'}} size="small" fullWidth>
      <InputLabel id={props.name}>{props.name}</InputLabel>
      <Select

        labelId={props.name}
        id={props.name + props.name}
        value={props.value}
        label={props.name}
        onChange={props.onChange}
      >
        {props.items.map((item, key) => <MenuItem key={key} value={item.value}><Stack direction="row" spacing={1} alignItems="center">{props.itemValue && props.itemValue?.indexOf(item.value) > -1 && props.icon} <span>{item.label}</span></Stack></MenuItem>)}
      </Select>
      {props.helper && <FormHelperText>{props.helper}</FormHelperText>}
    </FormControl>
  )
}

export default SelectInput;